<template>
  <div class="text-right">
    <v-btn
      v-for="range of ranges"
      :key="range.value"
      :text="activeRange !== range.value"
      v-bind:color="activeRange === range.value ? 'primary' : 'none'"
      elevation="0"
      @click="setRange(range.value)"
    >
      {{ range.text }}
    </v-btn>

    <DateRangeCustom
      v-if="customRanges.length"
      :ranges="customRanges"
      :active-range="activeRange"
      :active-dates="activeDates"
      @onRangeChange="setRange"
    ></DateRangeCustom>
  </div>
</template>
<script>
import DateRangeCustom from '@/common/components/DateRange/DateRangeCustom.vue';

export default {
  name: 'DateRange',
  components: { DateRangeCustom },
  props: ['ranges', 'customRanges', 'activeRange', 'activeDates'],
  methods: {
    setRange(range, dates) {
      this.$emit('onRangeChange', range, dates);
    },
  },
};
</script>
