<template>
  <v-dialog
    max-width="560"
    v-model="enabled"
    @click:outside="onClose()"
    @keydown.esc="onClose()"
  >
    <v-card class="px-8 py-7">
      <div class="d-flex align-center mb-1">
        <h2 class="text-h6 font-weight-semibold">
          <slot name="title"></slot>
        </h2>
        <v-spacer></v-spacer>
        <v-btn icon @click="onClose()" class="app-modal-close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="text-subtitle-1 grey--text">
        <slot name="content"></slot>
      </div>
      <div class="d-flex justify-flex-end mt-6">
        <slot name="buttons"></slot>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmationModal',
  props: ['onClose', 'enabled'],
};
</script>
<style>
.app-modal-close {
  transform: translate(35%, -35%);
}
</style>
