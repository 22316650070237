<template>
  <div class="subscriptions-container flex-column align-flex-start">
    <vertical-space-divider></vertical-space-divider>

    <rajdhani
      :content="{ text: 'subscriptions' }"
      :config="{
        weight: 'Bold',
        'text-transform': 'uppercase',
        'font-size': '44px',
        'line-height': '56px',
        color: '#c3c9de',
      }"
    ></rajdhani>

    <vertical-space-divider></vertical-space-divider>

    <div
      class="subscriptions-card main-card section-shadow flex-column align-flex-start"
    >
      <inter
        :content="{
          text: 'Thank you for choosing My Real Profit!',
        }"
        :config="{
          weight: 'SemiBold',
          'font-size': '24px',
          color: 'var(--gray-700)',
        }"
      ></inter>

      <vertical-space-divider
        :config="{ height: '32px' }"
      ></vertical-space-divider>

      <inter
        :content="{
          text: 'Please update your payment method.',
        }"
        :config="{
          weight: 'Regular',
          'font-size': '18px',
          'line-height': '24px',
          color: 'var(--gray-500)',
          'text-align': 'left',
        }"
      ></inter>

      <vertical-space-divider
        :config="{ height: '8px' }"
      ></vertical-space-divider>

      <inter
        :content="{
          text:
            'Your monthly <a href=\'https://myrealprofit.com/#pricing\' target=\'blank\'>pricing plan</a> is: ' +
            getUserPlan(),
        }"
        :config="{
          weight: 'Regular',
          'font-size': '18px',
          'line-height': '24px',
          color: 'var(--gray-500)',
          'text-align': 'left',
        }"
      ></inter>

      <vertical-space-divider
        :config="{ width: '32px' }"
      ></vertical-space-divider>

      <div class="stripe-card-container">
        <stripe-element-card
          :pk="publishableKey"
          @token="tokenCreated"
          ref="elementRef"
        />
      </div>

      <vertical-space-divider
        :config="{ width: '32px' }"
      ></vertical-space-divider>

      <div
        class="flex-row justify-space-between align-center"
        style="width: 400px"
      >
        <v-btn v-if="!loading" color="secondary" class="px-8" @click="submit">
          Subscribe
        </v-btn>
        <loading-progress
          v-else
          :indeterminate="true"
          size="40"
          rotate
          fillDuration="2"
          rotationDuration="1"
          height="42"
          width="42"
        />

        <img
          src="@/assets/svg/logos/powered_by_stripe_blurple.svg"
          alt="powered by stripe"
          width="130px"
          height="65px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { env_vars } from '@/store/vars.js';
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import Rajdhani from '@/common/components/Texts/Rajdhani.vue';
import Inter from '@/common/components/Texts/Inter.vue';
import VerticalSpaceDivider from '@/common/components/VerticalSpaceDivider.vue';
import { mrpDataLayer } from '@/analytics/gtm';

export default {
  components: {
    StripeElementCard,
    Rajdhani,
    Inter,
    VerticalSpaceDivider,
  },
  name: 'CreateSubscription',
  data() {
    return {
      publishableKey: env_vars.stripe_publishable_key,
      token: null,
      loading: false,
    };
  },
  methods: {
    getUserPlan() {
      let priceString = this.$store.getters.subscriptionDetails.price;
      return '$' + parseInt(priceString) / 100;
    },
    submit() {
      this.$refs.elementRef.submit();
    },
    async tokenCreated(token) {
      this.loading = true;
      this.$store
        .dispatch('update_card_details_controller', { token: token.id })
        .then(() => {
          mrpDataLayer.authenticated.push({
            event: 'Card connected successfully',
          });

          this.$router.push('settings');
          this.$store.dispatch('get_subscription_controller');
          this.$store.dispatch('logAlertSuccess', {
            msg:
              'Thank you for choosing My Real Profit! We successfully processed your card details.',
            delay: 10000,
          });
        })
        .catch(error => {
          console.log(error.response);
          this.$store.dispatch('logAlertError', {
            msg:
              'There was an error processing your card details. Please, try again.',
          });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.subscriptions-card {
  width: 100%;
  padding: 32px;
}

.stripe-card-container {
  width: 400px;
}
</style>

<style>
.subscriptions-card .vue-progress-path .progress {
  stroke: var(--secondary-purple-color);
  fill: white;
  stroke-width: 1.5px;
}
.subscriptions-card .vue-progress-path .background {
  stroke: var(--third-purple-color);
}
</style>
