<template>
  <div>
    <v-card :loading="loading">
      <v-card-text class="py-6 px-5 d-flex flex-wrap align-center gap-2">
        <div class="text-h5 font-weight-bold mr-5">Products</div>

        <v-text-field
          solo
          outlined
          dense
          label="Search by Title / ASIN / Category Name"
          hide-details="auto"
          class="flex-fill"
          prepend-inner-icon="mdi-magnify"
          v-model="searchPhrase"
          clearable
        ></v-text-field>

        <v-select
          :items="sortByOptions"
          label="Sort by"
          outlined
          dense
          :menu-props="{ offsetY: true }"
          height="32"
          append-icon="mdi-chevron-down"
          hide-details
          class="max-w-3"
          v-model="sortBy"
        >
        </v-select>

        <v-select
          :items="groupByOptions"
          label="Group by"
          outlined
          dense
          :menu-props="{ offsetY: true }"
          height="32"
          append-icon="mdi-chevron-down"
          hide-details
          class="max-w-2"
          v-model="groupBy"
          @change="groupByChange"
        >
          <template v-slot:append-item>
            <v-divider class="mb-2"></v-divider>
            <v-list-item style="cursor: pointer;" class="primary--text">
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon class="primary--text mr-1"
                    >mdi-tag-plus-outline</v-icon
                  >
                  Create Custom Category
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>

        <v-btn
          :href="cvsBlob"
          color="white"
          depressed
          class="elevation-1 app-border"
          download="mrp_traffic_analytics.csv"
        >
          Export to CSV
          <v-icon class="ml-3" color="primary" size="20">
            mdi-cloud-download
          </v-icon>
        </v-btn>
      </v-card-text>
    </v-card>

    <TrafficAnalyticsProductsTable
      :searchPhrase="searchPhraseDebounced"
      :sort-by="sortBy"
      @onProductsFilter="setBlobUrl"
      ref="table"
    />
  </div>
</template>

<script>
import TrafficAnalyticsProductsTable from '@/modules/DashboardLayout/TrafficAnalytics/TrafficAnalyticsProductsTable.vue';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import {
  exportColsCategory,
  exportColsChild,
  exportColsParent,
} from '@/modules/DashboardLayout/TrafficAnalytics/export-traffic-analytics';

export default {
  name: 'TrafficAnalyticsProducts',
  components: { TrafficAnalyticsProductsTable },
  data() {
    return {
      searchPhrase: '',
      searchPhraseDebounced: '',
      sortBy: 'sales',
      sortByOptions: [
        {
          text: 'Total Sales',
          value: 'sales',
        },
        {
          text: 'Total Units Sold',
          value: 'units',
        },
        {
          text: 'Sessions',
          value: 'sessions',
        },
        {
          text: 'Conversion Rate',
          value: 'conversionRate',
        },
        {
          text: 'TACoS',
          value: 'tacos',
        },
      ],
      cvsBlob: '',
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      loading: 'trafficAnalytics/productsIsLoading',
      data: 'trafficAnalytics/productsData',
      rangeType: 'trafficAnalytics/rangeType',
    }),
    groupBy: {
      get() {
        return this.$store.getters['trafficAnalytics/productsGroupBy'];
      },
      set(groupBy) {
        return groupBy;
      },
    },
    groupByOptions() {
      return buildGroupList(this.$store.getters.customCategories);
    },
  },
  methods: {
    groupByChange(value) {
      this.$store.dispatch('trafficAnalytics/getProducts', {
        groupBy: value,
      });
    },
    setBlobUrl(products) {
      let exportCols;

      switch (this.groupBy) {
        case 'CHILD':
          exportCols = exportColsChild;
          break;
        case 'PARENT':
          exportCols = exportColsParent;
          break;
        default:
          exportCols = exportColsCategory;
          break;
      }

      const colsKeys = exportCols.map(col => col.key);

      let csvContent = '';

      csvContent += exportCols.map(col => `"${col.label}"`).join(',') + '\n';

      products.forEach(product => {
        const row =
          colsKeys.map(colKey => `"${product[colKey]}"`).join(',') + '\n';
        csvContent += row;
      });

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

      this.cvsBlob = URL.createObjectURL(blob);
    },
  },

  watch: {
    searchPhrase: _.debounce(function(value) {
      this.searchPhraseDebounced = value;
    }, 150),
  },
};

function buildGroupList(customCategories) {
  return [
    {
      text: 'Parent',
      value: 'PARENT',
    },
    {
      text: 'Child',
      value: 'CHILD',
    },
    ...customCategories.map(category => ({
      text: category,
      value: category,
    })),
  ];
}
</script>

<style scoped>
.app-border {
  border: 1px solid #ddd !important;
}
</style>
