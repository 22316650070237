var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small-card profit-card section-shadow main-card flex-column justify-space-between"},[_c('div',{staticClass:"flex-row justify-space-between"},[_c('main-name-value',{attrs:{"content":{
        name: 'profit',
        value: _vm.profitTotal,
      },"config":{
        'align-items': 'flex-start',
        name: {
          weight: 'Medium',
          'text-transform': 'uppercase',
          'font-size': '16px',
          color: 'var(--main-gray-color)',
        },
        value: {
          weight: 'Bold',
          'font-size': '24px',
          color: 'var(--secondary-gray-color)',
        },
      }}}),(!_vm.profitFetched)?_c('loading-progress',{attrs:{"indeterminate":true,"size":"40","rotate":"","fillDuration":"2","rotationDuration":"1","height":"42","width":"42"}}):_c('img',{attrs:{"src":require("@/assets/svg/icons/icon-profit.svg"),"width":"44px","height":"44px"}})],1),_c('div',{staticClass:"flex-row justify-flex-start"},[_c('main-name-value',{attrs:{"content":{
        name: 'margin\xa0\xa0 | \xa0\xa0roi',
        value: _vm.marginRoiString,
      },"config":{
        'align-items': 'flex-start',
        name: {
          weight: 'Medium',
          'text-transform': 'uppercase',
          'font-size': '13px',
          color: 'var(--main-gray-color)',
        },
        value: {
          weight: 'Bold',
          'font-size': '13px',
          color: 'var(--main-green-color)',
        },
      }}})],1),(_vm.profitFetched)?_c('main-progressbar',{staticStyle:{"position":"absolute","left":"0","bottom":"0"},attrs:{"config":{
      value1: _vm.marginPercent,
      opacity: '1.0',
      size: '4px',
      color2: '#ffffff',
    }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }