<template>
  <div v-if="stateAuth && tokens_success" class="flex-column align-flex-start">
    <vertical-space-divider></vertical-space-divider>
    <rajdhani
      :content="{ text: 'Connect Amazon Account' }"
      :config="{
        weight: 'Bold',
        'text-transform': 'uppercase',
        'font-size': '44px',
        'line-height': '56px',
        color: '#c3c9de',
      }"
    ></rajdhani>
    <vertical-space-divider></vertical-space-divider>

    <div class="section-shadow main-card d-block flex-column save-seller-card">
      <div class="main-card-header">
        <span class="main-card-header-text">
          Step 1 of 2 Completed
        </span>
      </div>
      <div>
        <div class="save-seller-text-container">
          <span class="save-seller-text">
            Your Amazon account has been successfully connected.
          </span>
        </div>
        <form
          @submit.prevent="saveSellerClicked"
          class="flex-row justify-space-between align-flex-end save-seller-form-container"
        >
          <div>
            <label class="d-block font-weight-semibold mb-1" for="brand-name">
              Brand Name
            </label>
            <v-text-field
              outlined
              dense
              solo
              placeholder="Enter Your Brand Name"
              hide-details="auto"
              class="f-18"
              v-model="model.name"
              id="brand-name"
            ></v-text-field>
          </div>
          <v-btn color="primary" type="submit">
            Save and Continue
          </v-btn>
        </form>
      </div>
    </div>
  </div>
  <div
    v-else-if="stateUpdate && tokens_success"
    class="flex-column align-flex-start loader"
  >
    <loading-progress
      :indeterminate="true"
      size="29"
      rotate
      fillDuration="2"
      rotationDuration="1"
      height="30"
      width="30"
    />
  </div>
  <div v-else class="flex-column align-flex-start">
    <vertical-space-divider></vertical-space-divider>
    <rajdhani
      :content="{ text: 'Connect Amazon Account' }"
      :config="{
        weight: 'Bold',
        'text-transform': 'uppercase',
        'font-size': '44px',
        'line-height': '56px',
        color: '#c3c9de',
      }"
    ></rajdhani>
    <vertical-space-divider></vertical-space-divider>

    <div class="section-shadow main-card flex-column save-seller-card">
      <div class="main-card-header">
        <span class="main-card-header-text">
          Error
        </span>
      </div>
      <div class="save-seller-text-container">
        <span class="save-seller-text">
          There was an error authorizing your Amazon account.
          <br />
          Please, contact My Real Profit support for help or
          <b><router-link to="/settings">try again</router-link> </b>.
        </span>
      </div>
      <div class="flex-row contact-support-container">
        <v-btn color="primary" href="mailto:support@myrealprofit.com">
          Contact Support
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import VerticalSpaceDivider from '@/common/components/VerticalSpaceDivider.vue';
import Rajdhani from '@/common/components/Texts/Rajdhani.vue';

export default {
  name: 'AddSellerRedirect',
  data() {
    return {
      model: {
        name: null,
      },
      state: null,
      selling_partner_id: null,
      mws_auth_token: 'deprecated',
      spapi_oauth_code: null,
    };
  },
  components: {
    VerticalSpaceDivider,
    Rajdhani,
  },
  methods: {
    saveSellerClicked() {
      if (this.model.name) {
        const sellerCredentials = {
          state: this.amazonRegionName,
          sellingPartnerId: this.selling_partner_id,
          mwsAuthToken: this.mws_auth_token,
          spapiOauthCode: this.spapi_oauth_code,
          name: this.model.name,
          amazonRegionName: this.amazonRegionName,
        };
        this.$store.dispatch('post_seller_controller', sellerCredentials);
      }
    },
    renewSellerToken(sellerId) {
      const renewTokenCredentials = {
        mrpAmazonSellingPartnerId: parseInt(sellerId),
        sellingPartnerId: this.selling_partner_id,
        mwsAuthToken: this.mws_auth_token,
        spapiOauthCode: this.spapi_oauth_code,
        amazonRegionName: this.amazonRegionName,
      };
      this.$store.dispatch('renew_token_controller', renewTokenCredentials);
    },
  },
  mounted() {
    this.state = this.$route.query.state;
    this.selling_partner_id = this.$route.query.selling_partner_id;
    this.spapi_oauth_code = this.$route.query.spapi_oauth_code;
    if (this.stateUpdate && this.tokens_success)
      this.renewSellerToken(this.state.split('_')[1]);
  },
  computed: {
    tokens_success() {
      return this.selling_partner_id && this.spapi_oauth_code;
    },
    stateAuth() {
      return this.state === 'amazonSellerAuth';
    },
    stateUpdate() {
      return this.state ? this.state.split('_')[0] === 'renewToken' : false;
    },
    amazonRegionName() {
      return this.$store.getters.currentRegionName;
    },
  },
};
</script>
<style scoped>
.save-seller-card {
  width: 55%;
}

.save-seller-text-container {
  padding: 0 30px;
  text-align: left;
}
.save-seller-text {
  font-family: 'SourceSansPro-Regular';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0em;
}

.save-seller-button-container {
  padding: 30px;
}
.save-seller-button {
  float: left;
}

.save-seller-form-container {
  padding: 30px;
}
.contact-support-container {
  padding: 30px;
  justify-content: flex-start;
}
</style>
<style>
.loader .vue-progress-path .progress {
  stroke: var(--secondary-gray-color);
  fill: white;
  stroke-width: 1.3px;
}
.loader .vue-progress-path .background {
  stroke: var(--third-gray-color);
}
</style>
