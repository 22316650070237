<template>
  <v-form
    ref="form"
    class="app-form"
    @submit.prevent="onSubmit"
    lazy-validation
  >
    <h2 class="page-title mb-3 text-uppercase">
      Change seller name
    </h2>

    <label class="app-label">Current seller name</label>

    <v-text-field
      solo
      outlined
      dense
      hide-details="auto"
      :value="seller ? seller.name : ''"
      class="app-current-seller-name mb-4"
      tabindex="-1"
    ></v-text-field>

    <label class="app-label" for="newSellerName">New seller name</label>

    <v-text-field
      solo
      outlined
      dense
      hide-details="auto"
      v-model="newSellerName"
      id="newSellerName"
      :rules="newSellerNameRules"
    ></v-text-field>

    <div class="d-flex justify-flex-end mt-5">
      <v-btn type="submit" color="secondary">
        Submit
      </v-btn>
    </div>
  </v-form>
</template>
<script>
import { mapGetters } from 'vuex';
import { SellerApi } from '@/api/seller';
import * as fetcher from '@/api/fetcher';

export default {
  props: ['sellerId'],
  data() {
    return {
      newSellerName: '',
      newSellerNameRules: [v => !!v || 'Field is required'],
    };
  },
  computed: {
    ...mapGetters({
      sellers: 'sellers',
      currentSeller: 'currentSeller',
    }),
    seller() {
      return this.sellers?.find(
        seller => seller.id === parseInt(this.sellerId)
      );
    },
  },
  methods: {
    async onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      await this.$store.dispatch('change_loading_state', true);

      try {
        const renameResponse = await SellerApi.rename({
          sellerId: this.seller.id,
          newName: this.newSellerName,
        });

        const message = renameResponse.data.description;

        const sellers = await fetcher.fetch_sellers();

        this.$store.commit('SET_SELLERS', sellers);

        if (this.currentSeller.id === this.seller.id) {
          this.$store.commit('SET_CURRENT_SELLER', {
            ...this.currentSeller,
            name: this.newSellerName,
          });
        }

        await this.$store.dispatch('change_loading_state', false);

        await this.$store.dispatch('logAlertSuccess', {
          msg: message,
        });

        this.newSellerName = '';
        this.$refs.form.resetValidation();
      } catch (e) {
        await this.$store.dispatch('logAlertError', {
          msg: 'Unkonwn error occured. Please try again later.',
        });

        await this.$store.dispatch('change_loading_state', false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.app-form {
  max-width: 480px;
}
.app-label {
  margin-bottom: 8px; // .mb-2
  font-weight: 600;
  display: block;
  font-size: 14px;
  font-family: Rajdhani, serif;
  color: #666;
}
.app-current-seller-name {
  pointer-events: none;
}
</style>
