<template>
  <div
    v-if="currentSellerActive && customReportForSellerAvailable"
    class="custom-report-container"
  >
    <CustomReportEmbed :report="report" v-if="report" />
  </div>
  <div v-else-if="!currentSellerActive">
    <h2 class="page-title">REPORT LOADING</h2>
  </div>
  <div v-else>
    <h2 class="page-title">REPORT NOT AVAILABLE</h2>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CustomReportEmbed from './CustomReportEmbed.vue';

export default {
  name: 'CustomReport',
  components: { CustomReportEmbed },

  computed: {
    ...mapGetters({
      sellerId: 'currentSellerId',
      marketplaceId: 'currentMarketplaceId',
      reports: 'customReports/reports',
    }),
    reportRoute() {
      return this.$route.params.reportId;
    },
    sellerHasCustomReports() {
      return !!this.reports?.length;
    },
    report() {
      return this.reports.find(report => report.variable === this.reportRoute);
    },
    customReportForSellerAvailable() {
      return this.sellerHasCustomReports && this.report;
    },
    currentSellerActive() {
      return this.$store.getters.currentSellerStatus === 'active';
    },
    currentSellerId() {
      return this.$store.getters.currentSellerId;
    },
  },
};
</script>

<style scoped>
.custom-report-container {
  margin-top: 32px;
}
</style>
