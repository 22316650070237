<template>
  <div class="position-relative" v-click-outside="hide">
    <v-btn
      :text="!isSomeActiveCustomRange()"
      v-bind:color="isSomeActiveCustomRange() ? 'primary' : 'none'"
      elevation="0"
      class="app-intense-hover"
      @click="toggle()"
    >
      Custom range
    </v-btn>
    <div class="hover-shadow secondary-card custom-date-picker" v-if="expanded">
      <div class="flex-row justify-space-between">
        <v-btn
          v-for="range of ranges"
          :key="range.type"
          :text="!isActive(range.type)"
          v-bind:color="isActive(range.type) ? 'primary' : 'none'"
          elevation="0"
          @click="changeRange(range.type)"
        >
          {{ range.label }}
        </v-btn>
        <v-btn
          :text="!isActive('custom')"
          v-bind:color="isActive('custom') ? 'primary' : 'none'"
          elevation="0"
        >
          Custom
        </v-btn>
      </div>
      <date-picker
        v-model="customDateRange"
        :model-config="modelConfig"
        is-range
        color="purple"
        trim-weeks
        :columns="2"
        :step="1"
        mode="date"
        class="mt-4"
        :first-day-of-week="0"
        :timezone="timezone"
        :min-date="minDate"
        :max-date="maxDate"
      >
      </date-picker>
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon';
import ClickOutside from 'vue-click-outside';
import { getCurrentRangeDates } from '@/store/modules/dateranges';

export default {
  name: 'DashboardCustomRange',
  directives: {
    ClickOutside,
  },
  data() {
    return {
      timezone: 'America/Los_Angeles',
      modelConfig: {
        start: {
          timeAdjust: '00:00:00',
        },
        end: {
          timeAdjust: '23:59:59',
        },
      },
      ranges: [
        {
          label: 'Last 7 days',
          type: 'last_7_days',
        },
        {
          label: 'Last 30 days',
          type: 'last_30_days',
        },
        {
          label: 'This quarter',
          type: 'this_quarter',
        },
        {
          label: 'Last quarter',
          type: 'last_quarter',
        },
      ],
      expanded: false,
    };
  },
  computed: {
    customDateRange: {
      get() {
        const { currentRangeType, currentRangeDates } = this.$store.getters;
        const { start, end } = getCurrentRangeDates(
          currentRangeType,
          currentRangeDates
        );

        return {
          start: new Date(Date.parse(start)),
          end: new Date(Date.parse(end)),
        };
      },
      set({ start, end }) {
        const dateRange = {
          start: DateTime.fromISO(start.toISOString()).setZone(
            'America/Los_Angeles'
          ),
          end: DateTime.fromISO(end.toISOString()).setZone(
            'America/Los_Angeles'
          ),
        };
        this.changeRange('custom', dateRange);
      },
    },
    minDate() {
      const minDateString = this.$store.getters.currentSellerDetails
        .dataAccessStartFrom;
      return new Date(Date.parse(minDateString));
    },
    maxDate() {
      return Date.now();
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
    changeRange(rangeType, customDateRange = null) {
      this.hide();
      this.$emit('changeRange', rangeType, customDateRange);
    },
    hide() {
      this.expanded = false;
    },
    isSomeActiveCustomRange() {
      const rangeTypes = [...this.ranges.map(range => range.type), 'custom'];
      return rangeTypes.includes(this.$store.getters.currentRangeType);
    },
    isActive(rangeType) {
      return rangeType === this.$store.getters.currentRangeType;
    },
  },
};
</script>
<style scoped>
.custom-date-picker {
  top: calc(100% + 15px);
  right: 0;
  padding: 15px;
  position: absolute;
  min-width: 160px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
</style>
