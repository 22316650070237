var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-products-wrapper"},[_c('div',{staticClass:"dashboard-products"},[(!_vm.productsFetched)?_c('div',{staticClass:"dashboard-products-header pa-4 pa-md-8 py-md-6 section-shadow main-card flex-row align-center"},[_c('h3',{staticClass:"app-products-title mr-7"},[_vm._v("Products")]),_c('loading-progress',{attrs:{"indeterminate":true,"size":"28","rotate":"","fillDuration":"2","rotationDuration":"1","height":"30","width":"30"}})],1):_c('div',[_c('v-card',{staticClass:"dashboard-products-header pa-4 pa-md-8 py-md-6 d-flex flex-row justify-space-between align-md-center align-stretch"},[_c('h3',{staticClass:"app-products-title mr-md-7"},[_vm._v("Products")]),_c('v-text-field',{staticClass:"app-products-input",attrs:{"solo":"","outlined":"","dense":"","label":"Search by Title","hide-details":"auto","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{staticClass:"app-products-sort",attrs:{"items":_vm.sortByOptions,"label":"Sort by","outlined":"","dense":"","solo":"","menu-props":{ offsetY: true },"height":"32","append-icon":"mdi-chevron-down","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" Sort by: "+_vm._s(item)+" ")]}}]),model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}}),_c('v-select',{staticClass:"app-products-group",attrs:{"items":_vm.groupByOptions,"label":"Group by","outlined":"","dense":"","solo":"","menu-props":{ offsetY: true },"height":"32","append-icon":"mdi-chevron-down","hide-details":""},on:{"change":function($event){return _vm.onGroupByChange($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" Group Products: "+_vm._s(item)+" ")]}},{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-list-item',{staticClass:"primary--text",staticStyle:{"cursor":"pointer"},on:{"click":_vm.createCategoryClicked}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{staticClass:"primary--text mr-1"},[_vm._v("mdi-tag-plus-outline")]),_vm._v(" Create Custom Category ")],1)],1)],1)]},proxy:true}]),model:{value:(_vm.groupBy),callback:function ($$v) {_vm.groupBy=$$v},expression:"groupBy"}})],1),_c('div',{staticClass:"products-table"},[(!_vm.getProducts.length)?_c('div',{staticClass:"products-table-empty"},[_c('span',{staticClass:"products-table-empty__text"},[_vm._v("No products found for this criteria")])]):_vm._e(),(_vm.groupBySku)?[_vm._l((_vm.productsFiltered.slice(
              0,
              _vm.productsDisplayed
            )),function(product,i){return _c('dashboard-product',{key:'product' + i,staticStyle:{"margin-top":"2em"},attrs:{"product":product}})}),(_vm.productsFiltered.length > _vm.productsDisplayed)?_c('v-btn',{staticClass:"primary--text mt-8 mt-md-12 d-block mx-auto",attrs:{"color":"white"},on:{"click":function($event){return _vm.showMoreProducts()}}},[_vm._v(" Show more ")]):_vm._e()]:[_vm._l((_vm.productVariationsFiltered.slice(
              0,
              _vm.productsDisplayed
            )),function(productVariation,i){return _c('dashboard-product-variation',{key:'productVariation' + i,staticStyle:{"margin-top":"3em"},attrs:{"productVariation":productVariation}})}),(_vm.productVariationsFiltered.length > _vm.productsDisplayed)?_c('v-btn',{staticClass:"primary--text mt-8 mt-md-12 d-block mx-auto",attrs:{"color":"white"},on:{"click":function($event){return _vm.showMoreProducts()}}},[_vm._v(" Show more ")]):_vm._e()],_vm._l((Math.max(0, 4 - _vm.getProducts.length)),function(index){return _c('div',{key:index,staticClass:"product-placeholder"},[_c('span',{staticClass:"placeholder-text placeholder-text-short"}),_c('span',{staticClass:"placeholder-text placeholder-text-medium"}),_c('span',{staticClass:"placeholder-box placeholder-box-short"})])})],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }