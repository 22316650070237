export const formatterCompact = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  notation: 'compact',
});

export function currencyValueFormatter(value) {
  if (!value) {
    formatterCompact.format(0);
  }

  if (value < 10000 && value > -10000) {
    return formatterCompact.format(value);
  }

  return formatterCompact.format(value);
}

export function simpleCurrencyValueFormatter(value) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(value).replaceAll(',', ' ');
}
