<template>
  <div class="product-value">
    <div class="product-value__label"><slot name="label"></slot></div>
    <div class="product-value__value"><slot name="value"></slot></div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.product-value {
  &__label {
    font-family: 'Rajdhani-Medium', sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    color: var(--third-gray-color);
  }
  &__value {
    font-weight: bold;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: 20px;
      margin-top: -3px;
    }
  }
}
</style>
