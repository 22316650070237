<template>
  <div>
    <div class="flex-row justify-space-between align-center mb-8">
      <div class="flex-row align-center">
        <v-btn icon color="primary" @click="backToCategories">
          <v-icon color="primary" size="30">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <div class="d-flex align-center gap-3 ml-2">
          <h2 class="page-title ">CATEGORY: {{ category }}</h2>

          <GuidelineVideo :link="guidelinesVideos.customCategories" />
        </div>
      </div>
      <div class="d-flex flex-row">
        <v-btn
          class="category-btn"
          style="background-color: var(--white)"
          outlined
          depressed
          :loading="downloadButtonLoading"
          @click="downloadButtonClicked()"
        >
          Export
          <v-icon style="margin-left: 10px;" color="#8073e5" size="20">
            mdi-cloud-download
          </v-icon>
        </v-btn>
        <v-btn
          class="category-btn ml-4"
          style="background-color: var(--white)"
          outlined
          depressed
          :loading="uploadButtonLoading"
          @click="uploadButtonClicked()"
        >
          Import
          <v-icon style="margin-left: 10px;" color="#8073e5" size="20">
            mdi-cloud-upload
          </v-icon>
        </v-btn>
      </div>
    </div>
    <v-card class="category-table">
      <table>
        <thead>
          <tr>
            <th>ITEM</th>
            <th>STATUS</th>
            <th>GROUP</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <product
            v-for="product in products"
            :key="product.sku"
            :product="product"
            :category="category"
          >
          </product>
        </tbody>
        <tfoot>
          <tr></tr>
        </tfoot>
      </table>
    </v-card>
    <ConfirmationModal :enabled="uploadModalShowed" :onClose="hideUploadModal">
      <template v-slot:title>
        Import file
      </template>
      <template v-slot:content>
        This file must follow a specific file format. <br />
        Use <b>'Export'</b> option to get the template file. <br /><br />
        <v-file-input
          v-model="file"
          accept=".csv"
          label="Select file"
          placeholder="No file chosen"
        />
      </template>
      <template v-slot:buttons>
        <v-btn color="white" class="mr-3" @click="hideUploadModal()"
          >Cancel</v-btn
        >
        <v-btn color="secondary" @click="submitFile()">
          Import
        </v-btn>
      </template>
    </ConfirmationModal>
    <ConfirmationModal
      :enabled="downloadModalShowed"
      :onClose="hideDownloadModal"
    >
      <template v-slot:title>
        Export template
      </template>
      <template v-slot:content>
        <br /><b>IMPORTANT!</b><br />
        Modify only PRODUCT GROUP column.<br />
        If PRODUCT GROUP is <b>blank</b>, SKU will be discarded.<br /><br />
      </template>
      <template v-slot:buttons>
        <v-btn color="white" class="mr-3" @click="hideDownloadModal()"
          >Cancel</v-btn
        >
        <v-btn color="secondary" @click="exportFile()">
          Export
        </v-btn>
      </template>
    </ConfirmationModal>
  </div>
</template>

<script>
import * as fetcher from '@/api/fetcher';
import * as poster from '@/api/poster';
import Product from '@/modules/DashboardLayout/CustomCategories/Products/Product/Product.vue';
import ConfirmationModal from '@/common/components/ConfirmationModal';
import GuidelineVideo from '@/common/components/GuidelineVideo.vue';
import { guidelinesVideos } from '@/utils/guidelines-videos';

export default {
  data() {
    return {
      downloadButtonLoading: false,
      downloadModalShowed: false,
      uploadButtonLoading: false,
      uploadModalShowed: false,
      dialog: true,
      file: null,
      products: [],
    };
  },
  components: { GuidelineVideo, Product, ConfirmationModal },
  name: 'productsCategory',
  created() {
    this.fetchCustomCategoryProducts();
  },
  computed: {
    guidelinesVideos() {
      return guidelinesVideos;
    },
    category() {
      return this.$route.params.category;
    },
    currentSellerId() {
      return this.$store.getters.currentSellerId;
    },
  },
  watch: {
    currentSellerId(oldId, newId) {
      if (oldId === newId) return;
      this.$router.push('/categories');
    },
  },
  methods: {
    backToCategories() {
      this.$router.push('/categories');
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    submitFile() {
      if (!this.file) return;
      this.uploadButtonLoading = true;
      this.hideUploadModal();
      poster
        .post_product_categories_csv(this.file, this.category)
        .then(() => {
          this.$store.dispatch('logAlertSuccess', {
            msg: 'Product categories updated successfully',
            delay: 10000,
          });
          this.fetchCustomCategoryProducts();
        })
        .catch(() => {
          this.$store.dispatch('logAlertError', {
            msg:
              'There was an error updating your product categories. Please, try again.',
            delay: 10000,
          });
        })
        .finally(() => {
          this.uploadButtonLoading = false;
          this.file = null;
        });
    },

    uploadButtonClicked() {
      this.showUploadModal();
    },
    downloadButtonClicked() {
      this.showDownloadModal();
    },
    showUploadModal() {
      this.uploadModalShowed = true;
    },
    showDownloadModal() {
      this.downloadModalShowed = true;
    },
    hideUploadModal() {
      this.uploadModalShowed = false;
    },
    hideDownloadModal() {
      this.downloadModalShowed = false;
    },

    async exportFile() {
      this.downloadButtonLoading = true;
      this.hideDownloadModal();
      try {
        await this.downloadFile();
      } catch (error) {
        this.$store.dispatch('logAlertError', {
          msg:
            'There was an error importing file for category ' +
            this.category +
            '. Please, try again.',
          delay: 10000,
        });
      } finally {
        this.downloadButtonLoading = false;
      }
    },
    async downloadFile() {
      const data = await fetcher.fetch_custom_category_products_csv({
        category: this.category,
      });
      const blob = new Blob([data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download =
        'mrp_' +
        'category_' +
        this.category.replace(/[^a-z0-9]/gi, '_').toLowerCase() +
        '.csv';
      link.click();
    },

    async fetchCustomCategoryProducts() {
      await this.$store.dispatch('change_loading_state', true);
      this.products = [];
      try {
        const data = await fetcher.fetch_custom_category_products_list({
          category: this.category,
        });
        this.products = this.sortByStatusParentTag(this.fillEmptyData(data));
      } catch (error) {
        this.$store.dispatch('logAlertError', {
          msg:
            'There was an error fetching data for category ' +
            this.category +
            '. Please, try again.',
          delay: 10000,
        });
      } finally {
        await this.$store.dispatch('change_loading_state', false);
      }
    },
    sortByStatusParentTag(data) {
      return data.sort((a, b) => {
        return a.status > b.status ? 1 : a.productTag > b.productTag ? 1 : -1;
      });
    },
    fillEmptyData(data) {
      data.forEach(el => {
        if (!el.parent) el.parent = '';
        if (!el.productTag) el.productTag = '';
      });
      return data;
    },
  },
};
</script>
<style>
@import '../../../../assets/css/category.css';
</style>
<style scoped>
.category-btn.v-btn--outlined {
  border: 1px solid #d7d6dc;
  font-weight: 600;
}
</style>
