<template>
  <div class="delete-modal-container">
    <card
      :content="{
        header: { text: 'Delete this expense?' },
        text: { text: 'This action will remove it from your seller history' },
      }"
      :config="{ width: '350px' }"
    >
      <img
        src="@/assets/svg/tick-inactive.svg"
        class="tick-img"
        @click="closeClicked()"
      />
      <template v-slot:buttons>
        <v-btn color="white" @click="noClicked()" class="mr-4">No</v-btn>
        <v-btn color="secondary" @click="yesClicked()">Yes, delete</v-btn>
      </template>
    </card>
  </div>
</template>

<script>
import Card from '@/common/components/Card.vue';

export default {
  name: 'DeleteModal',
  components: {
    Card,
  },
  methods: {
    yesClicked() {
      this.$emit('yes-clicked');
    },
    noClicked() {
      this.$emit('no-clicked');
    },
    closeClicked() {
      this.$emit('close-clicked');
    },
  },
};
</script>

<style scoped>
.delete-modal-container {
  position: relative;
  z-index: 999999;
  top: 25px;
  left: -350px;
}
.tick-img {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
</style>
