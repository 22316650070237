<template>
  <div class="flex-column">
    <div class="flex-row justify-space-between align-center">
      <div class="d-flex gap-3 align-center">
        <h2 class="page-title">DASHBOARD</h2>

        <GuidelineVideo :link="guidelinesVideos.profitDashboard" />
      </div>
      <dashboard-dateranges></dashboard-dateranges>
    </div>

    <v-row align-content="stretch" class="mt-4">
      <v-col cols="12" md="4">
        <sales-card></sales-card>
      </v-col>
      <v-col cols="12" md="4">
        <units-card></units-card>
      </v-col>
      <v-col cols="12" md="4">
        <profit-card></profit-card>
      </v-col>
    </v-row>

    <v-row align-content="stretch">
      <v-col col="8" md="8" v-show="$vuetify.breakpoint.mdAndUp">
        <SalesAndProfitChartContainer
          class="h-100"
        ></SalesAndProfitChartContainer>
      </v-col>
      <v-col>
        <expenses-card></expenses-card>
      </v-col>
    </v-row>

    <dashboard-products class="mt-7"></dashboard-products>
  </div>
</template>

<script>
import SalesCard from '@/modules/DashboardLayout/ProfitDashboard/SalesCard/SalesCard.vue';
import UnitsCard from '@/modules/DashboardLayout/ProfitDashboard/UnitsCard/UnitsCard.vue';
import ProfitCard from '@/modules/DashboardLayout/ProfitDashboard/ProfitCard/ProfitCard.vue';
import ExpensesCard from '@/modules/DashboardLayout/ProfitDashboard/ExpensesCard/ExpensesCard.vue';
import DashboardDateranges from '@/modules/DashboardLayout/ProfitDashboard/DashboardDateranges/DashboardDateranges.vue';
import DashboardProducts from '@/modules/DashboardLayout/ProfitDashboard/DashboardProducts/DashboardProducts.vue';
import SalesAndProfitChartContainer from '@/modules/DashboardLayout/ProfitDashboard/SalesAndProfitChart/SalesAndProfitChartContainer.vue';
import { guidelinesVideos } from '@/utils/guidelines-videos';
import GuidelineVideo from '@/common/components/GuidelineVideo.vue';

export default {
  name: 'ProfitDashboardLayout',
  components: {
    GuidelineVideo,
    SalesAndProfitChartContainer,
    SalesCard,
    UnitsCard,
    ProfitCard,
    ExpensesCard,
    DashboardProducts,
    DashboardDateranges,
  },
  created() {
    this.$store.dispatch('created_dashboard_controller');
  },
  computed: {
    guidelinesVideos() {
      return guidelinesVideos;
    },
    sellerInitialized() {
      return (
        !this.currentSellerDetails.spmwsHistoryInitialized ||
        (this.$store.getters.currentSellerAdvertisingAuthorized &&
          !this.currentSellerDetails.ppcHistoryInitialized)
      );
    },
    currentSellerDetails() {
      return this.$store.getters.currentSellerDetails;
    },
    currentSellerName() {
      return this.$store.getters.currentSellerName;
    },
    sellerDetailsFetched() {
      return this.$store.getters.currentSellerDetailsStatus === 'active';
    },
  },
  watch: {
    sellerDetailsFetched(newValue) {
      if (newValue && this.sellerInitialized) {
        this.$store.dispatch('logAlertInfo', {
          msg:
            'We are still collecting ' +
            this.currentSellerName +
            ' data from Amazon. Please, come back later',
          delay: 30000,
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
.gap-30 {
  gap: 30px;
}
.small-card {
  position: relative;
  width: 100%;
  min-height: var(--small-card-height);
  height: 100%;
  padding: 15px 30px 10px 30px;
  overflow: hidden;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 15px 16px 10px 16px;
  }
}
</style>
