<template>
  <div class="w-full">
    <template v-if="!isAuthorized">
      <h2 class="page-title text-uppercase">{{ report.text }}</h2>

      <div class="message">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
          v-if="isLoading"
        ></v-progress-circular>

        <Message
          v-if="!isLoading"
          text="Please authorize your BI account"
          retryButtonText="Log In"
          @retry="login"
        />
      </div>
    </template>

    <div
      ref="report"
      class="iframe-container d-none"
      :style="{
        height: report.height + 'px',
      }"
      :class="{
        'd-block': isAuthorized && !isLoading && !isPublic,
      }"
    ></div>

    <div
      class="iframe-container"
      :style="{
        height: report.height + 'px',
      }"
      v-if="isPublic"
    >
      <iframe :src="iframeSrc"></iframe>
    </div>
  </div>
</template>
<script>
import { service, factories } from 'powerbi-client';

import Message from '@/common/components/Message.vue';
import router from '@/router';

const powerbi = new service.Service(
  factories.hpmFactory,
  factories.wpmpFactory,
  factories.routerFactory
);

const LOGIN_REQUEST = {
  scopes: ['https://analysis.windows.net/powerbi/api/Report.Read.All'],
};

export default {
  name: 'CustomReportEmbed',
  components: { Message },
  props: {
    report: {
      text: String,
    },
  },
  data() {
    return {
      accessToken: '',
      isAuthorized: false,
      authorizationFailed: false,
      iframeSrc: '',
      isLoading: true,
    };
  },
  async mounted() {
    await this.acquireTokenSilent();
  },
  computed: {
    embedConfiguration() {
      return {
        type: 'report',
        tokenType: 0,
        accessToken: this.accessToken,
        embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${this.reportId}`,
        id: this.reportId,
      };
    },
    embedContainer() {
      return this.$refs.report;
    },
    reportId() {
      const reportLink = this.report.biLink;

      const reportUrl = new URL(reportLink);

      return reportUrl.searchParams.get('reportId');
    },
    isPublic() {
      const reportLink = this.report.biLink;

      return reportLink.includes('/view');
    },
  },
  methods: {
    embedReport() {
      if (this.embedContainer) {
        powerbi.reset(this.embedContainer);
      }

      if (this.isPublic) {
        this.iframeSrc = this.report.biLink;
      } else {
        powerbi.embed(this.embedContainer, this.embedConfiguration);
      }
    },
    async acquireTokenSilent() {
      const accounts = this.$msal.getAllAccounts();

      if (!accounts.length) {
        this.isAuthorized = false;
        this.isLoading = false;
        return;
      }

      const mrpAccount = findMrpAccount(accounts);

      if (mrpAccount) {
        try {
          const acquireTokenSilentResponse = await this.$msal.acquireTokenSilent(
            {
              ...LOGIN_REQUEST,
              account: mrpAccount,
            }
          );

          const { accessToken } = acquireTokenSilentResponse;
          this.accessToken = accessToken;

          this.isAuthorized = true;
          this.embedReport();
        } catch (e) {
          this.isAuthorized = false;
        }
      } else {
        this.isAuthorized = false;
      }

      this.isLoading = false;
    },
    login() {
      router.push({
        path: '/msal-redirect',
        query: { redirectUrl: router.currentRoute.path },
      });
    },
  },

  watch: {
    async $route() {
      await this.acquireTokenSilent();
    },
  },
};

function findMrpAccount(accounts) {
  return accounts.find(account =>
    account.username.includes('myrealprofit.com')
  );
}
</script>

<style lang="scss" scoped>
.iframe-container {
  width: 100%;
  ::v-deep iframe {
    border: 0;
    width: 100%;
    height: 100%;
  }
}
.message {
  display: flex;
  height: 500px;
  align-items: center;
  justify-content: center;
}
</style>
