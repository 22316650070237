<template>
  <div class="position-relative">
    <v-text-field
      class="hide-controls"
      autofocus
      outlined
      dense
      hide-details="auto"
      :ref="'input'"
      type="number"
      @keydown="onKeyDown($event)"
      v-model="value"
      :append-icon="value !== initialValue ? 'mdi-refresh' : null"
      @click:append="clearSearch"
      @focus="$event.target.select()"
    ></v-text-field>
  </div>
</template>

<script>
// backspace starts the editor on Windows

import { floatFormatter } from '@/common/ag-grid/value-formatters';

const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';
const KEY_PERIOD = 'Period';
const KEY_COMMA = 'Comma';

export default {
  data() {
    return {
      value: null,
      initialValue: null,
      cancelBeforeStart: true,
      highlightAllOnFocus: true,
    };
  },
  methods: {
    clearSearch() {
      this.value = floatFormatter(this.initialValue);
      this.$refs.input.blur();
    },
    getValue() {
      return this.value;
    },

    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },

    setInitialState(params) {
      let startValue;
      let highlightAllOnFocus = true;

      if (params.eventKey === KEY_BACKSPACE) {
        // if backspace or delete pressed, we clear the cell
        startValue = '';
      } else if (params.charPress) {
        // if a letter was pressed, we start with the letter
        startValue = params.charPress;
        highlightAllOnFocus = false;
      } else {
        // otherwise we start with the current value
        startValue = params.value;
        if (params.eventKey === KEY_F2) {
          highlightAllOnFocus = false;
        }
      }

      this.value = floatFormatter(startValue);
      this.highlightAllOnFocus = highlightAllOnFocus;
    },

    isCancelAfterEnd() {
      // condition
    },

    onKeyDown(event) {
      if (this.isLeftOrRight(event) || this.isBackspace(event)) {
        event.stopPropagation();
        return;
      }

      if (
        !this.finishedEditingPressed(event) &&
        !(
          this.isKeyPressedNumeric(event) ||
          this.isPeriod(event) ||
          this.isComma(event)
        )
      ) {
        if (event.preventDefault) event.preventDefault();
      }
    },

    isCharNumeric(charStr) {
      return /\d/.test(charStr);
    },

    isKeyPressedNumeric(event) {
      const charStr = event.key;
      return this.isCharNumeric(charStr);
    },

    finishedEditingPressed(event) {
      const charCode = event.key;
      return charCode === KEY_ENTER || charCode === KEY_TAB;
    },

    isBackspace(event) {
      return event.key === KEY_BACKSPACE;
    },

    isLeftOrRight(event) {
      return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
    },

    isPeriod(event) {
      return event.code === KEY_PERIOD;
    },

    isComma(event) {
      return event.code === KEY_COMMA;
    },
  },

  created() {
    this.setInitialState(this.params);

    this.initialValue = this.params.getInitialValue();

    // only start edit if key pressed is a number, not a letter
    this.cancelBeforeStart =
      this.params.charPress && '1234567890'.indexOf(this.params.charPress) < 0;
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    });
  },
};
</script>
<style>
.hide-controls input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hide-controls input[type='number'] {
  -moz-appearance: textfield;
}
</style>
