var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-container main-card section-shadow flex-column align-flex-start",style:(_vm.computedStyles)},[_vm._t("default"),_c('inter',{attrs:{"content":{ text: _vm.header.text },"config":{
      weight: 'Medium',
      color: 'var(--gray-700)',
      'font-size': _vm.header['font-size'],
      'line-height': _vm.header['line-height'],
    }}}),_c('vertical-space-divider',{attrs:{"config":{ height: '8px' }}}),_c('inter',{attrs:{"content":{
      text: _vm.text.text,
    },"config":{
      weight: 'Regular',
      color: 'var(--gray-500)',
      'font-size': _vm.text['font-size'],
      'line-height': _vm.text['line-height'],
    }}}),(_vm.text.text !== ' ')?_c('vertical-space-divider',{attrs:{"config":{ height: '16px' }}}):_vm._e(),_vm._t("content"),_c('div',{staticClass:"card-button-container flex-row justify-flex-end"},[_vm._t("buttons")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }