<template>
  <div class="new-expense-modal-container">
    <card
      :content="{
        header: { text: 'Create New Manual Expense' },
        text: { text: ' ' },
      }"
      :config="{ width: '400px' }"
    >
      <img
        src="@/assets/svg/tick-inactive.svg"
        alt="a"
        style="position: absolute; top:8px; right:8px; cursor: pointer"
        @click="closeClicked()"
      />

      <template v-slot:content>
        <div class="flex-row justify-space-between" style="width: 100%">
          <div
            class="radio-container flex-row align-center"
            @click="periodClicked('ONE_TIME')"
          >
            <inter
              :content="{
                text: 'One-time',
              }"
              :config="{
                weight: 'Regular',
                color: 'var(--gray-700)',
                'font-size': '16px',
                'line-height': '26px',
              }"
            ></inter>
            <horizontal-space-divider
              :config="{ width: '4px' }"
            ></horizontal-space-divider>
            <input type="radio" :checked="model.periodSelected == 'ONE_TIME'" />
            <span class="radio"></span>
          </div>
          <div
            class="radio-container flex-row align-center"
            @click="periodClicked('MONTHLY')"
          >
            <inter
              :content="{
                text: 'Monthly',
              }"
              :config="{
                weight: 'Regular',
                color: 'var(--gray-700)',
                'font-size': '16px',
                'line-height': '26px',
              }"
            ></inter>
            <horizontal-space-divider
              :config="{ width: '4px' }"
            ></horizontal-space-divider>
            <input type="radio" :checked="model.periodSelected == 'MONTHLY'" />
            <span class="radio"></span>
          </div>
          <div
            class="radio-container flex-row align-center"
            @click="periodClicked('WEEKLY')"
          >
            <inter
              :content="{
                text: 'Weekly',
              }"
              :config="{
                weight: 'Regular',
                color: 'var(--gray-700)',
                'font-size': '16px',
                'line-height': '26px',
              }"
            ></inter>
            <horizontal-space-divider
              :config="{ width: '4px' }"
            ></horizontal-space-divider>
            <input type="radio" :checked="model.periodSelected == 'WEEKLY'" />
            <span class="radio"></span>
          </div>
          <div
            class="radio-container flex-row align-center"
            @click="periodClicked('DAILY')"
          >
            <inter
              :content="{
                text: 'Daily',
              }"
              :config="{
                weight: 'Regular',
                color: 'var(--gray-700)',
                'font-size': '16px',
                'line-height': '26px',
              }"
            ></inter>
            <horizontal-space-divider
              :config="{ width: '4px' }"
            ></horizontal-space-divider>
            <input type="radio" :checked="model.periodSelected == 'DAILY'" />
            <span class="radio"></span>
          </div>
        </div>

        <vertical-space-divider></vertical-space-divider>

        <div class="flex-row justify-space-between" style="width: 100%">
          <div class="flex-column align-flex-start" style="width: 65%">
            <inter
              :content="{
                text: 'Description',
              }"
              :config="{
                weight: 'Regular',
                color: 'var(--gray-700)',
                'font-size': '18px',
                'line-height': '28px',
              }"
            ></inter>
            <v-text-field
              id="manual-expense-input"
              solo
              outlined
              dense
              hide-details="auto"
              class="w-100"
              v-model="model.description"
            ></v-text-field>
          </div>
          <div class="flex-column align-flex-end" style="width: 25%">
            <inter
              :content="{
                text: 'Amount',
              }"
              :config="{
                weight: 'Regular',
                color: 'var(--gray-700)',
                'font-size': '18px',
                'line-height': '28px',
              }"
            ></inter>

            <v-text-field
              id="manual-expense-input"
              solo
              outlined
              dense
              hide-details="auto"
              class="w-100"
              v-model="model.price"
            ></v-text-field>
          </div>
        </div>

        <vertical-space-divider></vertical-space-divider>

        <div class="flex-row">
          <inter
            v-if="model.periodSelected !== 'ONE_TIME'"
            :content="{
              text: 'Start Date:',
            }"
            :config="{
              weight: 'Regular',
              color: 'var(--gray-700)',
              'font-size': '18px',
              'line-height': '28px',
            }"
          ></inter>
          <inter
            v-else
            :content="{
              text: 'Date of Expense:',
            }"
            :config="{
              weight: 'Regular',
              color: 'var(--gray-700)',
              'font-size': '18px',
              'line-height': '28px',
            }"
          ></inter>

          <horizontal-space-divider
            :config="{ width: '16px' }"
          ></horizontal-space-divider>
          <inter
            :content="{
              text: calendarDatePretty,
            }"
            :config="{
              weight: 'Medium',
              color: 'var(--gray-700)',
              'font-size': '18px',
              'line-height': '28px',
            }"
          ></inter>
        </div>

        <vertical-space-divider
          :config="{ height: '16px' }"
        ></vertical-space-divider>

        <date-picker
          v-model="calendarDate"
          color="purple"
          trim-weeks
          :step="1"
          mode="date"
        >
        </date-picker>
      </template>

      <template v-slot:buttons>
        <v-btn color="secondary" @click="createClicked()">Create</v-btn>
      </template>
    </card>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import Card from '@/common/components/Card.vue';
import VerticalSpaceDivider from '@/common/components/VerticalSpaceDivider.vue';
import HorizontalSpaceDivider from '@/common/components/HorizontalSpaceDivider.vue';
import Inter from '@/common/components/Texts/Inter.vue';

import { DateTime } from 'luxon';

export default {
  directives: {
    ClickOutside,
  },
  name: 'NewExpenseModal',
  components: {
    Card,
    VerticalSpaceDivider,
    HorizontalSpaceDivider,
    Inter,
  },
  data() {
    return {
      allowedPeriods: ['ONE_TIME', 'MONTHLY', 'WEEKLY', 'DAILY'],
      model: {
        periodSelected: 'ONE_TIME',
        price: null,
        description: null,
      },
      calendarDate: null,
    };
  },
  methods: {
    clearForm() {
      this.model.periodSelected = 'ONE_TIME';
      this.model.price = null;
      this.model.description = null;
      this.calendarDate = null;
    },
    closeClicked() {
      this.clearForm();
      this.$emit('close-clicked');
    },
    periodClicked(period) {
      this.model.periodSelected = period;
    },

    createClicked() {
      if (this.validateModel()) {
        this.$emit('create-clicked', {
          type: this.model.periodSelected,
          description: this.model.description,
          price: this.model.price,
          startDate: this.calendarDateLA,
        });
        this.clearForm();
        this.$emit('close-clicked');
      }
    },
    validateModel() {
      return (
        this.allowedPeriods.includes(this.model.periodSelected) &&
        this.validPrice(parseFloat(this.model.price)) &&
        this.validString(this.model.description) &&
        !!this.calendarDateLA
      );
    },
    validPrice(val) {
      return !!val && typeof val == 'number';
    },
    validString(val) {
      return !!val && typeof val == 'string';
    },
  },
  computed: {
    calendarDatePretty() {
      if (!this.calendarDateLA) return null;
      return this.calendarDateLA.split('T')[0];
    },
    calendarDateLA() {
      if (!this.calendarDate) return null;

      const startZoneOffset = this.calendarDate.getTimezoneOffset();

      //this casting has to be executed, since js Date object works poorly
      const startRangeUTC = DateTime.fromJSDate(this.calendarDate)
        .minus({ minutes: startZoneOffset })
        .toUTC();
      const startRangeLA = DateTime.fromObject({
        year: startRangeUTC.year,
        month: startRangeUTC.month,
        day: startRangeUTC.day,
        zone: 'America/Los_Angeles',
      }).startOf('day');

      return DateTime.fromISO(startRangeLA, { setZone: true })
        .toUTC()
        .toISO();
    },
  },
};
</script>

<style scoped>
.new-expense-modal-container {
  position: relative;
  z-index: 999999;
}

.radio-container {
  cursor: pointer;
  position: relative;
}
.container input {
  height: 0;
  width: 0;
}
.radio-container input {
  position: absolute;
  cursor: pointer;
  margin: 0;
  right: 0;
  opacity: 0;
}
.radio-container .radio {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--gray-100);
}
.radio-container:hover input ~ .radio {
  background-color: var(--gray-200);
}
.radio-container input:checked ~ .radio {
  background-color: var(--main-purple-color);
}
.radio-container .radio:after {
  content: '';
  position: absolute;
  display: none;
  top: 9px;
  right: 4px;
}
.radio-container input:checked ~ .radio:after {
  display: block;
}
.radio-container .radio:after {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

#manual-expense-input {
  border: 1px solid #656ba6;
  border-radius: 5px;
  padding: 5px 10px;
  width: 100%;

  font-family: 'Inter-Regular';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;

  color: var(--gray-700);
}
#manual-expense-input:focus {
  outline: none;
}

.calendar-row-container {
  position: relative;
  width: 100%;
}
.calendar-wrapper {
  position: absolute;
}
</style>
