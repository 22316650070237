<template>
  <div
    class="small-card profit-card section-shadow main-card flex-column justify-space-between"
  >
    <div class="flex-row justify-space-between">
      <main-name-value
        :content="{
          name: 'profit',
          value: profitTotal,
        }"
        :config="{
          'align-items': 'flex-start',
          name: {
            weight: 'Medium',
            'text-transform': 'uppercase',
            'font-size': '16px',
            color: 'var(--main-gray-color)',
          },
          value: {
            weight: 'Bold',
            'font-size': '24px',
            color: 'var(--secondary-gray-color)',
          },
        }"
      ></main-name-value>

      <loading-progress
        v-if="!profitFetched"
        :indeterminate="true"
        size="40"
        rotate
        fillDuration="2"
        rotationDuration="1"
        height="42"
        width="42"
      />
      <img
        v-else
        src="@/assets/svg/icons/icon-profit.svg"
        width="44px"
        height="44px"
      />
    </div>

    <div class="flex-row justify-flex-start">
      <main-name-value
        :content="{
          name: 'margin\xa0\xa0 | \xa0\xa0roi',
          value: marginRoiString,
        }"
        :config="{
          'align-items': 'flex-start',
          name: {
            weight: 'Medium',
            'text-transform': 'uppercase',
            'font-size': '13px',
            color: 'var(--main-gray-color)',
          },
          value: {
            weight: 'Bold',
            'font-size': '13px',
            color: 'var(--main-green-color)',
          },
        }"
      ></main-name-value>
    </div>
    <main-progressbar
      v-if="profitFetched"
      style="position:absolute; left: 0; bottom: 0"
      :config="{
        value1: marginPercent,
        opacity: '1.0',
        size: '4px',
        color2: '#ffffff',
      }"
    ></main-progressbar>
  </div>
</template>

<script>
import MainNameValue from '@/common/components/MainNameValue.vue';
import MainProgressbar from '@/common/components/MainProgressbar.vue';
export default {
  name: 'ProfitCard',
  components: {
    MainNameValue,
    MainProgressbar,
  },
  computed: {
    profitFetched() {
      return this.$store.getters.profitStatus === 'fetched';
    },
    profit() {
      return this.$store.getters.profit;
    },

    profitTotal() {
      if (this.profitFetched) {
        return '$' + this.profit.totalPretty;
      } else {
        return '$';
      }
    },
    marginPercent() {
      if (this.profitFetched) {
        return this.profit.margin;
      } else {
        return 0;
      }
    },
    marginString() {
      if (this.profitFetched) {
        return this.profit.margin.toFixed(2) + '%';
      } else {
        return '%';
      }
    },
    roiString() {
      if (this.profitFetched) {
        return this.profit.roi.toFixed(2) + '%';
      } else {
        return '%';
      }
    },
    marginRoiString() {
      return this.marginString + '\xa0\xa0 · \xa0\xa0' + this.roiString;
    },
  },
};
</script>

<style>
.profit-card .vue-progress-path .progress {
  stroke: var(--main-green-color);
  fill: white;
  stroke-width: 1.5px;
}
.profit-card .vue-progress-path .background {
  stroke: #ebf7f6;
}
</style>
