<template>
  <table class="expenses-table" :style="computedStyles">
    <tr v-for="(expense, i) in content.expensesList" :key="i">
      <td>
        <rajdhani
          :content="{
            text: expense.percent.toFixed(0) + '%',
          }"
          :config="{ weight: 'SemiBold' }"
        ></rajdhani>
      </td>
      <td>
        <div class="flex-row align-center">
          <source-sans-pro
            :content="{ text: getExpenseName(expense) }"
            :config="{ color: 'var(--secondary-gray-color)' }"
          ></source-sans-pro>

          <div
            class="warning-tooltip-wrapper"
            v-if="showCogsWarning(expense.name)"
          >
            <img src="@/assets/svg/warning.svg" style="margin-left: 8px;" />
            <div class="warning-tooltip main-card section-shadow">
              <source-sans-pro
                :content="{
                  text: 'Some of the Active listings have empty COGS value.',
                }"
                :config="{ 'font-size': '15px', 'line-height': '18px' }"
              >
                <router-link
                  to="/cogs"
                  style="color: var(--main-purple-color); font-weight: 700"
                >
                  Set value
                </router-link>
              </source-sans-pro>
            </div>
          </div>
          <div
            v-if="expenseHoverVisible(expense)"
            class="expense-details-hover ml-1"
          >
            <img src="@/assets/svg/info-circle-solid.svg" />
            <div
              class="expense-details hover-shadow main-card"
              :class="
                expense.name == 'Adjustments' ? 'wide-row' : 'standart-row'
              "
            >
              <div
                class="flex-row justify-space-between expense-details-row"
                v-for="(expenseDetail, idx) in expense.subexpenses.list"
                :key="idx"
              >
                <source-sans-pro
                  :content="{
                    text:
                      expense.name == 'Adjustments'
                        ? trimString(expenseDetail.name, 42)
                        : trimString(expenseDetail.name, 22),
                  }"
                  :config="{
                    color: 'var(--secondary-gray-color)',
                  }"
                ></source-sans-pro>
                <source-sans-pro
                  :content="{
                    text: '$' + expenseDetail.totalPretty,
                  }"
                  :config="{
                    weight: 'SemiBold',
                    color: 'var(--secondary-gray-color)',
                  }"
                ></source-sans-pro>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td>
        <source-sans-pro
          :content="{
            text: '$' + expense.totalPretty,
          }"
          :config="{ weight: 'Bold', color: 'var(--secondary-gray-color)' }"
        ></source-sans-pro>
      </td>
    </tr>
  </table>
</template>

<script>
import Rajdhani from '@/common/components/Texts/Rajdhani.vue';
import SourceSansPro from '@/common/components/Texts/SourceSansPro.vue';

export default {
  components: { SourceSansPro, Rajdhani },
  name: 'ExpensesTable',
  props: ['content', 'config'],
  data() {
    return {
      // default styles
      'font-size': '14px',
    };
  },
  created() {
    this.setConfigData();
  },
  methods: {
    setConfigData() {
      if (this.config) {
        this['font-size'] = this.config['font-size']
          ? this.config['font-size']
          : this['font-size'];
      }
    },
    trimString(str, n) {
      return str.length > n ? str.substring(0, n) + '...' : str;
    },

    getExpenseName(expense) {
      if (this.expenseHoverVisible(expense)) {
        return expense.name + ' (' + expense.subexpenses.list.length + ')';
      } else {
        return expense.name;
      }
    },
    expenseHoverVisible(expense) {
      return (
        expense.subexpenses.list.length > 1 ||
        (expense.subexpenses.list.length === 1 &&
          expense.name !== expense.subexpenses.list[0].name)
      );
    },
    showCogsWarning(name) {
      return (
        name == 'Cost of Goods' &&
        this.$store.getters.currentSellerHasMissingActiveCogs
      );
    },
  },
  computed: {
    computedStyles: function() {
      return [
        {
          'font-size': this['font-size'],
        },
      ];
    },
  },
};
</script>

<style scoped>
.warning-tooltip-wrapper {
  position: relative;
}
.warning-tooltip-wrapper .warning-tooltip {
  visibility: hidden;
  left: -110px;
  width: 220px;
  padding: 8px;

  position: absolute;
  z-index: 1;
}
.warning-tooltip-wrapper:hover .warning-tooltip {
  visibility: visible;
}

.expense-details-hover {
  width: 16px;
  height: 16px;
}
.expense-details-hover:hover .expense-details {
  visibility: visible;
  cursor: pointer;
}
.expense-details {
  visibility: hidden;
  position: absolute;
  z-index: 5;
  width: 250px;
  margin-left: -110px;
}
.standart-row {
  width: 260px;
  margin-left: -120px;
}
.wide-row {
  width: 420px;
  margin-left: -270px;
}

.expense-details-row {
  border-bottom: 1px solid #e9e8eb;
  width: 100%;
  padding: 10px;
}

.expenses-table {
  width: 100%;
  z-index: 5;
}
table {
  border-collapse: collapse;
}

table tr {
  height: 39px;
}
table
  tr:nth-child(n + 1):nth-child(-n + 9)
  td:nth-child(n + 2):nth-child(-n + 3) {
  border-bottom: 1px solid #e9e8eb;
}
table td:nth-child(1) {
  width: 44px;
  text-align: center;
}
table td:nth-child(2) {
  min-width: 50%;
  padding-left: 0.5em;
  text-align: left;
}
table td:nth-child(3) {
  width: 35%;
  text-align: right;
}

table tr:nth-child(1) td:nth-child(1) {
  background: #6e5df0;
  color: #ffffff;
}
table tr:nth-child(2) td:nth-child(1) {
  background: #7c6ef1;
  color: #ffffff;
}
table tr:nth-child(3) td:nth-child(1) {
  background: #8b7ef3;
  color: #ffffff;
}
table tr:nth-child(4) td:nth-child(1) {
  background: #998ef4;
  color: #ffffff;
}
table tr:nth-child(5) td:nth-child(1) {
  background: #a89ef6;
  color: #ffffff;
}
table tr:nth-child(6) td:nth-child(1) {
  background: #b6aef7;
  color: #4e57aa;
}
table tr:nth-child(7) td:nth-child(1) {
  background: #c5bef9;
  color: #4e57aa;
}
table tr:nth-child(8) td:nth-child(1) {
  background: #d3cffa;
  color: #4e57aa;
}
table tr:nth-child(9) td:nth-child(1) {
  background: #d3cffab3;
  color: #4e57aa;
}
table tr:nth-child(10) td:nth-child(1) {
  background: #d3cffa70;
  color: #4e57aa;
}
</style>
