<template>
  <v-card :loading="isLoading">
    <v-card-text :class="{ 'app-blur': isLoading }" v-if="!isError">
      <SalesAndProfitChartFilters></SalesAndProfitChartFilters>
      <SalesAndProfitChart></SalesAndProfitChart>
    </v-card-text>
    <div class="align-center justify-center d-flex h-100" v-if="isError">
      <Message
        :text="'Unknown error occurred getting sales and profit data'"
        @retry="loadChartData"
      ></Message>
    </div>
  </v-card>
</template>
<script>
import SalesAndProfitChartFilters from './SalesAndProfitChartFilters.vue';
import SalesAndProfitChart from './SalesAndProfitChart.vue';
import { mapGetters } from 'vuex';
import Message from '@/common/components/Message.vue';

export default {
  async created() {
    await this.loadProducts();
    await this.loadChartData();
  },
  data() {
    return {
      isLoadedNewSellerData: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'salesAndProfit/isLoading',
      isSuccess: 'salesAndProfit/isSuccess',
      isError: 'salesAndProfit/isError',
      productsLoaded: 'products/allIsSuccess',
    }),
  },
  methods: {
    async loadChartData() {
      await this.$store.dispatch('salesAndProfit/get');
    },
    async loadProducts() {
      await this.$store.dispatch('products/loadAll');
    },
  },
  watch: {
    '$store.getters.currentRangeDates': async function() {
      await this.loadChartData();
    },
    '$store.getters.currentSellerName': async function(seller) {
      if (!seller) {
        return;
      }

      await this.$store.dispatch('salesAndProfit/reset');
      await this.loadProducts();
      await this.loadChartData();
    },
  },
  components: {
    Message,
    SalesAndProfitChart,
    SalesAndProfitChartFilters,
  },
};
</script>
<style scoped lang="scss">
.app-blur {
  animation: blur 300ms linear forwards;
}
@keyframes blur {
  0% {
    opacity: 1;
    filter: blur(0);
  }

  100% {
    opacity: 0.2;
    filter: blur(2px);
  }
}
</style>
