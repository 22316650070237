<template>
  <v-dialog v-model="dialog" max-width="550px">
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="primary"
        overlap
        left
        class="app-options-icon"
        :content="badgeValue"
        offset-x="16"
        offset-y="16"
      >
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>
            mdi-filter-variant
          </v-icon>
        </v-btn>
      </v-badge>
    </template>

    <v-card v-if="dialog" :loading="isLoading">
      <v-toolbar class="text-h6 font-weight-semibold">
        Choose products
        <v-chip
          class="ml-4"
          label
          v-if="selectedSkus.size"
          color="primary"
          outlined
          small
        >
          Selected products: {{ selectedSkus.size }}
        </v-chip>
        <v-btn icon class="ml-auto" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <template v-if="isSuccess">
        <v-divider></v-divider>

        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="mt-0 px-5 py-3"
          clearable
          v-model="phrase"
        ></v-text-field>

        <v-list flat class="app-content">
          <v-list-item-group>
            <v-virtual-scroll
              :items="products"
              height="400"
              item-height="80"
              bench="3"
            >
              <template v-slot:default="{ item }">
                <v-list-item
                  v-ripple
                  flat
                  :key="item.sku"
                  v-on:change="toggleProduct(item)"
                >
                  <v-list-item-action class="mr-2">
                    <v-checkbox :input-value="isSelected(item)"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-avatar class="mr-3">
                    <v-img :src="item.imageUrl"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content class="py-5">
                    <v-list-item-title>{{ item.itemName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.sku }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-list-item-group>
        </v-list>

        <v-divider></v-divider>

        <div class="app-buttons">
          <v-btn text class="font-weight-semibold" small @click="toggleAll">
            {{ isSelectedAll ? 'Deselect all' : 'Select all' }}
          </v-btn>

          <v-checkbox
            v-model="includeUnitemised"
            class="mt-0"
            label="Include unitemised expenses"
            hide-details
          ></v-checkbox>
        </div>

        <v-divider></v-divider>

        <v-card-actions class="justify-end">
          <v-btn text @click="dialog = false" class="mr-auto">
            Close
          </v-btn>
          <v-btn text @click="reset()">
            Reset
          </v-btn>
          <v-btn color="primary" @click="chooseProducts()">
            Save
          </v-btn>
        </v-card-actions>
      </template>

      <Message
        v-if="isError"
        :text="'Unknown error occurred during getting products'"
        @retry="loadProducts"
      ></Message>
    </v-card>
  </v-dialog>
</template>
<script>
import Message from '@/common/components/Message.vue';
import { mapGetters } from 'vuex';

export default {
  components: { Message },
  data() {
    return {
      dialog: false,
      selectedSkus: new Set(),
      phrase: '',
      includeUnitemised: false,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        if (this.filtersSelectedSkus.length === 0) {
          this.selectedSkus = new Set(
            this.allProducts.map(product => product.sku)
          );
        } else {
          this.selectedSkus = new Set(this.filtersSelectedSkus);
        }

        this.includeUnitemised = this.storeIncludeUnitemised;
      }
    },
  },
  methods: {
    chooseProducts() {
      const isAllProducts = this.selectedSkus.size === this.allProducts.length;
      this.$store.dispatch('salesAndProfit/get', {
        skus: isAllProducts ? [] : Array.from(this.selectedSkus),
        includeUnitemised: this.includeUnitemised,
      });

      this.dialog = false;
    },
    reset() {
      this.$store.dispatch('salesAndProfit/get', {
        skus: [],
        includeUnitemised: this.includeUnitemised,
      });

      this.dialog = false;
    },
    toggleAll() {
      if (this.selectedSkus.size === this.allProducts.length) {
        this.selectedSkus = new Set();
      } else {
        this.selectedSkus = new Set(
          this.allProducts.map(product => product.sku)
        );
      }
    },
    toggleProduct(product) {
      if (this.selectedSkus.has(product.sku)) {
        this.selectedSkus.delete(product.sku);
      } else {
        this.selectedSkus.add(product.sku);
      }
      this.selectedSkus = new Set(this.selectedSkus);
    },
    isSelected(product) {
      return this.selectedSkus.has(product.sku);
    },
    loadProducts() {
      this.$store.dispatch('products/loadAll');
    },
    toggleUnitemised() {
      this.includeUnitemised = !this.includeUnitemised;
    },
  },
  computed: {
    ...mapGetters({
      storeIncludeUnitemised: 'salesAndProfit/includeUnitemised',
    }),
    allProducts() {
      return this.$store.getters['products/allData'];
    },
    products() {
      if (this.phrase) {
        return this.allProducts.filter(product => {
          const phrase = this.phrase.toLowerCase();
          const name = product.itemName.toLowerCase();
          const sku = product.sku.toLowerCase();
          return name.includes(phrase) || sku.includes(phrase);
        });
      }
      return this.allProducts;
    },
    isSelectedAll() {
      return this.selectedSkus.size === this.allProducts?.length;
    },
    badgeValue() {
      const currentSelectedSkus = this.$store.getters['salesAndProfit/filters']
        .skus;
      return currentSelectedSkus.length
        ? currentSelectedSkus.length
        : this.allProducts.length;
    },
    filtersSelectedSkus() {
      return this.$store.getters['salesAndProfit/filters'].skus;
    },
    isError() {
      return this.$store.getters['products/allStatus'] === 'error';
    },
    isSuccess() {
      return this.$store.getters['products/allStatus'] === 'success';
    },
    isLoading() {
      return this.$store.getters['products/allStatus'] === 'loading';
    },
  },
};
</script>
<style scoped lang="scss">
.app-options-icon {
  position: absolute;
  top: -5px;
  z-index: 2;
  right: 0;
}
.app-content {
  /*height: 400px;*/
  overflow: auto;
  display: block;
}
.app-buttons {
  justify-content: space-between;
  display: flex;
  padding: 15px $container-padding-x;
}
</style>
