<template>
  <v-dialog v-model="dialog" max-width="550px">
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="primary"
        overlap
        left
        class="app-options-icon"
        :content="badgeValue"
        offset-x="16"
        offset-y="16"
      >
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>
            mdi-filter-variant
          </v-icon>
        </v-btn>
      </v-badge>
    </template>

    <v-card v-if="dialog">
      <v-toolbar class="text-h6 font-weight-semibold">
        Choose products
        <v-chip
          class="ml-4"
          label
          v-if="selectedIds.size"
          color="primary"
          outlined
          small
        >
          Selected products: {{ selectedIds.size }}
        </v-chip>
        <v-btn icon class="ml-auto" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-text-field
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="mt-0 px-5 py-3"
        clearable
        v-model="phrase"
      ></v-text-field>

      <v-list flat class="app-content">
        <v-list-item-group>
          <v-virtual-scroll
            :items="mappedProducts"
            height="400"
            item-height="80"
            bench="3"
          >
            <template v-slot:default="{ item }">
              <v-list-item
                v-ripple
                flat
                :key="item[productKey]"
                v-on:change="toggleProduct(item)"
              >
                <v-list-item-action class="mr-2">
                  <v-checkbox :input-value="isSelected(item)"></v-checkbox>
                </v-list-item-action>

                <v-list-item-avatar class="mr-3">
                  <v-img :src="item.imageUrl"></v-img>
                </v-list-item-avatar>

                <v-list-item-content class="py-5">
                  <v-list-item-title>{{ item.itemName }}</v-list-item-title>
                  <v-list-item-subtitle>
                    CHILD: {{ item.asin }} / SKU:
                    {{ item.sku }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-list-item-group>
      </v-list>

      <v-divider></v-divider>

      <div class="app-buttons">
        <v-btn text class="font-weight-semibold" small @click="toggleAll">
          {{ isSelectedAll ? 'Deselect all' : 'Select all' }}
        </v-btn>
      </div>

      <v-divider></v-divider>

      <v-card-actions class="justify-end">
        <v-btn text @click="dialog = false" class="mr-auto">
          Close
        </v-btn>
        <v-btn text @click="reset()">
          Reset
        </v-btn>
        <v-btn color="primary" @click="chooseProducts()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ProductsSelector',
  props: ['products', 'selected', 'productKey'],
  data() {
    return {
      dialog: false,
      selectedIds: new Set(),
      phrase: '',
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        if (this.selected.length === 0) {
          this.selectedIds = new Set(
            this.products.map(product => product[this.productKey])
          );
        } else {
          this.selectedIds = new Set(this.selected);
        }
      }
    },
  },
  methods: {
    chooseProducts() {
      const isAllProducts = this.selectedIds.size === this.products.length;

      const selectedProducts = this.products.filter(product =>
        this.selectedIds.has(product[this.productKey])
      );

      this.$emit('onSelectProducts', isAllProducts ? [] : selectedProducts);

      this.dialog = false;
    },
    reset() {
      this.$emit('onSelectProducts', []);

      this.dialog = false;
    },
    toggleAll() {
      if (this.selectedIds.size === this.products.length) {
        this.selectedIds = new Set();
      } else {
        this.selectedIds = new Set(
          this.products.map(product => product[this.productKey])
        );
      }
    },
    toggleProduct(product) {
      if (this.selectedIds.has(product[this.productKey])) {
        this.selectedIds.delete(product[this.productKey]);
      } else {
        this.selectedIds.add(product[this.productKey]);
      }
      this.selectedIds = new Set(this.selectedIds);
    },
    isSelected(product) {
      return this.selectedIds.has(product[this.productKey]);
    },
  },
  computed: {
    mappedProducts() {
      if (this.phrase) {
        return this.products.filter(product => {
          const phrase = this.phrase.toLowerCase();
          const name = product.itemName.toLowerCase();
          const sku = product[this.productKey].toLowerCase();
          const asin = product[this.productKey].toLowerCase();
          return (
            name.includes(phrase) ||
            sku.includes(phrase) ||
            asin.includes(phrase)
          );
        });
      }
      return this.products;
    },
    isSelectedAll() {
      return this.selectedIds.size === this.products?.length;
    },
    badgeValue() {
      const selected = this.selected;
      return selected.length ? selected.length : this.products.length;
    },
  },
};
</script>
<style scoped lang="scss">
.app-content {
  overflow: auto;
  display: block;
}
.app-buttons {
  justify-content: space-between;
  display: flex;
  padding: 15px $container-padding-x;
}
</style>
