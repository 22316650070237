const commonCols = [
  {
    label: 'Total Sales',
    key: 'sales',
  },
  {
    label: 'Total Units Sold',
    key: 'units',
  },
  {
    label: 'Sessions',
    key: 'sessions',
  },
  {
    label: 'Conversion Rate',
    key: 'conversionRate',
  },
  {
    label: 'TAcoS',
    key: 'tacos',
  },
];

export const exportColsChild = [
  {
    label: 'Title',
    key: 'title',
  },
  {
    label: 'Child',
    key: 'asin',
  },
  {
    label: 'Parent',
    key: 'parentAsin',
  },
  ...commonCols,
];

export const exportColsParent = [
  {
    label: 'Title',
    key: 'title',
  },
  {
    label: 'Parent',
    key: 'parentAsin',
  },
  ...commonCols,
];

export const exportColsCategory = [
  {
    label: 'Title',
    key: 'title',
  },
  {
    label: 'Category',
    key: 'category',
  },
  ...commonCols,
];
