<template>
  <tr
    :class="{
      listingsRowClicked: editClicked,
    }"
  >
    <td>
      <img :src="product.imageUrl" class="category-product-image" />
      <div class="category-product-container">
        <div class="category-product-name">
          {{ name }}
        </div>
        <div class="category-product-sku">
          {{ subname }}
        </div>
      </div>
    </td>
    <td>
      <div class="category-product-status-container">
        <div class="category-product-status">
          <div
            :class="{
              active: product.status.toLowerCase() === 'active',
              inactive: product.status.toLowerCase() === 'inactive',
            }"
          >
            {{ product.status }}
          </div>
        </div>
      </div>
    </td>
    <td>
      <div v-if="editClicked">
        <div class="cogs-input">
          <v-text-field
            autofocus
            outlined
            dense
            hide-details="auto"
            v-model="newTag"
          ></v-text-field>
        </div>
      </div>
      <div v-else-if="tagLoading"></div>
      <div v-else @click="clickEdit">
        <div>
          {{ product.productTag }}
        </div>
      </div>
    </td>
    <td>
      <div v-if="editClicked" class="cogs-container">
        <img
          src="@/assets/svg/tick.svg"
          class="tick-img"
          @click="tickClicked()"
          @keydown.enter="tickClicked()"
        />
        <img src="@/assets/svg/divider.svg" />
        <img
          src="@/assets/svg/cross.svg"
          class="cross-img"
          @click="crossClicked()"
          @keydown.esc="crossClicked()"
        />
      </div>
      <div v-else-if="tagLoading">
        <loading-progress
          :indeterminate="true"
          size="26"
          rotate
          fillDuration="2"
          rotationDuration="1"
          height="28"
          width="28"
        />
      </div>
      <div v-else class="cogs-container">
        <img src="@/assets/svg/edit.svg" class="edit-img" @click="clickEdit" />
      </div>
    </td>
    <td></td>
  </tr>
</template>

<script>
import * as poster from '@/api/poster';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'Product',
  props: ['product', 'category'],
  directives: {
    ClickOutside,
  },
  data() {
    return {
      editClicked: false,
      newTag: null,
      tagLoading: false,
    };
  },
  computed: {
    name() {
      return this.trimString(this.product.itemName, 70);
    },
    subname() {
      return (
        (this.product.parent ? 'PARENT: ' + this.product.parent + ' / ' : '') +
        'CHILD: ' +
        this.product.asin +
        ' / SKU: ' +
        this.product.sku.toUpperCase()
      );
    },
  },
  methods: {
    trimString(str, n) {
      return str.length > n ? str.substring(0, n) + '...' : str;
    },
    clickEdit() {
      this.editClicked = true;
    },
    crossClicked() {
      this.editClicked = false;
      this.newTag = null;
    },
    startLoading() {
      this.editClicked = false;
      this.tagLoading = true;
    },
    finishLoading() {
      this.newTag = null;
      this.tagLoading = false;
    },
    tickClicked() {
      this.newTag = this.newTag
        ? this.newTag.replace(/\s\s+/g, ' ').trim()
        : this.newTag;
      if (!this.validString(this.newTag)) {
        this.logValidStringMsg();
        return;
      }
      this.startLoading();
      poster
        .edit_product_tag(
          this.category,
          this.product.sku,
          this.product.asin,
          this.newTag
        )
        .then(({ data }) => (this.product.productTag = data.productTag))
        .catch(({ error }) =>
          this.$store.dispatch('logAlertError', {
            msg:
              'There was an error updating product tag for ' +
              this.product.sku +
              '. ' +
              error.message,
          })
        )
        .finally(() => this.finishLoading());
    },

    validString(val) {
      return !!val && val !== '';
    },
    logValidStringMsg() {
      this.$store.dispatch('logAlertInfo', { msg: 'Value must not be empty' });
    },
  },
};
</script>

<style scoped>
.cogs-container {
  position: relative;
}
.cogs-input {
  margin-right: -10px;
}
.cogs-input input {
  border: 1px solid #656ba6;
  border-radius: 5px;
  padding: 5px 10px;
  width: 70px;

  font-family: 'SourceSansPro-Regular';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;

  color: #64667a;
  text-align: right;
}
.cogs-input input:focus {
  border: 2px solid #656ba6;
}
.warning-img {
  margin-left: -30px;
  margin-top: 5px;
}

.edit-img:hover {
  background-color: #f2f2f2;
  border-radius: 5px;
}

.tick-img:hover {
  background-color: #ebf7f6;
  border-radius: 5px;
}
.cross-img:hover {
  background-color: #fff0f0;
  border-radius: 5px;
}
</style>
