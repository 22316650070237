<template>
  <div class="products" :class="{ 'products--loading': loading }">
    <div class="font-weight-semibold mt-4" v-if="!loadedProducts.length">
      No products found for this criteria
    </div>

    <div v-for="product of loadedProducts" :key="product.id">
      <TrafficAnalyticsProduct :product="product" :group-by="groupBy" />
    </div>

    <div
      class="product-placeholder opacity-30"
      v-for="index in Math.max(0, 4 - loadedProducts.length)"
      :key="index"
    >
      <span class="placeholder-text placeholder-text-short"></span>
      <span class="placeholder-text placeholder-text-medium"></span>
      <span class="placeholder-box placeholder-box-short"></span>
    </div>

    <v-btn
      v-if="!isLoadedAllProducts"
      @click="showMore()"
      color="white"
      class="primary--text d-block mx-auto products__more"
    >
      Show more
    </v-btn>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  percentageValueFormatter,
  simpleCurrencyValueFormatter,
  simpleNumberFormatter,
} from '@/common/ag-grid/value-formatters';
import TrafficAnalyticsProduct from '@/modules/DashboardLayout/TrafficAnalytics/TrafficAnalyticsProduct.vue';
import _ from 'lodash';

export const TA_PRODUCTS_INCREMENTAL = 10;

export default {
  name: 'TrafficAnalyticsProductsTable',
  components: {
    TrafficAnalyticsProduct,
  },
  props: ['tableData', 'sortBy', 'searchPhrase'],
  data() {
    return {
      loadedProductsCount: TA_PRODUCTS_INCREMENTAL,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'trafficAnalytics/productsIsLoading',
      productsData: 'trafficAnalytics/productsData',
      groupBy: 'trafficAnalytics/productsGroupBy',
    }),
    products() {
      let data = [...this.productsData];

      if (this.searchPhrase) {
        data = filterProductsByPhrase(data, this.searchPhrase);
      }

      data.sort((prev, next) => {
        if (prev.subproductsList && prev.subproductsList.length) {
          prev.subproductsList.sort((subPrev, subNext) => {
            return subPrev[this.sortBy] > subNext[this.sortBy] ? -1 : 1;
          });
        }

        return prev[this.sortBy] > next[this.sortBy] ? -1 : 1;
      });

      const products = mapToProductItem(data);

      this.$emit('onProductsFilter', products);

      return products;
    },
    loadedProducts() {
      return this.products.slice(0, this.loadedProductsCount);
    },
    isLoadedAllProducts() {
      return this.products.length <= this.loadedProductsCount;
    },
  },
  methods: {
    showMore() {
      this.loadedProductsCount += TA_PRODUCTS_INCREMENTAL;
    },
  },
  watch: {
    productsData() {
      this.loadedProductsCount = TA_PRODUCTS_INCREMENTAL;
    },
  },
};

function mapToProductItem(products) {
  return products.map(product => ({
    ...addFormattedValues(product),
    subProducts: _.orderBy(
      product.subproductsList.map(p => ({
        ...addFormattedValues(p),
      }))
    ),
  }));
}

function addFormattedValues(product) {
  return {
    ...product,
    salesFormatted: simpleCurrencyValueFormatter(product.sales),
    unitsFormatted: simpleNumberFormatter(product.units),
    sessionsFormatted: simpleNumberFormatter(product.sessions),
    conversionRateFormatted: percentageValueFormatter(product.conversionRate),
    tacosFormatted: percentageValueFormatter(product.tacos),
  };
}

function filterProductsByPhrase(products, phrase) {
  return products.filter(product => {
    return matchProductWithPhrase(product, phrase);
  });
}

function matchProductWithPhrase(product, phrase) {
  const { asin, parentAsin, title, category, subproductsList } = product;

  if (subproductsList?.length) {
    for (let subProduct of subproductsList) {
      if (matchProductWithPhrase(subProduct, phrase)) {
        return true;
      }
    }
  }

  return [asin, parentAsin, title, category]
    .join()
    .toLowerCase()
    .includes(phrase.toLowerCase());
}
</script>
<style lang="scss" scoped>
.products {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-top: 2rem;
  &--loading {
    opacity: 0.5;
  }
  &__more {
    margin-top: -0.5rem;
  }
}

.products-table-empty__text {
  z-index: 1;
  font: 600 16px 'Source Sans Pro';
  display: block;
  margin: 1rem 0;
}
</style>
