<template>
  <v-card class="app-login-card mx-auto pa-8 pa-md-14">
    <h2 class="app-login-card__title text-center mb-6 mb-md-12">Sign In</h2>

    <!-- Log In form -->
    <form role="form" @submit.prevent="onSubmit">
      <!-- Email input -->

      <label class="d-block font-weight-semibold mb-1" for="email">
        Email
      </label>
      <v-text-field
        outlined
        dense
        placeholder="Enter Your Email"
        hide-details="auto"
        class="f-18"
        v-model="model.username"
        id="email"
      ></v-text-field>

      <vertical-space-divider
        :config="{ height: formIndent }"
      ></vertical-space-divider>

      <!-- Password input -->
      <label class="d-block font-weight-semibold mb-1" for="password">
        Password
      </label>
      <v-text-field
        outlined
        dense
        placeholder="Enter Your Password"
        hide-details="auto"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        class="f-18"
        id="password"
        v-model="model.password"
      ></v-text-field>

      <div class="d-flex justify-flex-end">
        <button
          type="button"
          class="font-weight-semibold mt-3"
          @click="isResetPasswordModelOpen = true"
        >
          Forgot your password?
        </button>
      </div>

      <v-btn type="submit" block color="secondary" class="d-block mt-5" rounded>
        Sign In
      </v-btn>
    </form>

    <v-divider class="my-8"></v-divider>

    <p class="app-login-card__copy text-center">New to My Real Profit?</p>

    <v-btn class="register-btn" to="register" block outlined depressed rounded>
      Start free trial
    </v-btn>

    <ResetPasswordModal
      v-if="isResetPasswordModelOpen"
      :enabled.sync="isResetPasswordModelOpen"
    />
  </v-card>
</template>
<script>
import VerticalSpaceDivider from '@/common/components/VerticalSpaceDivider.vue';
import ResetPasswordModal from '@/modules/AuthLayout/ResetPasswordModal/ResetPasswordModal.vue';

export default {
  name: 'Login',
  metaInfo() {
    return {
      link: [{ rel: 'canonical', href: window.location.href.split('?')[0] }],
    };
  },
  components: {
    ResetPasswordModal,
    VerticalSpaceDivider,
  },
  data() {
    return {
      headerIndent: this.$mobile ? '32px' : '64px',
      formIndent: this.$mobile ? '16px' : '32px',
      showPassword: false,
      model: {
        username: null,
        password: null,
        rememberMe: false,
      },
      isResetPasswordModelOpen: false,
    };
  },
  methods: {
    onSubmit() {
      if (!this.inputPassed()) return;
      const credentials = {
        username: this.model.username.toLowerCase(),
        password: this.model.password,
      };
      this.$store.dispatch('login_controller', credentials);
    },
    inputPassed() {
      if (!this.model.username || !this.model.password) {
        this.$store.dispatch('logAlertError', {
          msg: 'Fields must not be empty',
        });
        return false;
      }
      return true;
    },
  },
  created() {
    if (this.$store.getters.accessToken) {
      this.$router.push('dashboard');
    }
  },
};
</script>

<style lang="scss" scoped>
.app-login-card {
  max-width: 500px;
  &__title {
    font: bold 44px Inter, serif;
    color: var(--gray-900);
  }
  &__copy {
    font-family: Inter, serif;
    font-weight: 600;
    color: var(--secondary-gray-color);
  }
}
.app-login-button {
  width: 50%;
}
.register-btn.v-btn--outlined {
  border: 1px solid #d7d6dc;
  font-weight: 600;
}
</style>
