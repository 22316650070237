import {
  bRoasValueGetter,
  marginValueGetter,
  netMarginValueGetter,
  netProfitValueGetter,
  profitBeforeAdsValueGetter,
  referralFeeValueGetter,
  roiBeforeAdsValueGetter,
  roiValueGetter,
} from './value-getters';
import { floatFormatter } from '@/common/ag-grid/value-formatters';

export function calculateNewData(currentData, newValue, newKey) {
  let {
    salePrice,
    fbaFee,
    storageFee,
    refundRate,
    cogs,
    tacos,
    referralPercent,
  } = {
    ...currentData,
    [newKey]: Number(newValue),
  };

  const referralFee = referralFeeValueGetter({ salePrice, referralPercent });

  const profitBeforeAds = profitBeforeAdsValueGetter({
    salePrice,
    fbaFee,
    referralFee,
    storageFee,
    refundRate,
    cogs,
  });

  const marginBeforeAds = marginValueGetter({ profitBeforeAds, salePrice });

  const roiBeforeAds = roiBeforeAdsValueGetter({ profitBeforeAds, cogs });

  const netProfit = netProfitValueGetter({
    profitBeforeAds,
    salePrice,
    tacos,
  });

  const netMargin = netMarginValueGetter({ salePrice, netProfit });

  const netRoi = roiValueGetter({ cogs, netProfit });
  const beAcos = marginBeforeAds;
  const beRoas = bRoasValueGetter({ salePrice, profitBeforeAds });

  return {
    ...currentData,
    referralFee: floatFormatter(referralFee),
    profitBeforeAds: floatFormatter(profitBeforeAds),
    marginBeforeAds: floatFormatter(marginBeforeAds),
    roiBeforeAds: floatFormatter(roiBeforeAds),
    netProfit: floatFormatter(netProfit),
    netMargin: floatFormatter(netMargin),
    netRoi: floatFormatter(netRoi),
    beAcos: floatFormatter(beAcos),
    beRoas: floatFormatter(beRoas),
    [newKey]: floatFormatter(Number(newValue)),
  };
}
