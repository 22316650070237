var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small-card sales-card section-shadow main-card flex-column justify-space-between"},[_c('div',{staticClass:"flex-row justify-space-between"},[_c('main-name-value',{attrs:{"content":{
        name: 'Sales',
        value: _vm.salesTotalString,
      },"config":{
        'align-items': 'flex-start',
        name: {
          weight: 'Medium',
          'text-transform': 'uppercase',
          'font-size': '16px',
          color: 'var(--main-gray-color)',
        },
        value: {
          weight: 'Bold',
          'font-size': '24px',
          color: 'var(--secondary-gray-color)',
        },
      }}}),(!_vm.salesFetched)?_c('loading-progress',{attrs:{"indeterminate":true,"size":"40","rotate":"","fillDuration":"2","rotationDuration":"1","height":"42","width":"42"}}):_c('img',{attrs:{"src":require("@/assets/svg/icons/icon-sales.svg"),"width":"44px","height":"44px"}})],1),_c('div',{staticClass:"flex-row justify-space-between"},[_c('main-name-value',{attrs:{"content":{
        name: 'organic',
        value: _vm.salesOrganicString,
      },"config":{
        'align-items': 'flex-start',
        name: {
          weight: 'Medium',
          'text-transform': 'uppercase',
          'font-size': '13px',
          color: 'var(--main-gray-color)',
        },
        value: {
          weight: 'Bold',
          'font-size': '13px',
          color: 'var(--main-green-color)',
        },
      }}}),_c('main-name-value',{attrs:{"content":{
        name: 'advertising',
        value: _vm.salesAdvertisingString,
      },"config":{
        'align-items': 'flex-end',
        name: {
          weight: 'Medium',
          'text-transform': 'uppercase',
          'font-size': '13px',
          color: 'var(--main-gray-color)',
        },
        value: {
          weight: 'Bold',
          'font-size': '13px',
          color: 'var(--fourth-purple-color)',
        },
      }}})],1),(_vm.salesFetched)?_c('main-progressbar',{staticStyle:{"position":"absolute","left":"0","bottom":"0"},attrs:{"config":{ value1: _vm.salesOrganicPercent, opacity: '1.0', size: '4px' }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }