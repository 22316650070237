<template>
  <v-card class="my-6 position-relative" :loading="loading">
    <div class="app-products-icon">
      <ProductsSelector
        :products="products"
        :selected="selectedSkus"
        :product-key="'sku'"
        @onSelectProducts="filterChart"
      />
    </div>

    <v-card-text class="py-5 position-relative">
      <ejs-chart
        align="left"
        ref="chart"
        :size="size"
        :title="title"
        :primaryXAxis="primaryXAxis"
        :primaryYAxis="primaryYAxis"
        :tooltip="tooltip"
        :chartArea="chartArea"
        :axes="axes"
        :palettes="palettes"
        :legendSettings="legendSettings"
        :crosshair="crosshair"
        :annotations="annotations"
        @sharedTooltipRender="onTooltipRender"
        @axisLabelRender="onAxisLabelRender"
        @pointRender="pointRender"
        :canResize="true"
      >
        <template v-slot:tooltipTemplate>
          <v-card class="app-tooltip">
            <h3 v-html="tooltipData.headerText" class="app-tooltip-title"></h3>
            <div class="app-tooltip-items">
              <div
                class="app-tooltip-item"
                :key="item.label"
                v-for="item of tooltipData.items"
              >
                <span
                  class="app-tooltip-indicator"
                  :style="{ backgroundColor: item.color }"
                ></span>

                <span class="app-tooltip-text" v-html="item.label"></span>
              </div>
            </div>
          </v-card>
        </template>
        <e-series-collection>
          <e-series
            :dataSource="seriesData"
            type="StackingColumn"
            groupName="group"
            xName="date"
            yName="sessionsOrganic"
            name="Organic Traffic"
            :cornerRadius="series['sessionsOrganic'].cornerRadius"
            columnWidth="0.75"
            :border="border"
            :minRadius="5"
            :animation="seriesAnimation"
          >
          </e-series>
          <e-series
            :dataSource="seriesData"
            type="StackingColumn"
            groupName="group"
            xName="date"
            yName="sessionsPaid"
            name="Paid Traffic"
            :cornerRadius="series['sessionsPaid'].cornerRadius"
            columnWidth="0.75"
            :border="border"
            :animation="seriesAnimation"
          >
          </e-series>
          <e-series
            :dataSource="seriesData"
            type="Line"
            xName="date"
            yName="conversionRate"
            name="Conversion Rate"
            width="2"
            opacity="0.5"
            yAxisName="conversionRate"
            :binInterval="50"
            :marker="marker"
            :animation="seriesAnimation"
          >
          </e-series>
        </e-series-collection>
      </ejs-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import { options } from './chart-settings';
import * as luxon from 'luxon';

import {
  Category,
  ChartAnnotation,
  ColumnSeries,
  Crosshair,
  DateTime,
  Legend,
  LineSeries,
  SplineSeries,
  StackingColumnSeries,
  Tooltip,
  Zoom,
} from '@syncfusion/ej2-vue-charts';
import { shade } from '@/utils/colors';
import { floatValueFormatter } from '@/common/ag-grid/value-formatters';
import ProductsSelector from '@/common/components/ProductsSelector/ProductsSelector.vue';
import { mapGetters } from 'vuex';
import Vue from 'vue';

// TODO: In future find better way
let contentVue = Vue.component('contentTemplate', {
  template: `
   <span style="writing-mode: vertical-rl; transform: rotate(180deg); font-weight: bold; letter-spacing: 0.3em; opacity: 0.5;"> NO DATA YET</span>`,
  data() {
    return {
      data: {},
    };
  },
});

export default {
  name: 'TrafficAnalyticsChart',
  components: { ProductsSelector },
  props: ['value'],
  provide: {
    chart: [
      SplineSeries,
      Legend,
      Tooltip,
      DateTime,
      StackingColumnSeries,
      Category,
      Crosshair,
      Zoom,
      ColumnSeries,
      LineSeries,
      ChartAnnotation,
    ],
  },
  data() {
    return {
      ...options.defaultSettings,
    };
  },
  methods: {
    onTooltipRender($event) {
      const { series, data } = $event;

      const date = data[0].pointX;

      const title = options.ranges['DAILY'].tooltipTitleFormatter(date);

      // don't rerender tooltip with the same data
      if (title === this.tooltipData?.headerText) {
        return;
      }

      const items = data
        .filter(({ seriesIndex }) =>
          series.some(({ index }) => seriesIndex === index)
        )
        .map(item => {
          const { pointY, seriesName, seriesIndex } = item;

          const value = options.series[seriesName].format(pointY);

          const findSeries = series.find(({ index }) => seriesIndex === index);

          return {
            label: `${seriesName}: <b>${value}</b>`,
            color: findSeries.interior,
          };
        });

      this.tooltipData = {
        headerText: title,
        items,
      };
    },
    onAxisLabelRender(args) {
      const {
        axis: {
          properties: { name },
        },
      } = args;

      if (name === 'primaryYAxis') {
        args.text = floatValueFormatter(args.text).toString();
      }

      if (name === 'conversionRate') {
        args.text = `${Number(args.text)}%`;
      }
    },
    pointRender(args) {
      if (args.point.yValue < 0) {
        args.fill = shade(args.fill, 50);
      }
    },
    filterChart(selectedProducts) {
      const selectedProductsSkus = selectedProducts.map(product => product.sku);

      this.$store.dispatch('trafficAnalytics/getChart', {
        skus: selectedProductsSkus,
        allProducts: this.products,
      });
    },
  },
  computed: {
    ...mapGetters({
      loading: 'trafficAnalytics/chartIsLoading',
      seriesData: 'trafficAnalytics/chartData',
      selectedSkus: 'trafficAnalytics/chartSkus',
      products: 'products/allData',
    }),
    annotations() {
      return (
        this.seriesData
          ?.filter(item => {
            const diffNowInDays = luxon.DateTime.fromISO(item.date).diffNow(
              'days'
            );
            return item.sessionsTotal === 0 && diffNowInDays.values.days > -3;
          })
          .map(item => ({
            content: () => ({
              template: contentVue,
            }),
            coordinateUnits: 'Point',
            x: item.date,
            y: 0,
            region: 'Series',

            yAxisName: 'conversionRate',
            horizontalAlignment: 'Center',
            verticalAlignment: 'Top',
          })) || []
      );
    },
  },
  mounted() {
    const rangeMargin = this.seriesData.reduce(
      (prev, current) => {
        const { min, max } = prev;

        return {
          min: Math.min(min, current.conversionRate),
          max: Math.max(max, current.conversionRate),
        };
      },
      {
        min: 0,
        max: 0,
      }
    );

    const marginInterval = Math.abs(rangeMargin.max - rangeMargin.min) / 10;

    let percentageInterval = 100;

    // Fast and ugly way, to improve later
    if (marginInterval < 5) {
      percentageInterval = 5;
    } else if (marginInterval < 10) {
      percentageInterval = 10;
    } else if (marginInterval < 50) {
      percentageInterval = 50;
    } else {
      percentageInterval = 100;
    }

    // TODO: To improve, create more generic way
    this.primaryXAxis = {
      ...this.primaryXAxis,
      ...options.ranges['DAILY'].primaryXAxis,
    };

    this.primaryYAxis = {
      ...this.primaryYAxis,
    };

    this.axes = [
      {
        ...this.axes[0],
        interval: percentageInterval,
      },
    ];

    this.$refs.chart.ej2Instances.animate(300);
  },
};
</script>
<style lang="scss" scoped>
.app-products-icon {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  z-index: 2;
}

::v-deep {
  .app-tooltip {
    padding: 10px 15px;
  }

  .app-tooltip-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 7px;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 7px;
  }

  .app-tooltip-items {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 15px;
  }

  .app-tooltip-item {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .app-tooltip-indicator {
    --size: 8px;
    width: var(--size);
    height: var(--size);
    border-radius: 100%;
    display: block;
  }
}
</style>
