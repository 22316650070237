<template>
  <div class="progressbar" :style="computedStyles"></div>
</template>

<script>
export default {
  name: 'MainProgressbar',
  props: ['config'],
  data() {
    return {
      // default styles - Horizontal progressbar
      size: '3px',
      color1: 'var(--secondary-green-color)',
      color2: 'var(--secondary-purple-color)',
      value1: 46,
      value2: 54,
      opacity: 1.0,
    };
  },
  created() {
    this.setConfigData();
  },
  methods: {
    setConfigData() {
      if (this.config) {
        this.size = this.config.size ? this.config.size : this.size;
        if (this.config.isVertical) {
          this.width = this.size;
          this.height = '100%';
          this.rotation = '180deg';
        } else {
          this.height = this.size;
          this.width = '100%';
          this.rotation = '90deg';
        }

        this.value1 =
          this.config.value1 !== null
            ? this.trim_value(this.config.value1) - 3
            : this.value1;
        this.value2 =
          this.config.value1 !== null
            ? this.trim_value(this.config.value1) + 3
            : this.value2;
        this.color1 = this.config.color1 ? this.config.color1 : this.color1;
        this.color2 = this.config.color2 ? this.config.color2 : this.color2;
        this.opacity = this.config.opacity ? this.config.opacity : this.opacity;
      }
    },
    trim_value(val) {
      if (val < 4) return 4;
      else if (val > 96) return 96;
      else return val;
    },
  },
  computed: {
    computedStyles: function() {
      return [
        {
          width: this.width,
          height: this.height,
          opacity: this.opacity,
          background:
            'linear-gradient(' +
            this.rotation +
            ', ' +
            this.color1 +
            ' ' +
            this.value1 +
            '%, ' +
            this.color2 +
            ' ' +
            this.value2 +
            '%)',
        },
      ];
    },
  },
};
</script>

<style scoped>
.progressbar {
  border-radius: 2px;
}
</style>
