export function sortVariationsProductsBy(sortBy) {
  switch (sortBy) {
    case 'Sales':
      return (a, b) =>
        a.variationTotal.sales.total > b.variationTotal.sales.total ? -1 : 1;
    case 'Units':
      return (a, b) =>
        a.variationTotal.units.total > b.variationTotal.units.total ? -1 : 1;
    case 'Expenses':
      return (a, b) =>
        a.variationTotal.expenses.total > b.variationTotal.expenses.total
          ? -1
          : 1;
    case 'Profit':
      return (a, b) =>
        a.variationTotal.profit.total > b.variationTotal.profit.total ? -1 : 1;
    case 'ROI':
      return (a, b) =>
        a.variationTotal.profit.roi > b.variationTotal.profit.roi ? -1 : 1;
    case 'Ad Spent':
      return (a, b) =>
        a.variationTotal.expenses.getExpense('Advertising').total >
        b.variationTotal.expenses.getExpense('Advertising').total
          ? -1
          : 1;
    case 'Discounts':
      return (a, b) =>
        a.variationTotal.expenses.getExpense('Discounts').total >
        b.variationTotal.expenses.getExpense('Discounts').total
          ? -1
          : 1;
    case 'Refund Rate':
      return (a, b) =>
        a.variationTotal.refundRate > b.variationTotal.refundRate ? -1 : 1;
    case 'TACoS':
      return (a, b) =>
        getPercent(
          a.variationTotal.expenses.getExpense('Advertising').total,
          a.variationTotal.sales.total
        ) >
        getPercent(
          b.variationTotal.expenses.getExpense('Advertising').total,
          b.variationTotal.sales.total
        )
          ? -1
          : 1;
    default:
      return (a, b) =>
        a.variationTotal.sales.total > b.variationTotal.sales.total ? -1 : 1;
  }
}

export function sortProductsBy(sortBy) {
  switch (sortBy) {
    case 'Sales':
      return (a, b) => (a.sales.total > b.sales.total ? -1 : 1);
    case 'Units':
      return (a, b) => (a.units.total > b.units.total ? -1 : 1);
    case 'Expenses':
      return (a, b) => (a.expenses.total > b.expenses.total ? -1 : 1);
    case 'Profit':
      return (a, b) => (a.profit.total > b.profit.total ? -1 : 1);
    case 'ROI':
      return (a, b) => (a.profit.roi > b.profit.roi ? -1 : 1);
    case 'Ad Spent':
      return (a, b) =>
        a.expenses.getExpense('Advertising').total >
        b.expenses.getExpense('Advertising').total
          ? -1
          : 1;
    case 'Discounts':
      return (a, b) =>
        a.expenses.getExpense('Discounts').total >
        b.expenses.getExpense('Discounts').total
          ? -1
          : 1;
    case 'Refund Rate':
      return (a, b) => (a.refundRate > b.refundRate ? -1 : 1);
    case 'TACoS':
      return (a, b) =>
        getPercent(a.expenses.getExpense('Advertising').total, a.sales.total) >
        getPercent(b.expenses.getExpense('Advertising').total, b.sales.total)
          ? -1
          : 1;

    default:
      return (a, b) => (a.sales.total > b.sales.total ? -1 : 1);
  }
}

function getPercent(a, b) {
  return b ? (a / b) * 100 : 0;
}
