<template>
  <tr
    :class="{
      listingsRowClicked: cogsClicked,
    }"
  >
    <td>
      <img :src="imageUrl" class="product-image" />
      <div class="product-container">
        <div class="product-name">
          {{ name }}
        </div>
        <div class="product-sku">
          {{ subname }}
        </div>
      </div>
    </td>
    <td>
      <div class="product-status-container">
        <div v-if="missingCogs">
          <img src="@/assets/svg/warning.svg" class="warning-img" />
        </div>
        <div class="product-status">
          <div
            :class="{
              active: listing.status.toLowerCase() === 'active',
              inactive: listing.status.toLowerCase() === 'inactive',
            }"
          >
            {{
              listing.status.toLowerCase() === 'active' ? 'Active' : 'Inactive'
            }}
          </div>
        </div>
      </div>
    </td>
    <td>
      <div v-if="cogsClicked" class="flex-row justify-flex-end">
        <div class="cogs-input">
          <v-text-field
            autofocus
            outlined
            dense
            hide-details="auto"
            v-model="updatedCogsValue"
          ></v-text-field>
        </div>
      </div>
      <div v-else-if="cogsLoading"></div>
      <div v-else @click="clickCogs">
        <div>
          {{ listing.cogsValue }}
        </div>
      </div>
    </td>
    <td>
      <div v-if="cogsClicked" class="cogs-container">
        <cogs-modal
          v-if="modalShowed"
          @apply-clicked="applyModalClicked"
          @close-clicked="closeModalClicked"
        ></cogs-modal>
        <img
          src="@/assets/svg/tick.svg"
          class="tick-img"
          @click="tickClicked()"
        />
        <img src="@/assets/svg/divider.svg" />
        <img
          src="@/assets/svg/cross.svg"
          class="cross-img"
          @click="closeCogs()"
        />
      </div>
      <div v-else-if="cogsLoading">
        <loading-progress
          :indeterminate="true"
          size="26"
          rotate
          fillDuration="2"
          rotationDuration="1"
          height="28"
          width="28"
        />
      </div>
      <div v-else class="cogs-container">
        <img
          src="@/assets/svg/edit.svg"
          class="edit-img"
          @click="clickCogs()"
        />
      </div>
    </td>
    <td></td>
  </tr>
</template>

<script>
import * as poster from '@/api/poster';

import ClickOutside from 'vue-click-outside';
import CogsModal from '@/modules/DashboardLayout/ProductsAndCogs/modals/CogsModal.vue';

export default {
  components: { CogsModal },
  name: 'Product',
  props: ['listing'],
  directives: {
    ClickOutside,
  },
  data() {
    return {
      cogsClicked: false,
      updatedCogsValue: null,
      modalShowed: false,
      cogsLoading: false,
    };
  },
  computed: {
    imageUrl() {
      return this.listing.imageUrl
        ? this.listing.imageUrl.replace('._SL75_', '')
        : '../png/undefined.png';
    },
    name() {
      return this.trimString(this.listing.itemName, 60);
    },
    subname() {
      return (
        (this.listing.parent ? 'PARENT: ' + this.listing.parent + ' / ' : '') +
        'CHILD: ' +
        this.listing.asin +
        ' / SKU: ' +
        this.listing.sku.toUpperCase()
      );
    },
    missingCogs() {
      return (
        this.listing.status.toLowerCase() === 'active' &&
        (!this.listing.cogsValue || !parseFloat(this.listing.cogsValue))
      );
    },
    cogsValue() {
      return this.listing.cogsValue ? parseFloat(this.listing.cogsValue) : 0;
    },
    updatedPrice() {
      return parseFloat(this.updatedCogsValue);
    },
  },
  methods: {
    trimString(str, n) {
      return str.length > n ? str.substring(0, n) + '...' : str;
    },
    clickCogs() {
      this.cogsClicked = true;
    },
    closeCogs() {
      this.cogsClicked = false;
      this.updatedCogsValue = null;
      this.modalShowed = false;
    },
    startLoading() {
      this.cogsLoading = true;
      this.cogsClicked = false;
      this.modalShowed = false;
    },
    finishLoading() {
      this.updatedCogsValue = null;
      this.cogsLoading = false;
    },
    tickClicked() {
      if (this.validPrice(this.updatedPrice)) {
        this.modalShowed = true;
      } else {
        this.logValidPriceMsg();
      }
    },

    applyModalClicked(history) {
      if (!this.validPrice(this.updatedPrice)) {
        this.logValidPriceMsg();
        return;
      }
      let cogsBuffer = this.updatedPrice;
      this.startLoading();
      poster
        .post_cogs(
          this.listing.sku,
          this.listing.asin,
          cogsBuffer,
          this.getStartDate(history)
        )
        .then(() => {
          this.$store.dispatch('logAlertSuccess', {
            msg: "You successfully updated CoGS for '" + this.listing.sku + "'",
          });
          this.listing.cogsValue = cogsBuffer;
        })
        .catch(() => {
          this.$store.dispatch('logAlertError', {
            msg:
              "There was an error updating CoGS for '" + this.listing.sku + "'",
          });
        })
        .finally(() => this.finishLoading());
    },
    closeModalClicked() {
      this.modalShowed = false;
    },

    validPrice(val) {
      return !!val && typeof val == 'number' && val >= 0;
    },
    logValidPriceMsg() {
      this.$store.dispatch('logAlertInfo', 'Value must be a positive number');
    },
    getStartDate(history) {
      return history === 'HISTORY' ? '2019-01-01' : this.getToday();
    },
    getToday() {
      let today = new Date();
      return today.toISOString().split('T')[0];
    },
  },
};
</script>

<style scoped>
.cogs-container {
  position: relative;
}
.cogs-input {
  max-width: 120px;
}
.cogs-input input {
  border: 1px solid #656ba6;
  border-radius: 5px;
  padding: 5px 10px;

  font-family: 'SourceSansPro-Regular';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;

  color: #64667a;
  text-align: right;
}
.cogs-input input:focus {
  border: 2px solid #656ba6;
}
.warning-img {
  margin-left: -30px;
  margin-top: 5px;
}

.edit-img:hover {
  background-color: #f2f2f2;
  border-radius: 5px;
}

.tick-img:hover {
  background-color: #ebf7f6;
  border-radius: 5px;
}
.cross-img:hover {
  background-color: #fff0f0;
  border-radius: 5px;
}
</style>
