import * as luxon from 'luxon';
import {
  percentageValueFormatter,
  simpleIntegerValueFormatter,
} from '@/common/ag-grid/value-formatters';

export const axisLabelStyle = {
  size: '11px',
  color: '#AAA',
  fontFamily: 'Inter',
};

export const options = {
  series: {
    'Organic Traffic': {
      format: value => simpleIntegerValueFormatter(value),
    },
    'Paid Traffic': {
      format: value => simpleIntegerValueFormatter(value),
    },
    'Conversion Rate': {
      format: value => percentageValueFormatter(value),
    },
  },
  ranges: {
    DAILY: {
      tooltipTitleFormatter: date => {
        return luxon.DateTime.fromJSDate(date, {
          zone: 'UTC',
        }).toFormat('cccc, LLL d', {
          locale: 'en',
        });
      },
      primaryXAxis: {
        labelFormat: 'MMM dd',
        intervalType: 'Days',
      },
    },
    WEEKLY: {
      tooltipTitleFormatter: date => {
        // TODO: change format
        return luxon.DateTime.fromJSDate(date, {
          zone: 'UTC',
        }).toFormat('cccc, LLL d', {
          locale: 'en',
        });
      },
    },
  },
  defaultSettings: {
    seriesAnimation: {
      enabled: true,
      duration: 100,
    },
    border: { color: 'white', width: 2.5 },
    size: {
      width: '100%',
      height: '100%',
    },
    crosshair: {
      enable: true,
      lineType: 'Both',
      line: {
        color: '#DDD',
      },
    },
    chartArea: {
      border: {
        width: 0,
      },
    },
    marker: {
      visible: true,
      width: 10,
      height: 10,
    },
    tooltip: {
      enable: true,
      shared: true,
      fill: '#FFF',
      opacity: 1,
      border: {
        color: '#DDD',
        width: 1,
      },
      textStyle: {
        color: '#000',
      },
      template: 'tooltipTemplate',
    },
    title: '',

    series: {
      sessionsOrganic: {
        cornerRadius: {
          topLeft: 4,
          topRight: 4,
          bottomLeft: 4,
          bottomRight: 4,
        },
      },
      sessionsPaid: {
        cornerRadius: {
          topLeft: 4,
          topRight: 4,
          bottomLeft: 4,
          bottomRight: 4,
        },
      },
    },
    primaryXAxis: {
      valueType: 'DateTime',
      labelFormat: 'HH:mm',
      intervalType: 'Hours',
      edgeLabelPlacement: 'Shift',
      majorGridLines: { width: 0 },
      majorTickLines: {
        color: 'white',
      },
      labelPlacement: 'onTicks',
      labelStyle: axisLabelStyle,
    },
    primaryYAxis: {
      labelFormat: 'N0',
      lineStyle: { width: 0 },
      majorGridLines: {
        color: '#EEE',
        width: 1,
      },
      majorTickLines: { width: 0 },
      minorTickLines: { width: 0 },
      labelStyle: axisLabelStyle,
      rowIndex: 0,
    },
    axes: [
      {
        labelFormat: 'N0',
        majorGridLines: { width: 0 },
        rowIndex: 0,
        opposedPosition: true,
        lineStyle: { width: 0 },
        majorTickLines: { width: 0 },
        name: 'conversionRate',
        minorTickLines: { width: 0 },
        labelStyle: axisLabelStyle,
        interval: 100,
        crossesInAxis: 'primaryYAxis',
      },
    ],
    legendSettings: {
      visible: true,
      textStyle: {
        ...axisLabelStyle,
        size: '13px',
      },
      margin: {
        top: 0,
        bottom: 30,
      },
      padding: 0,
      position: 'Top',
      alignment: 'Center',
    },

    // sales, profit, margin
    palettes: ['#4fa186', '#776ae6', '#DB9C9C'],
  },
};
