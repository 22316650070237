<template>
  <div class="delete-modal-container">
    <card
      :content="{
        header: { text: content.header },
        text: { text: ' ' },
      }"
      :config="{ width: '350px' }"
    >
      <img
        src="@/assets/svg/tick-inactive.svg"
        class="tick-img"
        @click="closeClicked()"
      />

      <template v-slot:content>
        <div class="flex-column align-flex-start">
          <div class="flex-row">
            <inter
              :content="{
                text: content.text,
              }"
              :config="{
                weight: 'Regular',
                color: 'var(--gray-700)',
                'font-size': '18px',
                'line-height': '28px',
              }"
            ></inter>
            <horizontal-space-divider
              :config="{ width: '16px' }"
            ></horizontal-space-divider>
            <inter
              :content="{
                text: calendarDateDisplay,
              }"
              :config="{
                weight: 'Medium',
                color: 'var(--gray-700)',
                'font-size': '18px',
                'line-height': '28px',
              }"
            ></inter>
          </div>

          <vertical-space-divider
            :config="{ height: '16px' }"
          ></vertical-space-divider>
          <date-picker
            v-model="calendarDate"
            color="purple"
            trim-weeks
            :step="1"
            mode="date"
          >
          </date-picker>
          <vertical-space-divider
            :config="{ height: '8px' }"
          ></vertical-space-divider>
        </div>
      </template>

      <template v-slot:buttons>
        <v-btn color="secondary" @click="yesClicked()">Update</v-btn>
      </template>
    </card>
  </div>
</template>

<script>
import Card from '@/common/components/Card.vue';
import VerticalSpaceDivider from '@/common/components/VerticalSpaceDivider.vue';
import HorizontalSpaceDivider from '@/common/components/HorizontalSpaceDivider.vue';
import Inter from '@/common/components/Texts/Inter.vue';

import { DateTime } from 'luxon';

export default {
  name: 'UpdateDateModal',
  props: ['content'],
  components: {
    Card,
    Inter,
    VerticalSpaceDivider,
    HorizontalSpaceDivider,
  },
  data() {
    return {
      calendarDate: null,
    };
  },
  methods: {
    clearForm() {
      this.calendarDate = null;
    },
    yesClicked() {
      if (this.calendarDate) {
        this.$emit('yes-clicked', this.calendarDateISO);
        this.clearForm();
      }
    },
    closeClicked() {
      this.$emit('close-clicked');
      this.clearForm();
    },
  },
  computed: {
    calendarDateDisplay() {
      if (!this.calendarDate) return 'unset';
      return this.calendarDateLA.toISO().split('T')[0];
    },
    calendarDateLA() {
      const zoneOffset = this.calendarDate.getTimezoneOffset();

      //this casting has to be executed, since js Date object works poorly
      const dateUTC = DateTime.fromJSDate(this.calendarDate)
        .minus({ minutes: zoneOffset })
        .toUTC();
      return DateTime.fromObject({
        year: dateUTC.year,
        month: dateUTC.month,
        day: dateUTC.day,
        zone: 'America/Los_Angeles',
      });
    },
    calendarDateISO() {
      if (this.content.startDate)
        return DateTime.fromISO(this.calendarDateLA.startOf('day'), {
          setZone: true,
        })
          .toUTC()
          .toISO();
      else
        return DateTime.fromISO(this.calendarDateLA.endOf('day'), {
          setZone: true,
        })
          .toUTC()
          .toISO();
    },
  },
};
</script>

<style scoped>
.delete-modal-container {
  position: relative;
  z-index: 999999;
  top: 25px;
  left: -100px;
}
.tick-img {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
</style>
