var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.stateAuth && _vm.tokens_success)?_c('div',{staticClass:"flex-column align-flex-start"},[_c('vertical-space-divider'),_c('rajdhani',{attrs:{"content":{ text: 'Connect Amazon Account' },"config":{
      weight: 'Bold',
      'text-transform': 'uppercase',
      'font-size': '44px',
      'line-height': '56px',
      color: '#c3c9de',
    }}}),_c('vertical-space-divider'),_c('div',{staticClass:"section-shadow main-card d-block flex-column save-seller-card"},[_vm._m(0),_c('div',[_vm._m(1),_c('form',{staticClass:"flex-row justify-space-between align-flex-end save-seller-form-container",on:{"submit":function($event){$event.preventDefault();return _vm.saveSellerClicked.apply(null, arguments)}}},[_c('div',[_c('label',{staticClass:"d-block font-weight-semibold mb-1",attrs:{"for":"brand-name"}},[_vm._v(" Brand Name ")]),_c('v-text-field',{staticClass:"f-18",attrs:{"outlined":"","dense":"","solo":"","placeholder":"Enter Your Brand Name","hide-details":"auto","id":"brand-name"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v(" Save and Continue ")])],1)])])],1):(_vm.stateUpdate && _vm.tokens_success)?_c('div',{staticClass:"flex-column align-flex-start loader"},[_c('loading-progress',{attrs:{"indeterminate":true,"size":"29","rotate":"","fillDuration":"2","rotationDuration":"1","height":"30","width":"30"}})],1):_c('div',{staticClass:"flex-column align-flex-start"},[_c('vertical-space-divider'),_c('rajdhani',{attrs:{"content":{ text: 'Connect Amazon Account' },"config":{
      weight: 'Bold',
      'text-transform': 'uppercase',
      'font-size': '44px',
      'line-height': '56px',
      color: '#c3c9de',
    }}}),_c('vertical-space-divider'),_c('div',{staticClass:"section-shadow main-card flex-column save-seller-card"},[_vm._m(2),_c('div',{staticClass:"save-seller-text-container"},[_c('span',{staticClass:"save-seller-text"},[_vm._v(" There was an error authorizing your Amazon account. "),_c('br'),_vm._v(" Please, contact My Real Profit support for help or "),_c('b',[_c('router-link',{attrs:{"to":"/settings"}},[_vm._v("try again")])],1),_vm._v(". ")])]),_c('div',{staticClass:"flex-row contact-support-container"},[_c('v-btn',{attrs:{"color":"primary","href":"mailto:support@myrealprofit.com"}},[_vm._v(" Contact Support ")])],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-card-header"},[_c('span',{staticClass:"main-card-header-text"},[_vm._v(" Step 1 of 2 Completed ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"save-seller-text-container"},[_c('span',{staticClass:"save-seller-text"},[_vm._v(" Your Amazon account has been successfully connected. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-card-header"},[_c('span',{staticClass:"main-card-header-text"},[_vm._v(" Error ")])])}]

export { render, staticRenderFns }