import { $api } from '@/main';

export const ProfitCalculatorApi = {
  get({ sellerId, marketplaceId }) {
    const params = {
      sellerId,
      marketplaceId,
    };
    return $api.make_get('profit-calculator', 'profit_calculator', { params });
  },
};
