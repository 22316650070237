<template>
  <v-card
    class="summary-box position-relative pb-2 w-100 overflow-hidden"
    :loading="loading"
  >
    <v-card-text class="pt-4 pb-2 px-7">
      <div class="d-flex">
        <div>
          <div class="summary-box__title">{{ title }}</div>
          <div class="summary-box__value mt-1">{{ value }}</div>
        </div>
        <div class="ml-auto">
          <slot name="icon"></slot>
        </div>
      </div>
      <div class="d-flex mt-4">
        <div>
          <div class="summary-box__bottom-title">
            {{ left.title }}
          </div>
          <strong
            class="text-small"
            :class="[left.value >= 0 ? left.color + '--text' : 'red--text']"
          >
            {{ left.formattedValue }} · {{ leftPercent }}
          </strong>
        </div>
        <div class="ml-auto text-right">
          <div class="summary-box__bottom-title">
            {{ right.title }}
          </div>
          <strong
            class="text-small"
            :class="[right.value >= 0 ? right.color + '--text' : 'red--text']"
          >
            {{ right.formattedValue }} ·
            {{ rightPercent }}
          </strong>
        </div>
      </div>
    </v-card-text>

    <div class="position-absolute b-0 w-100">
      <FadeProgressBar :value="progressBarData"></FadeProgressBar>
    </div>
  </v-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { percentageValueFormatter } from '@/common/ag-grid/value-formatters';
import FadeProgressBar from '@/common/components/FadeProgressBar.vue';

export default defineComponent({
  name: 'SummaryBox',
  components: { FadeProgressBar },
  props: ['data', 'loading'],
  computed: {
    title() {
      return this.data.title;
    },
    value() {
      return this.data.value;
    },
    left() {
      return this.data.left;
    },
    allSummary() {
      return Math.abs(this.left.value) + Math.abs(this.right.value);
    },
    leftPercent() {
      return percentageValueFormatter(
        (this.left.value / this.allSummary) * 100
      );
    },
    right() {
      return this.data.right;
    },
    rightPercent() {
      return percentageValueFormatter(
        (this.right.value / this.allSummary) * 100
      );
    },
    progressBarData() {
      return [
        {
          value: (this.left.value / this.allSummary) * 100,
          color: this.$vuetify.theme.currentTheme[this.left.color],
        },
        {
          value: (this.right.value / this.allSummary) * 100,
          color: this.$vuetify.theme.currentTheme[this.right.color],
        },
      ];
    },
    fakeData() {
      return [
        {
          value: 20,
          color: 'red',
        },
        {
          value: 10,
          color: 'yellow',
        },
      ];
    },
  },
});
</script>

<style lang="scss" scoped>
.summary-box {
  .v-card__text {
    line-height: 1;
  }

  &__title {
    font-family: Rajdhani, serif;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--gray-500);
  }
  &__value {
    font-size: 1.5rem;
    font-weight: 700;
    color: black;
  }
  &__bottom-title {
    font-family: Rajdhani, serif;
    font-size: 0.85rem;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--gray-500);
  }
}
</style>
