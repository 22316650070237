<template>
  <div>
    <!-- Power BI iframe -->
    <iframe
      title="Market Share & Funnel"
      :src="currentReportLink"
      width="1080"
      height="2300"
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'MarketShare',
  data() {
    return {};
  },
  computed: {
    currentReportLink() {
      return 'https://app.powerbi.com/reportEmbed?reportId=c837fd09-ebf6-4faf-a78c-f759a773bd5f&autoAuth=true&ctid=6cf08673-3f89-4e64-bafc-e90a9ca2340d';
    },
  },
  created() {},
  methods: {},
};
</script>

<style scoped></style>
