<template>
  <div>
    <!-- Power BI iframe -->
    <iframe
      title="Advertising Report"
      :src="currentReportLink"
      width="1080"
      height="2500"
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'Advertising',
  data() {
    return {};
  },
  computed: {
    currentReportLink() {
      return 'https://app.powerbi.com/reportEmbed?reportId=4ca19c6a-e00d-4a74-a442-fc0ea1652688&autoAuth=true&ctid=6cf08673-3f89-4e64-bafc-e90a9ca2340d';
    },
  },
  created() {},
  methods: {},
};
</script>

<style scoped></style>
