<template>
  <div class="d-flex align-center">
    {{ this.value }}

    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          x-small
          icon
          color="grey lighten-2"
          class="ml-2"
          @click="edit"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edit</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: '',
    };
  },
  mounted() {
    this.value = this.params?.valueFormatted || this.params.value;
  },
  methods: {
    edit() {
      this.params.api.startEditingCell({
        rowIndex: this.params.rowIndex,
        colKey: this.params.column.colId,
      });
    },
  },
};
</script>
<style scoped></style>
