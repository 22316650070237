<template>
  <div class="pt-4 h-100">
    <router-link to="/dashboard" class="ml-4 mt-3">
      <v-btn color="white" type="button">
        <v-icon left color="primary">
          mdi-arrow-left
        </v-icon>
        Back to dashboard
      </v-btn>
    </router-link>

    <div class="flex-row align-center my-3 mx-4 gap-3">
      <h2 class="page-title text-uppercase">Profit Calculator</h2>

      <v-tooltip bottom right max-width="400">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="primary" v-bind="attrs" v-on="on">
            mdi-information-outline
          </v-icon>
        </template>
        <div>
          <p>
            This calculator is designed to help you understand your profit per
            unit sold, including all Amazon costs associated with each sale.
          </p>

          <p>
            You can edit the columns that have
            <v-icon small class="mx-1" color="primary">mdi-pencil</v-icon> icon
            to estimate how certain changes in metrics might affect your profit
            per unit. For example: pricing changes, marketing strategy planning
            using TACoS, etc.
          </p>
          <p>
            This calculator uses Amazon’s real-time data for selling price, FBA
            fees, and referral fees.
          </p>
          <p>
            Refunds, TACoS and storage costs are calculated based on each
            product’s last 30 days of data.
          </p>
        </div>
      </v-tooltip>

      <GuidelineVideo :link="guidelinesVideos.profitCalculator" />
    </div>

    <div class="d-flex align-center  mx-4 mb-6 mt-3">
      <v-text-field
        solo
        outlined
        dense
        clearable
        label="Search by product name / sku / asin"
        hide-details="auto"
        class="app-search-input"
        prepend-inner-icon="mdi-magnify"
        v-model="phrase"
        v-on:input="phraseChange()"
      ></v-text-field>

      <v-btn
        color="white"
        depressed
        class="elevation-1 ml-5"
        @click="exportToCsv()"
      >
        Export to CSV
        <v-icon class="ml-3" color="primary" size="20">
          mdi-cloud-download
        </v-icon>
      </v-btn>

      <p class="text--secondary mb-0 ml-5">
        Storage, refunds, and TACOS are based on the last 30 days’ data.
      </p>
    </div>

    <div class="app-grid d-flex align-center justify-center" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
        class="ma-auto"
      ></v-progress-circular>
    </div>

    <ag-grid-vue
      v-if="!loading"
      class="app-grid ag-theme-mrp ag-theme-material"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :defaultColDef="defaultColDef"
      :stopEditingWhenCellsLoseFocus="true"
      :suppressClickEdit="false"
      :readOnlyEdit="true"
      :cacheQuickFilter="true"
      :getRowId="getRowId"
      :rowHeight="rowHeight"
      :suppressDragLeaveHidesColumns="true"
      :scrollbarWidth="0"
      @grid-ready="onGridReady"
      @cell-edit-request="onCellEditRequest"
      @rowDataUpdated="onRowDataUpdated"
      @gridSizeChanged="onGridSizeChanged"
    >
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import {
  ProductDetailsCellRenderer,
  NumericCellEditor,
  EditableRenderer,
} from '@/common/ag-grid/renderers';
import { calculateNewData, createColumnDefs, defaultColDef } from './helpers';
import { ProfitCalculatorApi } from '@/api/profit-calculator';
import { guidelinesVideos } from '@/utils/guidelines-videos';
import GuidelineVideo from '@/common/components/GuidelineVideo.vue';

export default {
  data() {
    return {
      columnDefs: null,
      defaultColDef: defaultColDef,
      gridApi: null,
      phrase: '',
      rowHeight: 75,
      loading: true,
      rowData: null,
    };
  },
  components: {
    GuidelineVideo,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    ProductDetailsCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    NumericCellEditor,
    // eslint-disable-next-line vue/no-unused-components
    EditableRenderer,
  },

  async mounted() {
    await ProfitCalculatorApi.get({
      sellerId: this.currentSellerId,
      marketplaceId: this.marketplaceId,
    }).then(response => {
      this.rowData = response.data;

      this.columnDefs = createColumnDefs({
        rowDataInitial: [...response.data],
      });

      this.loading = false;
    });
  },
  computed: {
    guidelinesVideos() {
      return guidelinesVideos;
    },
    currentSellerId() {
      return this.$store.getters.currentSellerId;
    },
    marketplaceId() {
      return this.$store.getters.currentMarketplaceId;
    },
  },
  methods: {
    getRowId(params) {
      return params.data.sku;
    },

    onGridReady({ api }) {
      this.gridApi = api;

      this.gridApi.sizeColumnsToFit();
    },

    onRowDataUpdated() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },

    onGridSizeChanged() {
      this.gridApi.sizeColumnsToFit();
    },

    onCellEditRequest(params) {
      const newData = calculateNewData(
        params.node.data,
        params.newValue,
        params.column.colId
      );

      const flashColumns = [];

      Object.entries(newData).forEach(([key, value]) => {
        if (params.node.data[key] !== value && key !== params.column.colId) {
          flashColumns.push(key);
        }
      });

      params.node.setData(newData);

      this.gridApi.flashCells({
        rowNodes: [params.node],
        columns: flashColumns,
      });
    },

    phraseChange() {
      this.gridApi.setQuickFilter(this.phrase);
    },

    exportToCsv() {
      const columnsToExport = [
        'title',
        'sku',
        'asin',
        'salePrice',
        'fbaFee',
        'referralFee',
        'cogs',
        'storageFee',
        'refundRate',
        'tacos',
        'profitBeforeAds',
        'marginBeforeAds',
        'roiBeforeAds',
        'netProfit',
        'netMargin',
        'netRoi',
        'beAcos',
        'beRoas',
      ];
      this.gridApi.exportDataAsCsv({
        skipColumnGroupHeaders: true,
        columnKeys: columnsToExport,
        fileName: 'mrp_profit_calculator.csv',
      });
    },
  },
};
</script>
<style scoped lang="scss">
.app-grid {
  height: calc(100vh - 215px);
}
.app-search-input {
  max-width: 400px;
}

::v-deep {
  .ag-cell-value {
    font-weight: 600;
  }
  .unit-economics-header {
    background: #db9c9c !important;
    color: white;
  }
  .before-ads-header {
    background: #776ae6 !important;
    color: white;
  }
  .net-metrics-header {
    background: #4fa186 !important;
    color: white;
  }
  .border-right {
    border-right: 1px solid #e2e2e2;
  }
  .ag-theme-material .ag-cell-inline-editing {
    border: none !important;
    box-shadow: none !important;
  }
}
</style>
