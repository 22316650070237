<template>
  <div
    class="small-card units-card section-shadow main-card flex-column justify-space-between"
  >
    <div class="flex-row justify-space-between">
      <main-name-value
        :content="{
          name: 'Units',
          value: unitsTotalString,
        }"
        :config="{
          'align-items': 'flex-start',
          name: {
            weight: 'Medium',
            'text-transform': 'uppercase',
            'font-size': '16px',
            color: 'var(--main-gray-color)',
          },
          value: {
            weight: 'Bold',
            'font-size': '24px',
            color: 'var(--secondary-gray-color)',
          },
        }"
      ></main-name-value>

      <loading-progress
        v-if="!unitsFetched"
        :indeterminate="true"
        size="40"
        rotate
        fillDuration="2"
        rotationDuration="1"
        height="42"
        width="42"
      />
      <img
        v-else
        src="@/assets/svg/icons/icon-units.svg"
        width="44px"
        height="44px"
      />
    </div>

    <div class="flex-row justify-space-between">
      <main-name-value
        :content="{
          name: 'full price',
          value: unitsFullPriceString,
        }"
        :config="{
          'align-items': 'flex-start',
          name: {
            weight: 'Medium',
            'text-transform': 'uppercase',
            'font-size': '13px',
            color: 'var(--main-gray-color)',
          },
          value: {
            weight: 'Bold',
            'font-size': '13px',
            color: 'var(--main-green-color)',
          },
        }"
      ></main-name-value>
      <main-name-value
        :content="{
          name: 'discounts',
          value: unitsCouponsString,
        }"
        :config="{
          'align-items': 'flex-end',
          name: {
            weight: 'Medium',
            'text-transform': 'uppercase',
            'font-size': '13px',
            color: 'var(--main-gray-color)',
          },
          value: {
            weight: 'Bold',
            'font-size': '13px',
            color: 'var(--fourth-purple-color)',
          },
        }"
      ></main-name-value>
    </div>
    <main-progressbar
      v-if="unitsFetched"
      style="position:absolute; left: 0; bottom: 0"
      :config="{ value1: unitsFullPricePercent, opacity: '1.0', size: '4px' }"
    ></main-progressbar>
  </div>
</template>

<script>
import MainNameValue from '@/common/components/MainNameValue.vue';
import MainProgressbar from '@/common/components/MainProgressbar.vue';
export default {
  name: 'UnitsCard',
  components: {
    MainNameValue,
    MainProgressbar,
  },
  computed: {
    unitsFetched() {
      return this.$store.getters.unitsStatus === 'fetched';
    },
    units() {
      return this.$store.getters.units;
    },

    unitsTotalString() {
      if (this.unitsFetched) {
        return this.units.totalPretty;
      } else {
        return '';
      }
    },
    unitsFullPriceString() {
      if (this.unitsFetched) {
        return (
          this.units.fullPricePretty +
          '\xa0 · \xa0' +
          this.units.fullPricePercent.toFixed(0) +
          '%'
        );
      } else {
        return '\xa0 · \xa0%';
      }
    },
    unitsCouponsString() {
      if (this.unitsFetched) {
        return (
          this.units.couponsPretty +
          '\xa0 · \xa0' +
          this.units.couponsPercent.toFixed(0) +
          '%'
        );
      } else {
        return '\xa0 · \xa0%';
      }
    },

    unitsFullPricePercent() {
      if (this.unitsFetched) {
        return this.units.fullPricePercent;
      } else {
        return 50;
      }
    },
  },
};
</script>
<style>
.units-card .vue-progress-path .progress {
  stroke: #db9c9c;
  fill: white;
  stroke-width: 1.5px;
}
.units-card .vue-progress-path .background {
  stroke: #fdebeb;
}
</style>
