<template>
  <div class="d-flex">
    <div
      v-for="(block, i) in blocks"
      :key="i"
      :style="{
        width: block.width,
        background: block.background,
        display: 'block',
        height: '4px',
      }"
    ></div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import { uniqueId } from 'lodash';

export default defineComponent({
  name: 'FadeProgressBar',
  props: ['value'],
  setup() {
    return {
      uniqueId: uniqueId(),
    };
  },
  computed: {
    blocks() {
      const blocks = [];

      let currentValue = 0;

      this.value.forEach((block, index) => {
        const value = block.value;

        currentValue = currentValue + value;

        const isLast = this.value.length - 1 === index;

        const nextEl = this.value[index + 1];
        let nextColor = nextEl ? nextEl.color : 'transparent';

        if (isLast && Math.ceil(currentValue) >= 100) {
          nextColor = block.color;
        }

        if (!(value > 0)) {
          return;
        }

        if (currentValue >= 100) {
          blocks.push({
            background: block.color,
            width: value + '%',
          });
        } else {
          blocks.push({
            background: block.color,
            width: value - 3 + '%',
          });

          blocks.push({
            background: `linear-gradient(90deg, ${block.color} 0%, ${nextColor} 100%)`,
            width: '6%',
          });
        }
      });

      return blocks;
    },
  },
});
</script>
