<template>
  <div class="product-details" v-if="!hide">
    <v-img
      :width="30"
      :height="30"
      aspect-ratio="1/1"
      cover
      :src="imageUrl"
      class="rounded flex-grow-0 product-details__image elevation-1"
    ></v-img>
    <div class="product-details__title text-truncate" :title="title">
      {{ title }}
    </div>
    <div
      class="product-details__subtitle text-truncate text-uppercase font-weight-light"
      :title="subTitle"
    >
      {{ subTitle }}
    </div>
  </div>
  <div v-else class="text-right w-100 pr-2">
    {{ placeholder }}
  </div>
</template>
<script>
import { VImg } from 'vuetify/lib/components';

export default {
  data() {
    return {
      src: null,
      imageUrl: null,
      title: null,
      subTitle: null,
      hide: false,
      placeholder: null,
    };
  },
  beforeMount() {
    const { imageUrl, title, subTitle, placeholder } = this.params.rendererData;

    if (!imageUrl && !title) {
      this.hide = true;
      this.placeholder = placeholder || '';
    }

    this.imageUrl = imageUrl;
    this.title = title;
    this.subTitle = subTitle;
  },
  components: {
    VImg,
  },
};
</script>
<style lang="scss" scoped>
.product-details {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'img title'
    'img subtitle';
  gap: 0 1rem;
  width: 100%;
  align-items: center;

  &__image {
    grid-area: img;
  }

  &__title {
    grid-area: title;
    line-height: 1.5;
  }

  &__subtitle {
    grid-area: subtitle;
    line-height: 1.5;
    color: #8073e5;
    font-family: 'Rajdhani-Medium', sans-serif;
  }
}
</style>
