<template>
  <div
    class="small-card sales-card section-shadow main-card flex-column justify-space-between"
  >
    <div class="flex-row justify-space-between">
      <main-name-value
        :content="{
          name: 'Sales',
          value: salesTotalString,
        }"
        :config="{
          'align-items': 'flex-start',
          name: {
            weight: 'Medium',
            'text-transform': 'uppercase',
            'font-size': '16px',
            color: 'var(--main-gray-color)',
          },
          value: {
            weight: 'Bold',
            'font-size': '24px',
            color: 'var(--secondary-gray-color)',
          },
        }"
      ></main-name-value>

      <loading-progress
        v-if="!salesFetched"
        :indeterminate="true"
        size="40"
        rotate
        fillDuration="2"
        rotationDuration="1"
        height="42"
        width="42"
      />
      <img
        v-else
        src="@/assets/svg/icons/icon-sales.svg"
        width="44px"
        height="44px"
      />
    </div>

    <div class="flex-row justify-space-between">
      <main-name-value
        :content="{
          name: 'organic',
          value: salesOrganicString,
        }"
        :config="{
          'align-items': 'flex-start',
          name: {
            weight: 'Medium',
            'text-transform': 'uppercase',
            'font-size': '13px',
            color: 'var(--main-gray-color)',
          },
          value: {
            weight: 'Bold',
            'font-size': '13px',
            color: 'var(--main-green-color)',
          },
        }"
      ></main-name-value>
      <main-name-value
        :content="{
          name: 'advertising',
          value: salesAdvertisingString,
        }"
        :config="{
          'align-items': 'flex-end',
          name: {
            weight: 'Medium',
            'text-transform': 'uppercase',
            'font-size': '13px',
            color: 'var(--main-gray-color)',
          },
          value: {
            weight: 'Bold',
            'font-size': '13px',
            color: 'var(--fourth-purple-color)',
          },
        }"
      ></main-name-value>
    </div>
    <main-progressbar
      v-if="salesFetched"
      style="position:absolute; left: 0; bottom: 0"
      :config="{ value1: salesOrganicPercent, opacity: '1.0', size: '4px' }"
    ></main-progressbar>
  </div>
</template>

<script>
import MainNameValue from '@/common/components/MainNameValue.vue';
import MainProgressbar from '@/common/components/MainProgressbar.vue';
export default {
  name: 'SalesCard',
  components: {
    MainNameValue,
    MainProgressbar,
  },
  computed: {
    salesFetched() {
      return this.$store.getters.salesStatus === 'fetched';
    },
    sales() {
      return this.$store.getters.sales;
    },

    salesTotalString() {
      if (this.salesFetched) {
        return '$' + this.sales.totalPretty;
      } else {
        return '$';
      }
    },
    salesOrganicString() {
      if (this.salesFetched) {
        return (
          '$' +
          this.sales.organicPretty +
          '\xa0 · \xa0' +
          this.sales.organicPercent.toFixed(0) +
          '%'
        );
      } else {
        return '$\xa0 · \xa0%';
      }
    },
    salesOrganicPercent() {
      if (this.salesFetched) {
        return this.sales.organicPercent;
      } else {
        return 50;
      }
    },

    salesAdvertisingString() {
      if (this.salesFetched) {
        return (
          '$' +
          this.sales.adsPretty +
          '\xa0 · \xa0' +
          this.sales.adsPercent.toFixed(0) +
          '%'
        );
      } else {
        return '$\xa0 · \xa0%';
      }
    },
  },
};
</script>

<style>
.sales-card .vue-progress-path .progress {
  stroke: var(--secondary-purple-color);
  fill: white;
  stroke-width: 1.5px;
}
.sales-card .vue-progress-path .background {
  stroke: var(--third-purple-color);
}
</style>
