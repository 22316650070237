<template>
  <div>
    <div class="position-relative product-wrapper">
      <dashboard-product :product="variationTotal"></dashboard-product>
      <div @click="toggle()" class="toggle-container text-center">
        <v-icon color="primary">
          {{ clicked ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </div>
    </div>

    <div class="subproducts pa-4 pt-md-8" v-show="clicked">
      <dashboard-product
        v-for="(subproduct, i) in subproductsFiltered"
        :key="i"
        :product="subproduct"
        :is-subproduct="true"
      ></dashboard-product>

      <v-btn
        v-if="subproducts.length > subproductsDisplayed"
        @click="showMore()"
        color="white"
        class="primary--text mt-4 d-block mx-auto"
      >
        Show more
      </v-btn>
    </div>
  </div>
</template>

<script>
import DashboardProduct from '@/modules/DashboardLayout/ProfitDashboard/DashboardProducts/DashboardProduct/DashboardProduct.vue';

export default {
  name: 'DashboardProductVariation',
  components: {
    DashboardProduct,
  },
  props: ['productVariation'],
  data() {
    return {
      clicked: false,
      subproductsDisplayed: 7,
      subproductsDisplayedStep: 7,
    };
  },
  methods: {
    toggle() {
      this.clicked = !this.clicked;
      this.subproductsDisplayed = this.subproductsDisplayedStep;
    },
    showMore() {
      this.subproductsDisplayed += this.subproductsDisplayedStep;
    },
  },
  computed: {
    variationTotal() {
      return this.productVariation.variationTotal;
    },
    subproducts() {
      return this.productVariation.subproducts.list;
    },
    subproductsFiltered() {
      return this.subproducts.slice(0, this.subproductsDisplayed);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.product-wrapper ::v-deep .product {
  z-index: 3;
  position: relative;
}

.toggle-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f8f7fc;
  box-shadow: 0 4px 2px -2px rgba(143, 142, 145, 0.25);
  position: relative;
  z-index: 2;
  height: 48px;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    width: 92px;
    margin: 0 auto;
    height: 22px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.subproducts {
  background-color: #e8eaf0;
  padding-bottom: 3em;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  z-index: 1;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-left: 77px !important;
  }
}
.subproducts ::v-deep .product {
  position: relative;
}
.subproducts ::v-deep .product-table {
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 2px;
    background: #c5c9d6;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -30px;
    bottom: 50%;
    width: 2px;
    height: 200%;
    background: #c5c9d6;
  }
}
</style>
