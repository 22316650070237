export const floatFormatter = value => {
  if (!value) {
    return 0;
  }
  return Math.round(value * 100) / 100;
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  notation: 'compact',
  compactDisplay: 'short',
});

export function floatValueFormatter(value) {
  if (!value) {
    return '0';
  }

  if (value < 10000 && value > -10000) {
    return floatFormatter(value);
  }

  return formatter.format(floatFormatter(value));
}
