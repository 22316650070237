<template>
  <div class="position-relative app-filters">
    <v-tooltip
      attach=".app-filters"
      color="white"
      content-class="app-info"
      absolute
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="app-info-icon" icon v-bind="attrs" v-on="on">
          <v-icon>
            mdi-information-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Click on the legend to show/hide the data</span>
    </v-tooltip>
    <sales-and-profit-chart-products></sales-and-profit-chart-products>
  </div>
</template>
<script>
import SalesAndProfitChartProducts from './SalesAndProfitChartProducts.vue';

export default {
  components: { SalesAndProfitChartProducts },
};
</script>
<style lang="scss" scoped>
.app-info {
  left: 0 !important;
  top: 0 !important;
  transform: translateY(calc(-100% - 8px));
}
.app-info-icon {
  left: 0;
  position: absolute;
  top: -5px;
  z-index: 2;
}

.app-info-text {
  gap: 5px;
}
</style>
