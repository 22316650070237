<template>
  <div>
    <TrafficAnalyticsHeader />

    <div class="d-flex align-center justify-center message" v-if="isError">
      <Message
        :text="'Unknown error occurred getting traffic analytics data'"
        @retry="loadData"
      ></Message>
    </div>

    <template v-if="!isError">
      <TrafficAnalyticsStatistics />
      <TrafficAnalyticsChart />
      <TrafficAnalyticsProducts />
    </template>
  </div>
</template>
<script>
import TrafficAnalyticsHeader from './TrafficAnalyticsHeader.vue';
import TrafficAnalyticsStatistics from './TrafficAnalyticsStatistics.vue';
import TrafficAnalyticsChart from './TrafficAnalyticsChart.vue';
import TrafficAnalyticsProducts from './TrafficAnalyticsProducts.vue';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import Message from '@/common/components/Message.vue';
import { getCurrentRangeDates } from '@/store/modules/trafficAnalytics';

export default {
  components: {
    Message,
    TrafficAnalyticsProducts,
    TrafficAnalyticsChart,
    TrafficAnalyticsStatistics,
    TrafficAnalyticsHeader,
  },
  async mounted() {
    await this.$store.dispatch('products/loadAll');

    this.loadData();
  },
  computed: {
    ...mapGetters({
      statsIsError: 'trafficAnalytics/isError',
      productsIsError: 'trafficAnalytics/productsIsError',
      chartIsError: 'trafficAnalytics/chartIsError',
      rangeType: 'trafficAnalytics/rangeType',
      _rangeDates: 'trafficAnalytics/rangeDates',
      currentSellerId: 'currentSellerId',
    }),
    rangeValue() {
      return [this.rangeType, this.rangeDates.start, this.rangeDates.end].join(
        '|'
      );
    },
    rangeDates() {
      return getCurrentRangeDates(this.rangeType, this._rangeDates);
    },
    isError() {
      return this.statsIsError || this.productsIsError || this.chartIsError;
    },
  },
  watch: {
    rangeValue(value, prevValue) {
      if (_.isEqual(value, prevValue)) {
        return;
      }
      this.loadData();
    },
    currentSellerId(value) {
      if (!value) {
        return;
      }
      this.$store.dispatch('products/loadAll');
      this.loadData();
    },
  },
  methods: {
    loadData() {
      this.$store.dispatch('trafficAnalytics/get');
      this.$store.dispatch('trafficAnalytics/getProducts');
      this.$store.dispatch('trafficAnalytics/getChart');
    },
  },
};
</script>
<style lang="scss" scoped>
.message {
  height: 70vh;
}
</style>
