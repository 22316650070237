<template>
  <v-card class="app-register-card mx-auto pa-8 pa-md-14">
    <h2 class="app-register-card__title text-center">Sign Up</h2>

    <!-- Sign Up form -->
    <form
      role="form"
      class="mt-8 mt-md-12 mt-lg-16"
      @submit.prevent="signUpSubmit"
    >
      <!-- Email input -->
      <label class="d-block font-weight-semibold mb-1" for="firstName">
        First Name
      </label>
      <v-text-field
        outlined
        dense
        placeholder="ex. John"
        hide-details="auto"
        class="f-18 mb-2 mb-md-5 mb-lg-8"
        v-model="model.firstName"
        id="firstName"
      ></v-text-field>

      <label class="d-block font-weight-semibold mb-1" for="email">
        Email
      </label>
      <v-text-field
        outlined
        dense
        placeholder="Enter Your Email"
        hide-details="auto"
        class="f-18 mb-2 mb-md-5 mb-lg-8"
        v-model="model.username"
        id="email"
      ></v-text-field>

      <label class="d-block font-weight-semibold mb-1" for="password1">
        Password
      </label>
      <v-text-field
        outlined
        dense
        placeholder="Enter Your Password"
        hide-details="auto"
        class="f-18 mb-2 mb-md-5 mb-lg-8"
        v-model="model.password1"
        id="password1"
        :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword1 ? 'text' : 'password'"
        @click:append="showPassword1 = !showPassword1"
      ></v-text-field>

      <label class="d-block font-weight-semibold mb-1" for="password2">
        Confirm Password
      </label>
      <v-text-field
        outlined
        dense
        placeholder="Confirm Your Password"
        hide-details="auto"
        class="f-18 mb-2 mb-md-5 mb-lg-8"
        v-model="model.password2"
        id="password2"
        :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword2 ? 'text' : 'password'"
        @click:append="showPassword2 = !showPassword2"
      ></v-text-field>

      <v-btn color="secondary" class="mt-8 mb-2" block type="submit" rounded
        >Start free trial</v-btn
      >

      <div class="">
        <span class="grey--text font-weight-medium"
          >By signing up, I agree to the</span
        >
        <a
          href="https://myrealprofit.com/terms-and-conditions"
          class="font-weight-semibold"
          target="_blank"
        >
          Terms and Conditions
        </a>
      </div>
    </form>

    <v-divider class="my-8"></v-divider>

    <p class="text-h6 font-weight-semibold text-center">
      Already have an account?
    </p>

    <v-btn class="login-btn w-100" to="/login" depressed outlined rounded
      >Sign In</v-btn
    >
  </v-card>
</template>
<script>
export default {
  name: 'Register',
  metaInfo() {
    return {
      link: [{ rel: 'canonical', href: window.location.href.split('?')[0] }],
    };
  },
  data() {
    return {
      headerIndent: this.$mobile ? '32px' : '64px',
      formIndent: this.$mobile ? '16px' : '32px',
      bottomIndent: this.$mobile ? '32px' : '32px',
      model: {
        nick: 'Empty Name',
        pricingPlan: 'basic',
        username: null,
        password1: null,
        password2: null,
        firstName: null,
        referral: null,
      },
      showPassword1: false,
      showPassword2: false,
    };
  },
  mounted() {
    this.model.username = this.$route.query.email;
    this.model.pricingPlan = this.$route.query.pricingPlan
      ? this.$route.query.pricingPlan
      : this.model.pricingPlan;
    this.model.referral = this.$route.query.referral
      ? this.$route.query.referral
      : this.$store.getters.referral;
    this.$store.commit('SET_REFERRAL', this.model.referral);
  },
  methods: {
    signUpSubmit() {
      this.normalizeInput();
      if (!this.inputPassed()) return;
      const credentials = {
        nick: this.model.nick,
        username: this.model.username.toLowerCase(),
        password: this.model.password1,
        fullName: this.model.firstName,
        pricingPlan: this.model.pricingPlan,
        influencerCoupon: this.model.referral,
      };
      this.$store.dispatch('register_controller', credentials);
    },
    normalizeInput() {
      this.model.username = this.model.username
        ? this.model.username.replace(/\s+/g, '').trim()
        : this.model.username;
      this.model.firstName = this.model.firstName
        ? this.model.firstName.replace(/\s+/g, ' ').trim()
        : this.model.firstName;
    },
    inputPassed() {
      if (
        !(
          this.model.nick &&
          this.model.username &&
          this.model.password1 &&
          this.model.password2 &&
          this.model.firstName &&
          this.model.pricingPlan
        )
      ) {
        this.$store.dispatch('logAlertInfo', {
          msg: 'Fields must not be empty',
        });
        return false;
      }
      if (!(this.model.password1 === this.model.password2)) {
        this.$store.dispatch('logAlertInfo', { msg: "Passwords don't match" });
        return false;
      }
      if (this.model.password1.length < 8) {
        this.$store.dispatch('logAlertInfo', {
          msg: 'Password must be at least 8 characters long',
        });
        return false;
      }
      if (
        this.model.firstName.length > 99 ||
        this.model.username.length > 99 ||
        this.model.password1.length > 99
      ) {
        this.$store.dispatch('logAlertInfo', {
          msg: 'Input must be shorter.',
        });
        return false;
      }
      if (this.model.firstName.split(' ').length !== 1) {
        this.$store.dispatch('logAlertInfo', {
          msg: 'Please provide only your first name.',
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-register-card {
  max-width: 500px;

  &__title {
    font: bold 44px Inter, serif;
    color: var(--gray-900);
  }
}

.register-container {
  width: min(100%, 500px);
  padding: 64px;
}
.auth-line-separator {
  width: 100%;
  border-top: 1px solid #d1d5db;
}

.terms-of-service-text {
  color: #a2a2a8;
  font-family: 'Inter-Regular';
  font-size: 14px;
  line-height: 18px;
}
.terms-of-service-link {
  color: #656ba6;
}

.signup-card-login-link-container {
  text-align: left;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  line-height: 20px;
}
.signup-card-login-link-question {
  color: #4e4f5e;
}
.signup-card-login-link-text {
  color: #656ba6;
  text-decoration-line: underline;
}
.login-btn.v-btn--outlined {
  border: 1px solid #d7d6dc;
  font-weight: 600;
}

@media screen and (max-width: 700px) {
  .register-container {
    padding: 32px 16px;
  }
}
</style>
