<template>
  <v-card
    class="summary-box position-relative pb-2 w-100 overflow-hidden"
    :loading="loading"
  >
    <v-card-text class="pt-4 pb-2 px-7">
      <div class="d-flex">
        <div>
          <div class="summary-box__title">{{ title }}</div>
          <div class="summary-box__value mt-1">{{ formattedValue }}</div>
        </div>
        <div class="ml-auto">
          <slot name="icon"></slot>
        </div>
      </div>
      <div class="d-flex mt-4">
        <div>
          <div class="summary-box__bottom-title">
            {{ groupedTitles }}
          </div>
          <strong class="text-small">
            <span
              v-for="(partial, i) of data.partialValues"
              :class="partial.value < 0 ? 'red--text' : color + '--text'"
              :key="i"
            >
              {{ partial.formattedValue }} {{ i === 0 ? ' · ' : '' }}
            </span>
          </strong>
        </div>
      </div>
    </v-card-text>

    <div class="position-absolute b-0 w-100">
      <FadeProgressBar :value="progressBarData"></FadeProgressBar>
    </div>
  </v-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import FadeProgressBar from '@/common/components/FadeProgressBar.vue';

export default defineComponent({
  name: 'SummaryBoxGrouped',
  components: { FadeProgressBar },
  props: ['data', 'loading'],
  computed: {
    title() {
      return this.data.title;
    },
    formattedValue() {
      return this.data.formattedValue;
    },
    groupedTitles() {
      return this.data.partialValues.map(group => group.title).join(' | ');
    },
    color() {
      return this.data.color;
    },
    progressBarData() {
      return [
        {
          value: this.data.value,
          color: this.$vuetify.theme.currentTheme[this.color],
        },
      ];
    },
  },
});
</script>

<style lang="scss" scoped>
.summary-box {
  height: 100%;
  .v-card__text {
    line-height: 1;
  }

  &__title {
    font-family: Rajdhani, serif;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--gray-500);
  }
  &__value {
    font-size: 1.5rem;
    font-weight: 700;
    color: black;
  }
  &__bottom-title {
    font-family: Rajdhani, serif;
    font-size: 0.85rem;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--gray-500);
  }
}
</style>
