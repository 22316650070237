<template>
  <div class="dashboard-products-wrapper">
    <div class="dashboard-products">
      <div
        v-if="!productsFetched"
        class="dashboard-products-header pa-4 pa-md-8 py-md-6 section-shadow main-card flex-row align-center"
      >
        <h3 class="app-products-title mr-7">Products</h3>
        <loading-progress
          :indeterminate="true"
          size="28"
          rotate
          fillDuration="2"
          rotationDuration="1"
          height="30"
          width="30"
        />
      </div>
      <div v-else>
        <v-card
          class="dashboard-products-header pa-4 pa-md-8 py-md-6 d-flex flex-row justify-space-between align-md-center align-stretch"
        >
          <h3 class="app-products-title mr-md-7">Products</h3>
          <v-text-field
            solo
            outlined
            dense
            label="Search by Title"
            hide-details="auto"
            class="app-products-input"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
          ></v-text-field>

          <v-select
            :items="sortByOptions"
            label="Sort by"
            outlined
            dense
            solo
            :menu-props="{ offsetY: true }"
            height="32"
            append-icon="mdi-chevron-down"
            hide-details
            class="app-products-sort"
            v-model="sortBy"
          >
            <template v-slot:selection="{ item }">
              Sort by: {{ item }}
            </template>
          </v-select>

          <v-select
            :items="groupByOptions"
            label="Group by"
            outlined
            dense
            solo
            :menu-props="{ offsetY: true }"
            height="32"
            append-icon="mdi-chevron-down"
            hide-details
            class="app-products-group"
            v-model="groupBy"
            @change="onGroupByChange($event)"
          >
            <template v-slot:selection="{ item }">
              Group Products: {{ item }}
            </template>
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item
                style="cursor: pointer;"
                class="primary--text"
                @click="createCategoryClicked"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon class="primary--text mr-1"
                      >mdi-tag-plus-outline</v-icon
                    >
                    Create Custom Category
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-card>

        <div class="products-table">
          <div class="products-table-empty" v-if="!getProducts.length">
            <span class="products-table-empty__text"
              >No products found for this criteria</span
            >
          </div>
          <template v-if="groupBySku">
            <dashboard-product
              v-for="(product, i) in productsFiltered.slice(
                0,
                productsDisplayed
              )"
              :key="'product' + i"
              style="margin-top: 2em"
              :product="product"
            ></dashboard-product>

            <v-btn
              v-if="productsFiltered.length > productsDisplayed"
              @click="showMoreProducts()"
              color="white"
              class="primary--text mt-8 mt-md-12 d-block mx-auto"
            >
              Show more
            </v-btn>
          </template>
          <template v-else>
            <dashboard-product-variation
              v-for="(productVariation, i) in productVariationsFiltered.slice(
                0,
                productsDisplayed
              )"
              :key="'productVariation' + i"
              style="margin-top: 3em"
              :productVariation="productVariation"
            ></dashboard-product-variation>

            <v-btn
              v-if="productVariationsFiltered.length > productsDisplayed"
              @click="showMoreProducts()"
              color="white"
              class="primary--text mt-8 mt-md-12 d-block mx-auto"
            >
              Show more
            </v-btn>
          </template>

          <div
            class="product-placeholder"
            v-for="index in Math.max(0, 4 - getProducts.length)"
            :key="index"
          >
            <span class="placeholder-text placeholder-text-short"></span>
            <span class="placeholder-text placeholder-text-medium"></span>
            <span class="placeholder-box placeholder-box-short"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardProduct from '@/modules/DashboardLayout/ProfitDashboard/DashboardProducts/DashboardProduct/DashboardProduct.vue';
import DashboardProductVariation from '@/modules/DashboardLayout/ProfitDashboard/DashboardProducts/DashboardProductVariation/DashboardProductVariation.vue';
import { sortProductsBy, sortVariationsProductsBy } from './sort-helpers';

export default {
  name: 'DashboardProducts',
  components: {
    DashboardProduct,
    DashboardProductVariation,
  },
  data() {
    return {
      search: '',
      productsDisplayed: 10,
      productsDisplayedStep: 10,
      sortByOptions: [
        'Sales',
        'Units',
        'Expenses',
        'Profit',
        'ROI',
        'Ad Spent',
        'Discounts',
        'Refund Rate',
        'TACoS',
      ],
      sortBy: 'Sales',
      productsList: [],
    };
  },
  methods: {
    showMoreProducts() {
      this.productsDisplayed += this.productsDisplayedStep;
    },
    async onGroupByChange(groupBy) {
      if (groupBy === this.groupBy) return;

      const scroll = window.scrollY;
      this.productsDisplayed = this.productsDisplayedStep;
      this.search = '';

      if (groupBy === 'SKU') {
        await this.$store.dispatch('change_loading_state', true);
        await new Promise(r => setTimeout(r, 300));
        await this.$store.dispatch('change_loading_state', false);
      }
      await this.$store.dispatch('change_group_by_controller', groupBy);
      this.$nextTick(() => {
        window.scrollTo(0, scroll);
      });
    },
    createCategoryClicked() {
      this.$router.push('/categories?auto_create=true');
    },
  },
  computed: {
    productsFetched() {
      return this.$store.getters.productsStatus === 'fetched';
    },
    products() {
      return this.$store.getters.products.list;
    },
    productVariations() {
      return this.$store.getters.productVariations.list;
    },
    getProducts() {
      if (this.groupBySku) {
        return this.productsFiltered;
      } else {
        return this.productVariationsFiltered;
      }
    },
    productsFiltered() {
      return this.products
        .filter(product =>
          product.name.toLowerCase().includes(this.search.toLowerCase())
        )
        .sort(sortProductsBy(this.sortBy));
    },
    productVariationsFiltered() {
      return this.productVariations
        .filter(productVariation =>
          productVariation.variationTotal.name
            .toLowerCase()
            .includes(this.search.toLowerCase())
        )
        .sort(sortVariationsProductsBy(this.sortBy));
    },
    groupByOptions() {
      return this.$store.getters.allCategories;
    },
    groupBySku() {
      return this.groupBy === 'SKU';
    },
    groupBy: {
      get() {
        return this.$store.getters.selectedCategory;
      },
      set(category) {
        return category;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.app-products-title {
  font-size: 24px;
  color: var(--secondary-gray-color);
  font-weight: bold;
}
.app-products-sort {
  max-width: 200px;
}
.app-products-group {
  max-width: 250px;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .dashboard-products-header {
    flex-flow: wrap;
    align-items: stretch !important;
  }
  .app-products-title {
    width: 100%;
  }
  .app-products-input {
    width: 100%;
  }
  .app-products-sort {
    flex: 0 0 30%;
    min-width: 130px;
  }
  .app-products-group {
    flex: 0 0 40%;
    min-width: 200px;
  }
}

.dashboard-products-wrapper {
  position: relative;
  width: 100%;
}
.dashboard-products {
  width: 100%;
}
.dashboard-products-header {
  min-height: 100px;
  gap: 10px;
}

.show-more-container {
  width: 100%;
  padding-top: 30px;
}

.products-table-empty__text {
  z-index: 1;
  font: 600 16px 'Source Sans Pro';
  display: block;
  margin: 50px 0;
}
.product-placeholder {
  margin: 25px 0;
  opacity: 0.3;
}
</style>

<style>
.dashboard-products .vue-progress-path .progress {
  stroke: var(--secondary-gray-color);
  fill: white;
  stroke-width: 1.3px;
}
.dashboard-products .vue-progress-path .background {
  stroke: var(--third-gray-color);
}
</style>
