<template>
  <v-menu
    bottom
    left
    :close-on-content-click="false"
    :offset-y="true"
    v-model="open"
    :nudge-bottom="10"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :text="!isActiveCustomRange"
        v-bind:color="isActiveCustomRange ? 'primary' : 'none'"
        elevation="0"
        v-bind="attrs"
        v-on="on"
      >
        Custom range
      </v-btn>
    </template>

    <v-card class="pa-3">
      <div class="flex">
        <v-btn
          v-for="range of ranges"
          :key="range.value"
          :text="activeRange !== range.value"
          v-bind:color="activeRange === range.value ? 'primary' : 'none'"
          elevation="0"
          @click="setRange(range.value)"
        >
          {{ range.text }}
        </v-btn>
        <v-btn
          :text="activeRange !== 'custom'"
          v-bind:color="activeRange === 'custom' ? 'primary' : 'none'"
          elevation="0"
        >
          Custom range
        </v-btn>
      </div>

      <date-picker
        v-model="customDateRange"
        :model-config="modelConfig"
        is-range
        color="purple"
        trim-weeks
        :columns="2"
        :step="1"
        mode="date"
        class="mt-4"
        :first-day-of-week="0"
        :min-date="minDate"
        :max-date="maxDate"
      >
      </date-picker>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: 'DateRangeCustom',
  props: ['ranges', 'activeRange', 'activeDates'],
  data() {
    return {
      timezone: 'America/Los_Angeles',
      modelConfig: {
        start: {
          timeAdjust: '00:00:00',
        },
        end: {
          timeAdjust: '23:59:59',
        },
      },
      open: false,
    };
  },
  computed: {
    customDateRange: {
      get() {
        const { start, end } = this.activeDates || {};

        return {
          start: new Date(Date.parse(start)),
          end: new Date(Date.parse(end)),
        };
      },
      set({ start, end }) {
        const dates = {
          start: start.toISOString(),
          end: end.toISOString(),
        };

        this.setRange('custom', dates);
      },
    },
    minDate() {
      const minDateString = this.$store.getters.currentSellerDetails
        .dataAccessStartFrom;
      return new Date(Date.parse(minDateString));
    },
    maxDate() {
      return Date.now();
    },
    isActiveCustomRange() {
      return [...this.ranges.map(range => range.value), 'custom'].includes(
        this.activeRange
      );
    },
  },
  methods: {
    setRange(range, customDates) {
      this.$emit('onRangeChange', range, customDates);

      this.open = false;
    },
  },
};
</script>
