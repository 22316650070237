<template>
  <span class="content" :style="computedStyles">
    {{ content.text }}
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'SourceSansPro',
  props: ['content', 'config'],
  data() {
    return {
      allowed_weights: ['Light', 'Regular', 'SemiBold', 'Bold', 'Black'],

      // default styles
      'font-family': 'SourceSansPro-Regular',
      'font-size': '1em',
      'line-height': '1em',
      'text-transform': 'none',
      'letter-spacing': '0em',
      'text-align': 'unset',
      color: 'var(--secondary-dark-color)',
    };
  },
  created() {
    this.setConfigData();
  },
  methods: {
    setConfigData() {
      if (this.config) {
        if (
          this.config.weight &&
          this.allowed_weights.includes(this.config.weight)
        ) {
          this['font-family'] = 'SourceSansPro-' + this.config.weight;
        }
        this['font-size'] = this.config['font-size']
          ? this.config['font-size']
          : this['font-size'];
        this['line-height'] = this.config['line-height']
          ? this.config['line-height']
          : this['line-height'];
        this['text-transform'] = this.config['text-transform']
          ? this.config['text-transform']
          : this['text-transform'];
        this['letter-spacing'] = this.config['letter-spacing']
          ? this.config['letter-spacing']
          : this['letter-spacing'];
        this['text-align'] = this.config['text-align']
          ? this.config['text-align']
          : this['text-align'];
        this.color = this.config.color ? this.config.color : this.color;
      }
    },
  },
  computed: {
    computedStyles: function() {
      return [
        {
          'font-family': this['font-family'],
          'font-size': this['font-size'],
          'line-height': this['line-height'],
          'text-transform': this['text-transform'],
          'letter-spacing': this['letter-spacing'],
          'text-align': this['text-align'],
          color: this.color,
        },
      ];
    },
  },
};
</script>

<style scoped>
.content {
  white-space: pre-line;
}
</style>
