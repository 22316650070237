export function referralFeeValueGetter({ salePrice, referralPercent }) {
  return salePrice * referralPercent;
}

export function profitBeforeAdsValueGetter({
  salePrice,
  fbaFee,
  referralFee,
  storageFee,
  refundRate,
  cogs,
}) {
  if (salePrice === 0) {
    return 0;
  }
  return (
    salePrice -
    (fbaFee + referralFee + storageFee + (refundRate / 100) * salePrice + cogs)
  );
}

export function marginValueGetter({ profitBeforeAds, salePrice }) {
  if (profitBeforeAds === 0) {
    return 0;
  }
  return (profitBeforeAds / salePrice) * 100;
}

export function roiBeforeAdsValueGetter({ profitBeforeAds, cogs }) {
  if (profitBeforeAds === 0 || cogs === 0) {
    return 0;
  }
  return (profitBeforeAds / cogs) * 100;
}

export function netProfitValueGetter({ profitBeforeAds, tacos, salePrice }) {
  if (profitBeforeAds === 0) {
    return 0;
  }
  return profitBeforeAds - (tacos / 100) * salePrice;
}

export function netMarginValueGetter({ netProfit, salePrice }) {
  if (salePrice === 0) {
    return 0;
  }
  return (netProfit / salePrice) * 100;
}

export function roiValueGetter({ netProfit, cogs }) {
  if (cogs === 0) {
    return 0;
  }
  return (netProfit / cogs) * 100;
}

export function bRoasValueGetter({ salePrice, profitBeforeAds }) {
  if (profitBeforeAds === 0) {
    return 0;
  }
  return salePrice / profitBeforeAds;
}

export function productDetailsCellValueGetter(params) {
  const { imageUrl, title, parentAsin, asin, sku } = params.data;

  const subTitleArr = [];

  if (sku) {
    subTitleArr.push(`SKU: ${sku}`);
  }

  if (asin) {
    subTitleArr.push(`ASIN: ${asin}`);
  }

  if (parent) {
    subTitleArr.push(`PARENT: ${parentAsin}`);
  }

  return {
    rendererData: {
      imageUrl,
      title,
      subTitle: subTitleArr.join(' / ').toUpperCase(),
    },
  };
}
