<template>
  <div>
    <v-dialog
      max-width="500"
      v-model="enabled"
      @click:outside="close()"
      @keydown.esc="close()"
    >
      <v-card :loading="pending">
        <v-form
          class="px-8 py-7"
          ref="form"
          @submit.prevent="onSubmit"
          lazy-validation
        >
          <div class="d-flex align-center mb-1">
            <h2 class="text-h6 font-weight-semibold">
              Reset password
            </h2>
            <v-spacer></v-spacer>
            <v-btn icon @click="close()" class="app-modal-close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="text-subtitle-1 grey--text">
            <p>
              Please provide the email address that is linked to your account,
              and we will send you a link to reset your password.
            </p>
            <label class="d-block font-weight-semibold mb-1" for="email">
              Email
            </label>
            <v-text-field
              outlined
              dense
              placeholder="Enter Your Email"
              hide-details="auto"
              class="f-18"
              v-model="email"
              id="email"
              required
              :rules="emailRules"
              validate-on-blur
            ></v-text-field>
          </div>
          <div class="d-flex justify-flex-end mt-6">
            <v-btn type="submit" color="secondary" rounded :disabled="pending">
              Send reset link
            </v-btn>
          </div>
          <v-alert v-if="error" dense type="error" class="mt-4 mb-0">
            Unknown error occurred. Please try again later.
          </v-alert>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { AuthApi } from '@/api/auth';

export default {
  name: 'ResetPasswordModal',
  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    modal: true,
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || 'E-mail must be valid',
    ],
    error: null,
    pending: false,
  }),
  methods: {
    close() {
      this.$emit('update:enabled', false);
    },
    async onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.pending = true;
      this.error = null;

      const resetPasswordRequest = AuthApi.resetPassword(this.email);

      resetPasswordRequest.then(() => {
        this.$store.dispatch('logAlertSuccess', {
          msg: `If there's an account associated with the email you provided, we have sent a password reset link. Please check your email.`,
          delay: 10000,
        });
        this.close();
      });

      resetPasswordRequest.catch(() => {
        this.error = true;
      });

      resetPasswordRequest.finally(() => {
        this.pending = false;
      });
    },
  },
};
</script>
