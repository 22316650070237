<template>
  <ejs-chart
    align="left"
    ref="chart"
    :size="size"
    :title="title"
    :primaryXAxis="primaryXAxis"
    :primaryYAxis="primaryYAxis"
    :tooltip="tooltip"
    :chartArea="chartArea"
    :axes="axes"
    :palettes="palettes"
    :legendSettings="legendSettings"
    :crosshair="crosshair"
    @sharedTooltipRender="onTooltipRender"
    @axisLabelRender="onAxisLabelRender"
    @pointRender="pointRender"
    :canResize="true"
  >
    <template v-slot:tooltipTemplate>
      <v-card class="app-tooltip">
        <h3 v-html="tooltipData.headerText" class="app-tooltip-title"></h3>
        <div class="app-tooltip-items">
          <div
            class="app-tooltip-item"
            :key="item.label"
            v-for="item of tooltipData.items"
          >
            <span
              class="app-tooltip-indicator"
              :style="{ backgroundColor: item.color }"
            ></span>

            <span class="app-tooltip-text" v-html="item.label"></span>
          </div>
        </div>
      </v-card>
    </template>
    <e-series-collection>
      <e-series
        :dataSource="seriesData"
        type="Column"
        groupName="group"
        xName="date"
        yName="sales"
        name="Sales"
        :cornerRadius="series['sales'].cornerRadius"
        columnWidth="0.75"
        :border="border"
        :minRadius="5"
        :animation="seriesAnimation"
      >
      </e-series>
      <e-series
        v-if="rangeType !== 'HOURLY'"
        :dataSource="seriesData"
        type="Column"
        groupName="group"
        xName="date"
        yName="profit"
        name="Profit"
        :cornerRadius="series['profit'].cornerRadius"
        columnWidth="0.75"
        :border="border"
        :animation="seriesAnimation"
      >
      </e-series>
      <e-series
        v-if="rangeType !== 'HOURLY'"
        :dataSource="seriesData"
        type="Line"
        xName="date"
        yName="margin"
        name="Profit Margin"
        width="2"
        opacity="0.5"
        yAxisName="margin"
        :binInterval="50"
        :marker="marker"
        :animation="seriesAnimation"
      >
      </e-series>
    </e-series-collection>
  </ejs-chart>
</template>

<script>
import {
  numberToDollars,
  options,
} from '@/modules/DashboardLayout/ProfitDashboard/SalesAndProfitChart/chart-settings';

import {
  Category,
  ColumnSeries,
  Crosshair,
  DateTime,
  Legend,
  LineSeries,
  SplineSeries,
  StackingColumnSeries,
  Tooltip,
  Zoom,
} from '@syncfusion/ej2-vue-charts';
import { shade } from '@/utils/colors';

export default {
  provide: {
    chart: [
      SplineSeries,
      Legend,
      Tooltip,
      DateTime,
      StackingColumnSeries,
      Category,
      Crosshair,
      Zoom,
      ColumnSeries,
      LineSeries,
    ],
  },
  data() {
    return {
      ...options.defaultSettings,
      seriesData: [],
    };
  },
  methods: {
    onTooltipRender($event) {
      const { series, data } = $event;

      const date = data[0].pointX;

      const title = options.ranges[this.rangeType].tooltipTitleFormatter(date);

      // don't rerender tooltip with the same data
      if (title === this.tooltipData?.headerText) {
        return;
      }

      const items = data
        .filter(({ seriesIndex }) =>
          series.some(({ index }) => seriesIndex === index)
        )
        .map(item => {
          const { pointY, seriesName, seriesIndex } = item;

          const value = options.series[seriesName].format(pointY);

          const findSeries = series.find(({ index }) => seriesIndex === index);

          return {
            label: `${seriesName}: <b>${value}</b>`,
            color: findSeries.interior,
          };
        });

      this.tooltipData = {
        headerText: title,
        items,
      };
    },
    onAxisLabelRender(args) {
      const {
        axis: {
          properties: { name },
        },
      } = args;

      if (name === 'primaryYAxis') {
        args.text = numberToDollars(args.text);
      }

      if (name === 'margin') {
        args.text = `${Number(args.text)}%`;
      }
    },
    pointRender(args) {
      if (args.point.yValue < 0) {
        args.fill = shade(args.fill, 50);
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters['salesAndProfit/data'];
    },
    status() {
      return this.$store.getters['salesAndProfit/status'];
    },
    rangeType() {
      return this.$store.getters['salesAndProfit/rangeType'];
    },
  },
  watch: {
    status(status) {
      if (status === 'success') {
        this.seriesData = [...this.data];

        const rangeMargin = this.data.reduce(
          (prev, current) => {
            const { min, max } = prev;

            return {
              min: Math.min(min, current.margin),
              max: Math.max(max, current.margin),
            };
          },
          {
            min: 0,
            max: 0,
          }
        );

        const marginInterval = Math.abs(rangeMargin.max - rangeMargin.min) / 10;

        let percentageInterval = 100;

        // Fast and ugly way, to improve later
        if (marginInterval < 5) {
          percentageInterval = 5;
        } else if (marginInterval < 10) {
          percentageInterval = 10;
        } else if (marginInterval < 50) {
          percentageInterval = 50;
        } else {
          percentageInterval = 100;
        }

        // TODO: To improve, create more generic way
        this.primaryXAxis = {
          ...this.primaryXAxis,
          ...options.ranges[this.rangeType].primaryXAxis,
        };

        this.primaryYAxis = {
          ...this.primaryYAxis,
        };

        this.axes = [
          {
            ...this.axes[0],
            interval: percentageInterval,
          },
        ];

        this.$refs.chart.ej2Instances.animate(300);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .app-tooltip {
    padding: 10px 15px;
  }

  .app-tooltip-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 7px;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 7px;
  }

  .app-tooltip-items {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 15px;
  }

  .app-tooltip-item {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .app-tooltip-indicator {
    --size: 8px;
    width: var(--size);
    height: var(--size);
    border-radius: 100%;
    display: block;
  }
}
</style>
