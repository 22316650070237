<template>
  <div v-if="!reversed" class="flex-column" :style="computedStyles">
    <rajdhani
      :content="{ text: content.name }"
      :config="{ ...config.name }"
    ></rajdhani>
    <div :style="computedHeight"></div>
    <source-sans-pro
      :content="{ text: content.value }"
      :config="{ ...config.value }"
    ></source-sans-pro>
  </div>
  <div v-else class="flex-column" :style="computedStyles">
    <source-sans-pro
      :content="{ text: content.value }"
      :config="{ ...config.value }"
    ></source-sans-pro>
    <div :style="computedHeight"></div>
    <rajdhani
      :content="{ text: content.name }"
      :config="{ ...config.name }"
    ></rajdhani>
  </div>
</template>

<script>
import Rajdhani from '@/common/components/Texts/Rajdhani.vue';
import SourceSansPro from '@/common/components/Texts/SourceSansPro.vue';

export default {
  components: { Rajdhani, SourceSansPro },
  name: 'MainNameValue',
  props: ['content', 'config'],
  data() {
    return {
      'align-items': 'flex-start',
      'text-align': 'unset',
      // gap is height between name and value text
      gap: '0.2em',
      reversed: false,
    };
  },
  created() {
    this.setConfigData();
  },
  methods: {
    setConfigData() {
      // init empty config data -> props to subcomponents
      if (!this.config) {
        this.config = { name: {}, value: {} };
      } else {
        this['align-items'] = this.config['align-items']
          ? this.config['align-items']
          : this['align-items'];
        this['text-align'] = this.config['text-align']
          ? this.config['text-align']
          : this['text-align'];
        this.reversed = this.config.reversed
          ? this.config.reversed
          : this.reversed;
        this.config.name = this.config.name ? this.config.name : {};
        this.config.value = this.config.value ? this.config.value : {};
        this.gap = this.config.gap ? this.config.gap : this.gap;
      }
    },
  },
  computed: {
    computedStyles: function() {
      return [
        {
          'align-items': this['align-items'],
          'text-align': this['text-align'],
        },
      ];
    },
    computedHeight: function() {
      return [{ height: this.gap }];
    },
  },
};
</script>

<style scoped></style>
