<template>
  <div>
    <h2 class="page-title mb-16">ACCOUNT SETUP</h2>
    <div class="flex-column align-center">
      <h3 v-if="!sellerDataInitialized" class="text__title mb-2">
        We are currently importing your data from Amazon.
      </h3>
      <h3 v-if="sellerDataInitialized" class="text__title mb-2">
        Your account is ready to use
      </h3>
      <h4 v-if="!sellerDataInitialized" class="text__subtitle mb-2">
        We'll notify you via email once your dashboard is ready. This usually
        takes about 48 hours.
      </h4>
      <svg
        class="loader-circle"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        v-if="!sellerDataInitialized"
      >
        <path
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="2.5s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
      </svg>
      <v-btn v-else to="/dashboard" color="primary" class="mt-6 px-6">
        TAKE ME TO THE DASHBOARD
      </v-btn>
    </div>
    <v-divider class="mt-12 mb-12"></v-divider>
    <h3 class="text__subtitle mb-8">FREQUENTLY ASKED QUESTIONS</h3>
    <div class="flex-row justify-space-between">
      <div class="flex-column align-flex-start" style="max-width: 45%;">
        <h3 class="text__title mb-2">
          What does “We are importing your data” mean?
        </h3>
        <h4 class="text__subtitle mb-2">
          My Real Profit needs to import all of your past transactions data in
          order to correctly calculate your business metrics and profitability.
        </h4>
      </div>
      <div class="flex-column align-flex-start" style="max-width: 45%;">
        <h3 class="text__title mb-2">
          How long will it take?
        </h3>
        <h4 class="text__subtitle mb-2">
          The amount of time this process takes generally varies based on the
          size of your company and how quickly Amazon sends us your information.
          If you have a lot of transactions and listings, it can take about 48
          hours.
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'InitialDataLoading',
  data() {
    return {};
  },
  computed: {
    sellerDataInitialized() {
      return this.$store.getters.currentSellerDetails.sellerDataInitialized;
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.loader-circle {
  width: 150px;
  height: 150px;
  path {
    fill: var(--main-purple-color);
  }
}
.text {
  &__title {
    font-size: 1.25rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__subtitle {
    color: var(--gray-500);
    font-size: 1.125rem;
    font-weight: normal;
  }
}
</style>
