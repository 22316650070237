var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"maintenance-container main-card section-shadow flex-column align-stretch"},[_c('inter',{attrs:{"content":{
      text: 'Sorry, My Real Profit is currently down for maintenance.',
    },"config":{
      weight: 'Bold',
      color: 'var(--gray-900)',
      'font-size': _vm.header['font-size'],
      'line-height': _vm.header['line-height'],
      'letter-spacing': '0.01em',
    }}}),_c('vertical-space-divider',{attrs:{"config":{ height: '64px' }}}),_c('v-btn',{staticClass:"align-self-center px-8",attrs:{"href":"https://myrealprofit.com","color":"secondary","rounded":""}},[_vm._v(" Go to homepage ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }