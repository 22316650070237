<template>
  <div class="flex-column align-center">
    <img src="@/assets/svg/logos/trivium-logo.svg" style="width: 30%" />
    <h2 class="app-register-card__custom-title mt-4 mt-md-8">
      Account Audit Sign Up
    </h2>
    <div class="mt-3 mb-6 mb-md-12">
      Need help with signing up?
      <a href="https://triviumco.com/thank-you/" target="_blank">Click here</a>.
    </div>

    <v-card class="app-register-card mx-auto pa-8 pa-md-14">
      <!-- Sign Up form -->
      <form role="form" @submit.prevent="signUpSubmit">
        <!-- Email input -->
        <label class="d-block font-weight-semibold mb-1" for="email">
          Email
        </label>
        <v-text-field
          outlined
          dense
          placeholder="Enter Your Email"
          hide-details="auto"
          class="f-18 mb-2 mb-md-5 mb-lg-8"
          v-model="model.username"
          id="email"
        ></v-text-field>

        <label class="d-block font-weight-semibold mb-1" for="password1">
          Password
        </label>
        <v-text-field
          outlined
          dense
          placeholder="Enter Your Password"
          hide-details="auto"
          class="f-18 mb-2 mb-md-5 mb-lg-8"
          v-model="model.password1"
          id="password1"
          :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword1 ? 'text' : 'password'"
          @click:append="showPassword1 = !showPassword1"
        ></v-text-field>

        <label class="d-block font-weight-semibold mb-1" for="password2">
          Confirm Password
        </label>
        <v-text-field
          outlined
          dense
          placeholder="Confirm Your Password"
          hide-details="auto"
          class="f-18 mb-2 mb-md-5 mb-lg-8"
          v-model="model.password2"
          id="password2"
          :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword2 ? 'text' : 'password'"
          @click:append="showPassword2 = !showPassword2"
        ></v-text-field>

        <v-btn color="secondary" class="mt-8 mb-2" block type="submit" rounded
          >Sign Up</v-btn
        >

        <div class="">
          <span class="grey--text font-weight-medium"
            >By signing up, I agree to the</span
          >
          <a
            href="https://myrealprofit.com/terms-and-conditions"
            class="font-weight-semibold"
            target="_blank"
          >
            Terms and Conditions
          </a>
          <br />
          <span class="grey--text font-weight-medium">and grant</span>
          <a
            href="https://triviumco.com"
            class="font-weight-semibold"
            target="_blank"
          >
            Trivium
          </a>
          <span class="grey--text font-weight-medium"
            >access to my Amazon Account data.</span
          >
        </div>
      </form>

      <v-divider class="my-8"></v-divider>

      <p class="text-h6 font-weight-semibold text-center">
        Already have an account?
      </p>

      <v-btn class="login-btn w-100" to="/login" depressed outlined rounded
        >Sign In</v-btn
      >
    </v-card>
  </div></template
>
<script>
export default {
  name: 'Register',
  data() {
    return {
      headerIndent: this.$mobile ? '32px' : '64px',
      formIndent: this.$mobile ? '16px' : '32px',
      bottomIndent: this.$mobile ? '32px' : '32px',
      model: {
        nick: 'TRIVIUM_AUDIT',
        pricingPlan: 'basic',
        username: null,
        password1: null,
        password2: null,
        firstName: 'Client Trivium Audit',
      },
      showPassword1: false,
      showPassword2: false,
    };
  },
  methods: {
    signUpSubmit() {
      if (!this.inputPassed()) return;
      const credentials = {
        nick: this.model.nick,
        username: this.model.username.toLowerCase(),
        password: this.model.password1,
        fullName: this.model.firstName,
        pricingPlan: this.model.pricingPlan,
      };
      this.$store.dispatch('register_controller', credentials);
    },
    inputPassed() {
      if (
        !(
          this.model.nick &&
          this.model.username &&
          this.model.password1 &&
          this.model.password2
        )
      ) {
        this.$store.dispatch('logAlertError', {
          msg: 'Fields must not be empty',
        });
        return false;
      }
      if (!(this.model.password1 === this.model.password2)) {
        this.$store.dispatch('logAlertError', { msg: "Passwords don't match" });
        return false;
      }
      if (this.model.password1.length < 8) {
        this.$store.dispatch('logAlertError', {
          msg: 'Password must be at least 8 characters long',
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-register-card {
  max-width: 500px;

  &__title {
    font: bold 40px Inter, serif;
    color: var(--gray-900);
  }
  &__custom-title {
    font: 30px Inter, serif;
    color: var(--gray-900);
  }
}

.register-container {
  width: min(100%, 500px);
  padding: 64px;
}
.auth-line-separator {
  width: 100%;
  border-top: 1px solid #d1d5db;
}

.terms-of-service-text {
  color: #a2a2a8;
  font-family: 'Inter-Regular';
  font-size: 14px;
  line-height: 18px;
}
.terms-of-service-link {
  color: #656ba6;
}

.signup-card-login-link-container {
  text-align: left;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  line-height: 20px;
}
.signup-card-login-link-question {
  color: #4e4f5e;
}
.signup-card-login-link-text {
  color: #656ba6;
  text-decoration-line: underline;
}
.login-btn.v-btn--outlined {
  border: 1px solid #d7d6dc;
  font-weight: 600;
}

@media screen and (max-width: 700px) {
  .register-container {
    padding: 32px 16px;
  }
}
</style>
