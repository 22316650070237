<template>
  <div
    class="maintenance-container main-card section-shadow flex-column align-stretch"
  >
    <inter
      :content="{
        text: 'Sorry, My Real Profit is currently down for maintenance.',
      }"
      :config="{
        weight: 'Bold',
        color: 'var(--gray-900)',
        'font-size': header['font-size'],
        'line-height': header['line-height'],
        'letter-spacing': '0.01em',
      }"
    ></inter>

    <vertical-space-divider
      :config="{ height: '64px' }"
    ></vertical-space-divider>

    <v-btn
      href="https://myrealprofit.com"
      color="secondary"
      rounded
      class="align-self-center px-8"
    >
      Go to homepage
    </v-btn>
  </div>
</template>
<script>
import Inter from '@/common/components/Texts/Inter.vue';
import VerticalSpaceDivider from '@/common/components/VerticalSpaceDivider.vue';

export default {
  name: 'Maintenance',
  components: {
    Inter,
    VerticalSpaceDivider,
  },
  data() {
    return {
      header: {
        'font-size': this.$mobile ? '26px' : '34px',
        'line-height': this.$mobile ? '36px' : '40px',
      },
    };
  },
};
</script>

<style scoped>
.maintenance-container {
  width: min(100%, 600px);
  padding: 60px;
}
@media screen and (max-width: 700px) {
  .maintenance-container {
    padding: 32px 16px;
  }
}
</style>
