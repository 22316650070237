<template>
  <div class="d-inline-block">
    <v-img
      :width="30"
      :height="30"
      aspect-ratio="1/1"
      cover
      :src="src"
      class="rounded flex-grow-0"
    ></v-img>
  </div>
</template>
<script>
import { VImg } from 'vuetify/lib/components';

export default {
  data() {
    return {
      src: null,
    };
  },
  beforeMount() {
    this.src = this.params.value;
  },
  components: {
    VImg,
  },
};
</script>
