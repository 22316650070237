<template>
  <div class="setting-container flex-column aling-flex-start">
    <accounts class="mb-16"></accounts>

    <subscriptions v-if="hasSellers"></subscriptions>
  </div>
</template>

<script>
import Accounts from '@/modules/DashboardLayout/Settings/Accounts/Accounts.vue';
import Subscriptions from '@/modules/DashboardLayout/Settings/Subscriptions/Subscriptions.vue';

export default {
  name: 'Settings',
  components: {
    Accounts,
    Subscriptions,
  },
  computed: {
    hasSellers() {
      return this.$store.getters.sellers.length;
    },
  },
};
</script>

<style scoped></style>
