<template>
  <div>
    <!-- Power BI iframe -->
    <iframe
      title="P&L Report"
      :src="currentReportLink"
      width="1080"
      height="2050"
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'PandL',
  data() {
    return {};
  },
  computed: {
    currentReportLink() {
      return 'https://app.powerbi.com/reportEmbed?reportId=78540be3-f7c6-478f-b1f0-d2aaaac15044&autoAuth=true&ctid=6cf08673-3f89-4e64-bafc-e90a9ca2340d';
    },
  },
  created() {},
  methods: {},
};
</script>

<style scoped></style>
