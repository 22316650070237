<template>
  <div
    class="expenses-card section-shadow main-card flex-column justify-space-between px-4 py-5 px-md-7"
  >
    <div class="flex-row justify-space-between">
      <main-name-value
        :content="{
          name: 'Expenses',
          value: expensesTotalString,
        }"
        :config="{
          'align-items': 'flex-start',
          name: {
            weight: 'Medium',
            'text-transform': 'uppercase',
            'font-size': '16px',
            color: 'var(--main-gray-color)',
          },
          value: {
            weight: 'Bold',
            'font-size': '24px',
            color: 'var(--secondary-gray-color)',
          },
        }"
      ></main-name-value>

      <loading-progress
        v-if="!expensesFetched"
        :indeterminate="true"
        size="40"
        rotate
        fillDuration="2"
        rotationDuration="1"
        height="42"
        width="42"
      />
      <img
        v-else
        src="@/assets/svg/icons/icon-expenses.svg"
        width="44px"
        height="44px"
      />
    </div>
    <div v-if="expensesFetched" class="expenses-table">
      <expenses-table
        :content="{
          expensesList: expenses.list,
        }"
        :config="{ 'font-size': '15px' }"
      >
      </expenses-table>
    </div>
  </div>
</template>

<script>
import ExpensesTable from '@/modules/DashboardLayout/ProfitDashboard/common/ExpensesTable/ExpensesTable.vue';
import MainNameValue from '@/common/components/MainNameValue.vue';

export default {
  name: 'ExpensesCard',
  components: {
    ExpensesTable,
    MainNameValue,
  },
  computed: {
    expensesFetched() {
      return this.$store.getters.expensesStatus === 'fetched';
    },
    expenses() {
      return this.$store.getters.expenses;
    },
    expensesTotalString() {
      if (this.expensesFetched) {
        return '$' + this.expenses.totalPretty;
      } else {
        return '$';
      }
    },
  },
};
</script>

<style scoped>
.expenses-card {
  width: 100%;
  height: var(--big-card-height);
  padding: 30px;
  height: 100%;
}
</style>

<style>
.expenses-card .vue-progress-path .progress {
  stroke: var(--main-purple-color);
  fill: white;
  stroke-width: 1.5px;
}
.expenses-card .vue-progress-path .background {
  stroke: var(--third-purple-color);
}
</style>
