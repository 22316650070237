<script>
export default {
  props: ['link', 'solo'],
};
</script>

<template>
  <a
    :href="link"
    target="_blank"
    class="app-guide"
    :class="{
      'app-guide--solo': solo,
    }"
  >
    <v-icon size="18">mdi-play-circle-outline</v-icon>
    <span>Video guide</span>
  </a>
</template>

<style lang="scss" scoped>
.app-guide {
  // d-flex ga-1 align-center
  display: flex;
  gap: 4px;
  align-items: center;
  color: #6b7280 !important; // grey-500
  text-wrap: nowrap;

  &:not(.app-guide--solo) {
    border-left: 1px solid #d1d5db; // grey-300
    padding-left: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &:hover span {
    text-decoration: underline;
  }
}
</style>
