<template>
  <div class="flex-row justify-flex-end">
    <v-dialog v-model="customRangeModal" width="auto">
      <v-card>
        <div class="px-8 pt-4">
          <h2 class="text-h6 font-weight-semibold mb-3">Choose date range</h2>
          <v-date-picker
            v-model="currentRangeDates"
            range
            no-title
            :first-day-of-week="1"
            color="primary"
            @change="customDatesChanged($event)"
          ></v-date-picker>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeCustomRangeModal()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-select
      hide-details
      dense
      outlined
      solo
      :menu-props="{ offsetY: true }"
      :items="mobileRanges"
      v-model="currentRange"
      v-show="$vuetify.breakpoint.smAndDown"
      class="app-range-select"
    >
      <template slot="item" slot-scope="data">
        <div
          class="v-list-item__content"
          @click="clickedRange(data.item.value, $event)"
        >
          <div class="v-list-item__title">
            {{ data.item.text }}
          </div>
        </div>
      </template>
    </v-select>

    <v-btn
      v-for="range of ranges"
      :key="range.type"
      :text="!isActive(range.type)"
      v-bind:color="isActive(range.type) ? 'primary' : 'none'"
      elevation="0"
      class="app-intense-hover"
      @click="changeDateRange(range.type)"
      v-show="$vuetify.breakpoint.mdAndUp"
    >
      {{ range.label }}
    </v-btn>
    <dashboard-custom-range
      v-show="$vuetify.breakpoint.mdAndUp"
      @changeRange="changeDateRange"
    ></dashboard-custom-range>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import DashboardCustomRange from '@/modules/DashboardLayout/ProfitDashboard/DashboardDateranges/DashboardCustomRange';
import { mapRangeTypeToDatesInLAZone } from '@/common/helpers/map-range-type';
import { DateTime } from 'luxon';
import { getCurrentRangeDates } from '@/store/modules/dateranges';

export default {
  name: 'Dateranges',
  components: { DashboardCustomRange },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      customRangeModal: false,
      currentRangeDates: this.getCurrentRangeDates(),
      lastRange: '',
      ranges: [
        {
          label: 'Today',
          type: 'today',
        },
        {
          label: 'Yesterday',
          type: 'yesterday',
        },
        {
          label: 'This month',
          type: 'this_month',
        },
        {
          label: 'Last month',
          type: 'last_month',
        },
      ],
      customRanges: [
        {
          label: 'Last 7 days',
          type: 'last_7_days',
        },
        {
          label: 'Last 30 days',
          type: 'last_30_days',
        },
        {
          label: 'This quarter',
          type: 'this_quarter',
        },
        {
          label: 'Last quarter',
          type: 'last_quarter',
        },
        {
          label: 'Custom range',
          type: 'custom',
        },
      ],
    };
  },
  watch: {
    '$store.getters.currentRangeDates': function() {
      this.currentRangeDates = this.getCurrentRangeDates();
    },
  },
  methods: {
    clickedRange(range, event) {
      this.lastRange = this.currentRange;
      if (range === 'custom') {
        event.stopPropagation();
        this.customRangeModal = true;
        return;
      }
      this.changeDateRange(range);
    },
    changeDateRange(rangeType, customDate = null) {
      this.$store.dispatch('change_current_range', {
        type: rangeType,
        dates: mapRangeTypeToDatesInLAZone(rangeType, customDate),
      });
    },
    isActive(rangeType) {
      return rangeType === this.$store.getters.currentRangeType;
    },
    customDatesChanged(range) {
      const [firstDate, secondDate] = range;
      const invertedSelection =
        DateTime.fromISO(firstDate) > DateTime.fromISO(secondDate);
      const ranges = invertedSelection
        ? [secondDate, firstDate]
        : [firstDate, secondDate];

      const start = DateTime.fromISO(ranges[0], {
        zone: 'America/Los_Angeles',
      }).startOf('day');
      const end = DateTime.fromISO(ranges[1], {
        zone: 'America/Los_Angeles',
      }).endOf('day');

      const dateRange = {
        start,
        end,
      };

      this.changeDateRange('custom', dateRange);
      this.customRangeModal = false;
    },
    getCurrentRangeDates() {
      const { currentRangeType, currentRangeDates } = this.$store.getters;
      const { start, end } = getCurrentRangeDates(
        currentRangeType,
        currentRangeDates
      );

      const laDate = DateTime.local().setZone('America/Los_Angeles');

      const startUTC = DateTime.fromISO(start, { zone: 'UTC' })
        .plus({ minutes: laDate.offset })
        .toISODate();
      const endUTC = DateTime.fromISO(end, { zone: 'UTC' })
        .plus({ minutes: laDate.offset })
        .toISODate();

      return [startUTC, endUTC];
    },
    closeCustomRangeModal() {
      this.customRangeModal = false;
    },
  },
  computed: {
    mobileRanges() {
      return [...this.ranges, ...this.customRanges]
        .filter(el => el.type != 'this_quarter' && el.type != 'last_quarter')
        .map(range => ({
          text: range.label,
          value: range.type,
        }));
    },
    currentRange: {
      get() {
        return this.$store.getters.currentRangeType;
      },
      set(currentRangeType) {
        return currentRangeType;
      },
    },
  },
};
</script>
<style>
.app-range-select {
  max-width: 160px;
}
</style>
