<template>
  <div
    class="card-container main-card section-shadow flex-column align-flex-start"
    :style="computedStyles"
  >
    <slot></slot>
    <inter
      :content="{ text: header.text }"
      :config="{
        weight: 'Medium',
        color: 'var(--gray-700)',
        'font-size': header['font-size'],
        'line-height': header['line-height'],
      }"
    ></inter>

    <vertical-space-divider
      :config="{ height: '8px' }"
    ></vertical-space-divider>

    <inter
      :content="{
        text: text.text,
      }"
      :config="{
        weight: 'Regular',
        color: 'var(--gray-500)',
        'font-size': text['font-size'],
        'line-height': text['line-height'],
      }"
    ></inter>

    <vertical-space-divider
      v-if="text.text !== ' '"
      :config="{ height: '16px' }"
    ></vertical-space-divider>

    <slot name="content"></slot>

    <div class="card-button-container flex-row justify-flex-end">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
import Inter from '@/common/components/Texts/Inter.vue';
import VerticalSpaceDivider from '@/common/components/VerticalSpaceDivider.vue';

export default {
  name: 'Card',
  components: {
    Inter,
    VerticalSpaceDivider,
  },
  props: ['content', 'config'],
  data() {
    return {
      // DEFAULT STYLES
      header: {
        text: 'Default header',
        'font-size': this.$mobile ? '18px' : '18px',
        'line-height': this.$mobile ? '26px' : '28px',
      },
      text: {
        text: 'Default text',
        'font-size': this.$mobile ? '16px' : '16px',
        'line-height': this.$mobile ? '24px' : '24px',
      },
      button: {
        text: 'Close',
        width: this.$mobile ? '100%' : null,
        padding: this.$mobile ? '12px 32px' : null,
      },
      width: '200px',
    };
  },
  created() {
    this.setContentData();
    this.setConfigData();
  },
  methods: {
    setConfigData() {
      if (this.config) {
        this.width = this.config.width ? this.config.width : this.width;
      }
    },
    setContentData() {
      if (this.content) {
        if (this.content.header) {
          this.header.text = this.content.header.text
            ? this.content.header.text
            : this.header.text;
        }
        if (this.content.text) {
          this.text.text = this.content.text.text
            ? this.content.text.text
            : this.text.text;
        }
        if (this.content.button) {
          this.button.text = this.content.button.text
            ? this.content.button.text
            : this.button.text;
        }
      }
    },
  },
  computed: {
    computedStyles: function() {
      return [{ width: this.width }];
    },
  },
};
</script>

<style scoped>
.card-container {
  position: absolute;
  padding: 16px;
}
.card-button-container {
  width: 100%;
}
</style>
