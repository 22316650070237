var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product"},[_c('h5',{staticClass:"product__label"},[_vm._v(_vm._s(_vm.sku))]),_c('h4',{staticClass:"product__title mb-2"},[_vm._v(_vm._s(_vm.name))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndDown),expression:"$vuetify.breakpoint.smAndDown"}]},[_c('v-card',{staticClass:"d-flex"},[_c('img',{staticClass:"product__image",attrs:{"src":_vm.image,"alt":_vm.name}}),_c('div',{staticClass:"flex-grow-1 pa-4"},[_c('div',{staticClass:"d-flex justify-space-between mb-2 product-prop"},[_c('span',{staticClass:"product-prop__label"},[_vm._v("SALES")]),_c('strong',[_vm._v(_vm._s(_vm.salesTotalString))])]),_c('div',{staticClass:"d-flex justify-space-between mb-2 product-prop"},[_c('span',{staticClass:"product-prop__label"},[_vm._v("UNITS")]),_c('strong',[_vm._v(_vm._s(_vm.unitsTotalString))])]),_c('div',{staticClass:"d-flex justify-space-between product-prop"},[_c('span',{staticClass:"product-prop__label"},[_vm._v("PROFIT")]),_c('strong',[_vm._v(_vm._s(_vm.profitString))])]),_c('v-divider',{staticClass:"my-2"}),_c('div',{staticClass:"d-flex justify-space-between product-prop"},[_c('span',{staticClass:"product-prop__label"},[_vm._v("MARGIN "+_vm._s('\xa0\xa0|\xa0\xa0')+" ROI")]),_c('strong',{class:{
              positive: _vm.marginPercent > 0,
              negative: _vm.marginPercent <= 0,
            }},[_vm._v(_vm._s(_vm.marginRoiString))])])],1)])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"section-shadow main-card product-table"},[_c('table',[_c('tr',[_c('td',[_c('img',{staticClass:"product-image-cell",attrs:{"src":_vm.image}})]),_c('td',[_c('div',{staticClass:"product-table-cell flex-row justify-space-between"},[_c('div',{staticClass:"flex-column justify-flex-start"},[_c('main-name-value',{attrs:{"content":{
                  name: 'sales',
                  value: _vm.salesTotalString,
                },"config":{
                  name: {
                    'text-transform': 'uppercase',
                    weight: 'Medium',
                    'font-size': '13px',
                    color: 'var(--third-gray-color)',
                  },
                  value: {
                    weight: 'SemiBold',
                    color: 'var(--secondary-gray-color)',
                    'font-size': '20px',
                  },
                }}})],1),_c('div',{staticClass:"flex-row justify-flex-end"},[_c('div',{staticClass:"flex-column justify-space-between"},[_c('main-name-value',{attrs:{"content":{
                    name: 'organic',
                    value: _vm.salesOrganicString,
                  },"config":{
                    gap: '0.1em',
                    'align-items': 'flex-end',
                    name: {
                      'text-transform': 'uppercase',
                      weight: 'Medium',
                      'font-size': '13px',
                      color: 'var(--third-gray-color)',
                    },
                    value: {
                      weight: 'SemiBold',
                      color: 'var(--main-green-color)',
                      'font-size': '13px',
                    },
                  }}}),_c('main-name-value',{attrs:{"content":{
                    name: 'advertising',
                    value: _vm.salesAdsString,
                  },"config":{
                    gap: '0.1em',
                    'align-items': 'flex-end',
                    name: {
                      'text-transform': 'uppercase',
                      weight: 'Medium',
                      'font-size': '13px',
                      color: 'var(--third-gray-color)',
                    },
                    value: {
                      weight: 'SemiBold',
                      color: 'var(--fourth-purple-color)',
                      'font-size': '13px',
                    },
                  }}})],1),_c('div',{staticStyle:{"width":"8px"}}),_c('main-progressbar',{attrs:{"config":{
                  value1: _vm.organicSalesPercent,
                  opacity: 0.8,
                  isVertical: true,
                }}})],1)])]),_c('td',[_c('div',{staticClass:"product-table-cell flex-row justify-space-between"},[_c('div',{staticClass:"flex-column justify-flex-start"},[_c('main-name-value',{attrs:{"content":{
                  name: 'units',
                  value: _vm.unitsTotalString,
                },"config":{
                  name: {
                    'text-transform': 'uppercase',
                    weight: 'Medium',
                    'font-size': '13px',
                    color: 'var(--third-gray-color)',
                  },
                  value: {
                    weight: 'SemiBold',
                    color: 'var(--secondary-gray-color)',
                    'font-size': '20px',
                  },
                }}})],1),_c('div',{staticClass:"flex-row justify-flex-end"},[_c('div',{staticClass:"flex-column justify-space-between"},[_c('main-name-value',{attrs:{"content":{
                    name: 'avg. price',
                    value: _vm.averagePriceString,
                  },"config":{
                    gap: '0.1em',
                    'align-items': 'flex-end',
                    name: {
                      'text-transform': 'uppercase',
                      weight: 'Medium',
                      'font-size': '13px',
                      color: 'var(--third-gray-color)',
                    },
                    value: {
                      weight: 'SemiBold',
                      color: 'var(--main-green-color)',
                      'font-size': '13px',
                    },
                  }}}),_c('main-name-value',{attrs:{"content":{
                    name: 'discounts',
                    value: _vm.unitsCouponString,
                  },"config":{
                    gap: '0.1em',
                    'align-items': 'flex-end',
                    name: {
                      'text-transform': 'uppercase',
                      weight: 'Medium',
                      'font-size': '13px',
                      color: 'var(--third-gray-color)',
                    },
                    value: {
                      weight: 'SemiBold',
                      color: 'var(--fourth-purple-color)',
                      'font-size': '13px',
                    },
                  }}})],1)])])]),_c('td',[_c('div',{staticClass:"product-table-cell flex-row justify-space-between"},[_c('div',{staticClass:"flex-column justify-space-between"},[_c('main-name-value',{attrs:{"content":{
                  name: 'expenses',
                  value: _vm.expensesTotalString,
                },"config":{
                  name: {
                    'text-transform': 'uppercase',
                    weight: 'Medium',
                    'font-size': '13px',
                    color: 'var(--third-gray-color)',
                  },
                  value: {
                    weight: 'SemiBold',
                    color: 'var(--secondary-gray-color)',
                    'font-size': '20px',
                  },
                }}}),_c('v-tooltip',{attrs:{"allow-overflow":"","open-on-hover":false,"open-on-focus":false,"right":"","content-class":"app-expenses-tooltip","min-width":"300","transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('div',_vm._g({staticClass:"expenses-img-container flex-row justify-space-between align-center",class:_vm.show ? 'expenses-img-container--hover' : ''},on),[_c('img',{staticClass:"expenses-img",attrs:{"src":require("@/assets/svg/table-cells.svg")}}),_c('v-icon',{attrs:{"color":"var(--third-gray-color)","x-small":""}},[_vm._v(" "+_vm._s(_vm.show ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")])],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[(_vm.show)?_c('expenses-table',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideTooltip),expression:"hideTooltip"}],attrs:{"content":{
                    expensesList: _vm.expensesList,
                  }}}):_vm._e()],1)],1),_c('div',{staticClass:"flex-column justify-space-between"},[_c('main-name-value',{attrs:{"content":{
                  name: _vm.isSubproduct
                    ? 'Refund rate'
                    : 'refunds \xa0|\xa0 rate',
                  value: _vm.expensesRefundsString,
                },"config":{
                  gap: '0.1em',
                  'align-items': 'flex-end',
                  name: {
                    'text-transform': 'uppercase',
                    weight: 'Medium',
                    'font-size': '13px',
                    color: 'var(--third-gray-color)',
                  },
                  value: {
                    weight: 'SemiBold',
                    color: 'var(--fourth-purple-color)',
                    'font-size': '13px',
                  },
                }}}),_c('main-name-value',{attrs:{"content":{
                  name: _vm.isSubproduct ? 'tacos' : 'marketing \xa0|\xa0 tacos',
                  value: _vm.expensesMarketingString,
                },"config":{
                  gap: '0.1em',
                  'align-items': 'flex-end',
                  name: {
                    'text-transform': 'uppercase',
                    weight: 'Medium',
                    'font-size': '13px',
                    color: 'var(--third-gray-color)',
                  },
                  value: {
                    weight: 'SemiBold',
                    color: 'var(--fourth-purple-color)',
                    'font-size': '13px',
                  },
                }}})],1)])]),_c('td',[_c('div',{staticClass:"product-table-cell flex-row justify-space-between"},[_c('div',{staticClass:"flex-column justify-flex-start"},[_c('main-name-value',{attrs:{"content":{
                  name: 'profit',
                  value: _vm.profitString,
                },"config":{
                  name: {
                    'text-transform': 'uppercase',
                    weight: 'Medium',
                    'font-size': '13px',
                    color: 'var(--third-gray-color)',
                  },
                  value: {
                    weight: 'SemiBold',
                    color: 'var(--secondary-gray-color)',
                    'font-size': '20px',
                  },
                }}})],1),_c('div',{staticClass:"flex-column justify-space-between"},[_c('main-name-value',{attrs:{"content":{
                  name: 'margin',
                  value: _vm.marginString,
                },"config":{
                  gap: '0.1em',
                  'align-items': 'flex-end',
                  name: {
                    'text-transform': 'uppercase',
                    weight: 'Medium',
                    'font-size': '13px',
                    color: 'var(--third-gray-color)',
                  },
                  value: {
                    weight: 'SemiBold',
                    color:
                      _vm.profit >= 0
                        ? 'var(--main-green-color)'
                        : 'var(--main-red-color)',
                    'font-size': '13px',
                  },
                }}}),_c('main-name-value',{attrs:{"content":{
                  name: 'roi',
                  value: _vm.roiString,
                },"config":{
                  gap: '0.1em',
                  'align-items': 'flex-end',
                  name: {
                    'text-transform': 'uppercase',
                    weight: 'Medium',
                    'font-size': '13px',
                    color: 'var(--third-gray-color)',
                  },
                  value: {
                    weight: 'SemiBold',
                    color:
                      _vm.profit >= 0
                        ? 'var(--main-green-color)'
                        : 'var(--main-red-color)',
                    'font-size': '13px',
                  },
                }}})],1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }