var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"expenses-card section-shadow main-card flex-column justify-space-between px-4 py-5 px-md-7"},[_c('div',{staticClass:"flex-row justify-space-between"},[_c('main-name-value',{attrs:{"content":{
        name: 'Expenses',
        value: _vm.expensesTotalString,
      },"config":{
        'align-items': 'flex-start',
        name: {
          weight: 'Medium',
          'text-transform': 'uppercase',
          'font-size': '16px',
          color: 'var(--main-gray-color)',
        },
        value: {
          weight: 'Bold',
          'font-size': '24px',
          color: 'var(--secondary-gray-color)',
        },
      }}}),(!_vm.expensesFetched)?_c('loading-progress',{attrs:{"indeterminate":true,"size":"40","rotate":"","fillDuration":"2","rotationDuration":"1","height":"42","width":"42"}}):_c('img',{attrs:{"src":require("@/assets/svg/icons/icon-expenses.svg"),"width":"44px","height":"44px"}})],1),(_vm.expensesFetched)?_c('div',{staticClass:"expenses-table"},[_c('expenses-table',{attrs:{"content":{
        expensesList: _vm.expenses.list,
      },"config":{ 'font-size': '15px' }}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }