import {
  currencyValueFormatter,
  floatValueFormatter,
  integerValueFormatter,
} from '@/common/ag-grid/value-formatters';

export const buildColumnDefs = ({ groupBy }) => {
  return [
    {
      field: 'title',
      headerName: 'Product',
      cellRenderer: 'ProductDetailsCellRenderer',
      cellRendererParams: params =>
        productDetailsCellValueGetter(params, groupBy),
      minWidth: 250,
      pinned: 'left',
      getQuickFilterText: params => {
        const { parentAsin, title, asin, sku, category } = params.data;
        return Object.values({ parentAsin, title, asin, sku, category }).join();
      },
    },
    {
      field: 'parentAsin',
      headerName: 'PARENT',
      hide: true,
    },
    {
      field: 'sku',
      headerName: 'SKU',
      hide: true,
    },
    {
      field: 'asin',
      headerName: 'ASIN',
      hide: true,
    },
    {
      field: 'category',
      headerName: 'Category',
      hide: true,
    },
    {
      headerName: 'Stock Units',
      headerClass: 'stock-units-header',
      children: [
        {
          field: 'sellable',
          headerName: 'Sellable',
          minWidth: 79,
          valueFormatter: ({ value }) => integerValueFormatter(value),
        },
        {
          field: 'reserved',
          headerName: 'Reserved',
          minWidth: 90,
          valueFormatter: ({ value }) => integerValueFormatter(value),
        },
        {
          field: 'stockPendingInbound',
          headerName: 'Pending Inbound',
          minWidth: 90,
          valueFormatter: ({ value }) => integerValueFormatter(value),
        },
        {
          field: 'totalFbaInventory',
          headerName: 'Total FBA Inventory',
          minWidth: 100,
          valueFormatter: ({ value }) => integerValueFormatter(value),
        },
      ],
    },
    {
      headerName: 'Capital',
      headerClass: 'capital-header',
      children: [
        {
          field: 'inventoryValue',
          headerName: 'Inventory Value',
          textAlign: 'Center',
          minWidth: 110,
          valueFormatter: ({ value }) => currencyValueFormatter(value),
        },
      ],
    },
    {
      headerName: 'Stock Coverage',
      headerClass: 'stock-coverage-header',
      children: [
        {
          field: 'last30DaysUnitsSold',
          headerName: 'units sold last 30 days',
          minWidth: 96,
          valueFormatter: ({ value }) => integerValueFormatter(value),
        },
        {
          field: 'unitsSoldDailyAvg',
          headerName: 'Units sold daily avg',
          minWidth: 95,
          valueFormatter: ({ value }) => floatValueFormatter(value),
        },
        {
          field: 'coverageDays',
          headerName: 'Days coverage',
          minWidth: 85,
          valueFormatter: ({ value }) => floatValueFormatter(value),
        },
      ],
    },
    {
      headerName: '',
      headerClass: 'unsellable-header',
      children: [
        {
          field: 'unsellable',
          headerName: 'Unsellable',
          minWidth: 92,
          valueFormatter: ({ value }) => integerValueFormatter(value),
        },
      ],
    },
  ];
};

function productDetailsCellValueGetter(params, groupBy) {
  const {
    title,
    imageUrl,
    asin,
    sku,
    parentAsin,
    category,
    placeholder,
  } = params.data;

  const isCategory = groupBy !== 'PARENT' && groupBy !== 'SKU';

  let subTitle = '';

  if (isCategory) {
    subTitle = category || '';
  }

  if (groupBy === 'PARENT') {
    subTitle = `PARENT: ${parentAsin}`;
  }

  if (groupBy === 'SKU') {
    subTitle = `SKU: ${sku} / ASIN: ${asin} / PARENT: ${parentAsin}`;
  }

  return {
    rendererData: {
      title,
      imageUrl,
      subTitle: subTitle.toUpperCase(),
      placeholder,
    },
  };
}
