<template>
  <div>
    <div class="flex-row align-center mb-8 gap-3">
      <h2 class="page-title">PRODUCTS AND COGS</h2>

      <GuidelineVideo :link="guidelinesVideos.productsAndCogs" />

      <div class="d-flex flex-row ml-auto">
        <v-btn
          class="cogs-btn"
          style="background-color: var(--white)"
          outlined
          depressed
          :loading="downloadButtonLoading"
          @click="downloadButtonClicked()"
        >
          Export
          <v-icon style="margin-left: 10px;" color="#8073e5" size="20">
            mdi-cloud-download
          </v-icon>
        </v-btn>
        <v-btn
          class="cogs-btn ml-4"
          style="background-color: var(--white)"
          outlined
          depressed
          :loading="uploadButtonLoading"
          @click="uploadButtonClicked()"
        >
          Import
          <v-icon style="margin-left: 10px;" color="#8073e5" size="20">
            mdi-cloud-upload
          </v-icon>
        </v-btn>
      </div>
    </div>

    <v-card class="listings-table">
      <table>
        <thead>
          <tr>
            <th>ITEM</th>
            <th>STATUS</th>
            <th>COGS</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <product
            v-for="listing in listings"
            :key="listing.sku"
            :listing="listing"
          >
          </product>
        </tbody>
        <tfoot>
          <tr></tr>
        </tfoot>
      </table>
    </v-card>
    <ConfirmationModal :enabled="uploadModalShowed" :onClose="hideUploadModal">
      <template v-slot:title>
        Import file
      </template>
      <template v-slot:content>
        This file must follow a specific file format. <br />
        Use <b>'Export'</b> option to get the template file. <br /><br />
        <v-file-input
          v-model="file"
          accept=".csv"
          label="Select file"
          placeholder="No file chosen"
        />
      </template>
      <template v-slot:buttons>
        <v-btn color="white" class="mr-3" @click="hideUploadModal()"
          >Cancel</v-btn
        >
        <v-btn color="secondary" @click="submitFile()">
          Import
        </v-btn>
      </template>
    </ConfirmationModal>
    <ConfirmationModal
      :enabled="downloadModalShowed"
      :onClose="hideDownloadModal"
    >
      <template v-slot:title>
        Export template
      </template>
      <template v-slot:content>
        <br /><b>IMPORTANT!</b><br />
        Adjust the values in the 'START DATE', 'END DATE' and 'PRODUCT COST'
        columns.<br /><br />

        Dates must follow the <b>numeric</b> format.<br />
        If the 'START DATE' field is left <b>blank</b>, the cost will be applied
        to all captured orders.<br />
        If the 'END DATE' field is left <b>blank</b>, the cost will be applied
        starting from the 'START DATE' and set as the
        <b>current cost</b>.<br /><br />

        If any of the 'DAY', 'MONTH', or 'YEAR' fields are empty, the date will
        be considered empty.<br />
        <br />
      </template>
      <template v-slot:buttons>
        <v-btn color="white" class="mr-3" @click="hideDownloadModal()"
          >Cancel</v-btn
        >
        <v-btn color="secondary" @click="exportFile()">
          Export
        </v-btn>
      </template>
    </ConfirmationModal>
  </div>
</template>

<script>
import * as fetcher from '@/api/fetcher';
import * as poster from '@/api/poster';
import Product from '@/modules/DashboardLayout/ProductsAndCogs/Product/Product.vue';
import ConfirmationModal from '@/common/components/ConfirmationModal';
import GuidelineVideo from '@/common/components/GuidelineVideo.vue';
import { guidelinesVideos } from '@/utils/guidelines-videos';

export default {
  components: { GuidelineVideo, Product, ConfirmationModal },
  name: 'Products',
  data() {
    return {
      listings: null,
      downloadButtonLoading: false,
      downloadModalShowed: false,
      uploadButtonLoading: false,
      uploadModalShowed: false,
      file: null,
    };
  },
  created() {
    this.fetchListings();
  },
  computed: {
    guidelinesVideos() {
      return guidelinesVideos;
    },
    currentSellerId() {
      return this.$store.getters.currentSellerId;
    },
  },
  watch: {
    currentSellerId(oldId, newId) {
      if (oldId === newId) return;
      this.fetchListings();
    },
  },
  methods: {
    async fetchListings() {
      await this.$store.dispatch('change_loading_state', true);
      this.listings = [];
      try {
        const data = await fetcher.fetch_products_and_cogs();
        this.listings = this.sortByStatusParentCogs(this.fillEmptyData(data));
      } catch (error) {
        console.log(error);
        this.$store.dispatch('logAlertError', {
          msg: 'There was an error fetching Listings. Please, try again.',
          delay: 10000,
        });
      } finally {
        await this.$store.dispatch('change_loading_state', false);
      }
    },
    sortByStatusParentCogs(data) {
      return data.sort((a, b) => {
        return a.status.localeCompare(b.status) || a.cogsValue - b.cogsValue;
      });
    },
    fillEmptyData(data) {
      data.forEach(el => {
        if (!el.parent) el.parent = '';
        if (!el.cogsValue) el.cogsValue = 0;
      });
      return data;
    },

    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    submitFile() {
      if (!this.file) return;
      this.uploadButtonLoading = true;
      this.hideUploadModal();
      poster
        .post_products_and_cogs_csv(this.file)
        .then(() => {
          this.$store.dispatch('logAlertSuccess', {
            msg: 'Product COGS updated successfully',
            delay: 10000,
          });
          this.fetchListings();
        })
        .catch(() => {
          this.$store.dispatch('logAlertError', {
            msg:
              'There was an error updating your product COGS. Please, try again.',
            delay: 10000,
          });
        })
        .finally(() => {
          this.uploadButtonLoading = false;
          this.file = null;
        });
    },

    uploadButtonClicked() {
      this.showUploadModal();
    },
    downloadButtonClicked() {
      this.showDownloadModal();
    },
    showUploadModal() {
      this.uploadModalShowed = true;
    },
    showDownloadModal() {
      this.downloadModalShowed = true;
    },
    hideUploadModal() {
      this.uploadModalShowed = false;
    },
    hideDownloadModal() {
      this.downloadModalShowed = false;
    },

    async exportFile() {
      this.downloadButtonLoading = true;
      this.hideDownloadModal();
      try {
        await this.downloadFile();
      } catch (error) {
        this.$store.dispatch('logAlertError', {
          msg: 'There was an error importing file for COGS. Please, try again.',
          delay: 10000,
        });
      } finally {
        this.downloadButtonLoading = false;
      }
    },
    async downloadFile() {
      const data = await fetcher.fetch_products_and_cogs_csv();
      const blob = new Blob([data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'mrp_cogs.csv';
      link.click();
    },
  },
};
</script>

<style>
@import '../../../assets/css/listings.css';
</style>
<style scoped>
.cogs-btn.v-btn--outlined {
  border: 1px solid #d7d6dc;
  font-weight: 600;
}
</style>
