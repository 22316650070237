<template>
  <tr>
    <td>
      {{ getName() }}
    </td>
    <td>
      {{ castType[manualExpense.type] }}
    </td>
    <td>${{ pricePretty }}</td>
    <td>
      <!-- START DATE -->
      <div class="date-container flex-row align-center">
        <update-date-modal
          v-if="updateStartDateModalShowed"
          @yes-clicked="yesStartDateModalClicked"
          @close-clicked="closeStartDateModalClicked"
          :content="{
            header:
              manualExpense.type == 'ONE_TIME'
                ? 'Update Date of Expense'
                : 'Update Start Date of Expense',
            text:
              manualExpense.type == 'ONE_TIME'
                ? 'New Date:'
                : 'New Start Date:',
            startDate: true,
          }"
        ></update-date-modal>
        <img
          src="@/assets/png/calendar.png"
          style="cursor: pointer; width: 16px; height: 16px"
          @click="startDateCalendarClicked()"
        />
        <horizontal-space-divider
          :config="{ width: '4px' }"
        ></horizontal-space-divider>
        <div>
          {{ getStartDateString() }}
        </div>
      </div>
    </td>
    <td>
      <!-- END DATE -->
      <div
        v-if="manualExpense.type !== 'ONE_TIME'"
        class="date-container flex-row align-center"
      >
        <update-date-modal
          v-if="updateEndDateModalShowed"
          @yes-clicked="yesEndDateModalClicked"
          @close-clicked="closeEndDateModalClicked"
          :content="{
            header: manualExpense.endDate
              ? 'Update End Date of Expense'
              : 'Set End Date of Expense',
            text: manualExpense.endDate ? 'New End Date:' : 'End Date:',
            startDate: false,
          }"
        ></update-date-modal>
        <img
          src="@/assets/png/calendar.png"
          style="cursor: pointer; width: 16px; height: 16px"
          @click="endDateCalendarClicked()"
        />
        <horizontal-space-divider
          :config="{ width: '4px' }"
        ></horizontal-space-divider>
        <div>
          {{ getEndDateString() }}
        </div>
      </div>
    </td>
    <td>
      <div class="delete-container flex-row justify-center align-center">
        <img
          src="@/assets/svg/cross.svg"
          class="cross-img"
          @click="showDeleteModalClicked"
        />
        <delete-modal
          v-if="deleteModalShowed"
          @yes-clicked="yesDeleteModalClicked"
          @no-clicked="closeDeleteModalClicked"
          @close-clicked="closeDeleteModalClicked"
        ></delete-modal>
      </div>
    </td>
  </tr>
</template>

<script>
import DeleteModal from '@/modules/DashboardLayout/ManualExpenses/modals/DeleteModal.vue';
import UpdateDateModal from '@/modules/DashboardLayout/ManualExpenses/modals/UpdateDateModal.vue';
import HorizontalSpaceDivider from '@/common/components/HorizontalSpaceDivider.vue';

import { getPrettyInteger } from '@/common/helpers/helpers.js';
import { DateTime } from 'luxon';

export default {
  components: { DeleteModal, UpdateDateModal, HorizontalSpaceDivider },
  name: 'ManualExpense',
  props: ['manualExpense'],
  data() {
    return {
      castType: {
        ONE_TIME: 'One-time',
        DAILY: 'Daily',
        WEEKLY: 'Weekly',
        MONTHLY: 'Monthly',
      },
      deleteModalShowed: false,
      updateStartDateModalShowed: false,
      updateEndDateModalShowed: false,
    };
  },
  computed: {
    // temporary functions. Only cast from LA time to UTC
    startDateDisplay() {
      return this.manualExpense.startDate.split('T')[0];
    },
    endDateDisplay() {
      return DateTime.fromISO(this.manualExpense.endDate, { setZone: true })
        .minus({ days: 1 })
        .toUTC()
        .toISO()
        .split('T')[0];
    },
    pricePretty() {
      return getPrettyInteger(this.manualExpense.price);
    },
  },
  methods: {
    getName() {
      return this.manualExpense.description.length > 48
        ? this.manualExpense.description.substring(0, 45) + '...'
        : this.manualExpense.description;
    },
    getStartDateString() {
      return this.startDateDisplay;
    },
    getEndDateString() {
      if (!this.manualExpense.endDate) return 'Present';
      else return this.endDateDisplay;
    },

    /* * * DELETE MANUAL EXPENSE MODAL * * */
    showDeleteModalClicked() {
      this.deleteModalShowed = true;
    },
    yesDeleteModalClicked() {
      this.$emit('delete-clicked', this.manualExpense.id);
      this.deleteModalShowed = false;
    },
    closeDeleteModalClicked() {
      this.deleteModalShowed = false;
    },
    /* * * * * * * * * * * * * * * * * * */

    /* * * START DATE CALENDAR MODAL * * */
    startDateCalendarClicked() {
      this.updateStartDateModalShowed = true;
    },
    yesStartDateModalClicked(date) {
      if (!date) return;
      this.$emit('start-date-update-clicked', {
        id: this.manualExpense.id,
        newStartDate: date,
      });
      this.updateStartDateModalShowed = false;
    },
    closeStartDateModalClicked() {
      this.updateStartDateModalShowed = false;
    },
    /* * * * * * * * * * * * * * * * * * */

    /* * * END DATE CALENDAR MODAL * * * */
    endDateCalendarClicked() {
      this.updateEndDateModalShowed = true;
    },
    yesEndDateModalClicked(date) {
      if (!date) return;
      this.$emit('end-date-update-clicked', {
        id: this.manualExpense.id,
        newEndDate: date,
      });
      this.updateEndDateModalShowed = false;
    },
    closeEndDateModalClicked() {
      this.updateEndDateModalShowed = false;
    },
    /* * * * * * * * * * * * * * * * * * */
  },
};
</script>

<style scoped>
.manual-expenses-table tr {
  height: 50px;
}
.manual-expenses-table tbody td {
  text-align: left;
  font-size: 16px;
  color: var(--gray-700);
}

.manual-expenses-table td:nth-child(1) {
  padding-left: 32px;
}
.manual-expenses-table td:nth-child(3) {
  font-family: 'Inter-Medium';
  letter-spacing: 0.02em;
}
.manual-expenses-table td:nth-child(5) {
  vertical-align: middle;
}

.date-container {
  position: relative;
}
.delete-container {
  position: relative;
}
.cross-img:hover {
  background-color: #fff0f0;
  border-radius: 5px;
  cursor: pointer;
}
</style>
