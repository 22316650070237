<template>
  <v-card :loading="pending" class="mx-auto app-card">
    <v-form class="" ref="form" @submit.prevent="onSubmit" lazy-validation>
      <v-card-title>Create new password</v-card-title>
      <v-card-subtitle>
        Almost done. Please provide your new password.
      </v-card-subtitle>

      <v-card-text>
        <v-alert v-if="error" color="red" class="mb-3 darken-1">
          <div class="white--text">
            The password reset token has expired. You need to regenerate the
            password reset link.
            <router-link
              to="/reset-password"
              class="white--text font-weight-bold ml-1"
            >
              Reset password
            </router-link>
          </div>
        </v-alert>

        <label class="text-h6" for="password">
          New password
        </label>
        <v-text-field
          outlined
          dense
          placeholder="Password"
          hide-details="auto"
          class="f-18 mt-2"
          v-model="password"
          id="password"
          required
          :rules="passwordRules"
          :type="showFirst ? 'text' : 'password'"
          :append-icon="showFirst ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showFirst = !showFirst"
          validate-on-blur
        ></v-text-field>

        <div class="mt-3 mb-4 d-flex flex-column gap-2 text-body-2">
          <span class="font-weight-semibold">Password requirements</span>
          <div class="d-flex align-center gap-2">
            <v-icon small :color="hasLowercase ? 'primary' : 'grey lighten-1'">
              {{ hasLowercase ? 'mdi-check-circle' : 'mdi-close-circle' }}
            </v-icon>
            At least 1 lowercase letter
          </div>
          <div class="d-flex align-center gap-2">
            <v-icon small :color="hasUppercase ? 'primary' : 'grey lighten-1'">
              {{ hasUppercase ? 'mdi-check-circle' : 'mdi-close-circle' }}
            </v-icon>
            At least 1 uppercase letter
          </div>
          <div class="d-flex align-center gap-2">
            <v-icon small :color="hasOneDigit ? 'primary' : 'grey lighten-1'">
              {{ hasOneDigit ? 'mdi-check-circle' : 'mdi-close-circle' }}
            </v-icon>
            At least 1 number
          </div>
          <div class="d-flex align-center gap-2">
            <v-icon small :color="hasMinLength ? 'primary' : 'grey lighten-1'">
              {{ hasMinLength ? 'mdi-check-circle' : 'mdi-close-circle' }}
            </v-icon>
            At least 8 characters
          </div>
        </div>

        <label class="text-h6" for="confirmPassword">
          Confirm password
        </label>
        <v-text-field
          outlined
          dense
          placeholder="Password"
          hide-details="auto"
          class="f-18 mt-2"
          v-model="confirmPassword"
          id="confirmPassword"
          required
          :rules="confirmPasswordRules"
          :type="showSecond ? 'text' : 'password'"
          :append-icon="showSecond ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showSecond = !showSecond"
          validate-on-blur
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn type="submit" color="secondary" rounded :disabled="pending">
          Reset password
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { AuthApi } from '@/api/auth';

export default {
  name: 'ResetPassword',
  data() {
    return {
      pending: false,
      error: false,
      password: '',
      confirmPassword: '',
      showFirst: false,
      showSecond: false,
      passwordRules: [
        v => !!v || 'Field is required',
        v => /\d/.test(v) || 'At least one digit is required',
        v => /[A-Z]/.test(v) || 'At least one uppercase letter is required',
        v => /[a-z]/.test(v) || 'At least one lowercase letter is required',
        v => /^.{8,}$/.test(v) || 'At least 8 characters is required',
      ],
      confirmPasswordRules: [
        v => !!v || 'Field is required',
        v => this.password === v || 'The passwords are different',
      ],
    };
  },

  computed: {
    hasOneDigit() {
      return /\d/.test(this.password);
    },
    hasUppercase() {
      return /[A-Z]/.test(this.password);
    },
    hasLowercase() {
      return /[a-z]/.test(this.password);
    },
    hasMinLength() {
      return /^.{8,}$/.test(this.password);
    },
  },

  methods: {
    async onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.pending = true;
      this.error = null;

      const { token, myRealProfitId } = this.$route.query;

      const setPasswordRequest = AuthApi.createNewPassword({
        password: this.password,
        token,
        myRealProfitId: Number(myRealProfitId),
      });

      setPasswordRequest.then(() => {
        this.$store.dispatch('logAlertSuccess', {
          msg: `Password changed successfully. You can log in with your new password.`,
        });
        this.$router.push('login');
      });

      setPasswordRequest.catch(e => {
        this.error =
          e?.message || 'Unknown error occurred. Please try again later.';
      });

      setPasswordRequest.finally(() => {
        this.pending = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.app-card {
  max-width: 500px;
  overflow: hidden;
}
</style>
