var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subscriptions-container flex-column align-flex-start"},[_c('vertical-space-divider'),_c('rajdhani',{attrs:{"content":{ text: 'subscriptions' },"config":{
      weight: 'Bold',
      'text-transform': 'uppercase',
      'font-size': '44px',
      'line-height': '56px',
      color: '#c3c9de',
    }}}),_c('vertical-space-divider'),_c('div',{staticClass:"subscriptions-card main-card section-shadow flex-column align-flex-start"},[_c('inter',{attrs:{"content":{
        text: 'Thank you for choosing My Real Profit!',
      },"config":{
        weight: 'SemiBold',
        'font-size': '24px',
        color: 'var(--gray-700)',
      }}}),_c('vertical-space-divider',{attrs:{"config":{ height: '32px' }}}),_c('inter',{attrs:{"content":{
        text: 'Please update your payment method.',
      },"config":{
        weight: 'Regular',
        'font-size': '18px',
        'line-height': '24px',
        color: 'var(--gray-500)',
        'text-align': 'left',
      }}}),_c('vertical-space-divider',{attrs:{"config":{ height: '8px' }}}),_c('inter',{attrs:{"content":{
        text:
          'Your monthly <a href=\'https://myrealprofit.com/#pricing\' target=\'blank\'>pricing plan</a> is: ' +
          _vm.getUserPlan(),
      },"config":{
        weight: 'Regular',
        'font-size': '18px',
        'line-height': '24px',
        color: 'var(--gray-500)',
        'text-align': 'left',
      }}}),_c('vertical-space-divider',{attrs:{"config":{ width: '32px' }}}),_c('div',{staticClass:"stripe-card-container"},[_c('stripe-element-card',{ref:"elementRef",attrs:{"pk":_vm.publishableKey},on:{"token":_vm.tokenCreated}})],1),_c('vertical-space-divider',{attrs:{"config":{ width: '32px' }}}),_c('div',{staticClass:"flex-row justify-space-between align-center",staticStyle:{"width":"400px"}},[(!_vm.loading)?_c('v-btn',{staticClass:"px-8",attrs:{"color":"secondary"},on:{"click":_vm.submit}},[_vm._v(" Subscribe ")]):_c('loading-progress',{attrs:{"indeterminate":true,"size":"40","rotate":"","fillDuration":"2","rotationDuration":"1","height":"42","width":"42"}}),_c('img',{attrs:{"src":require("@/assets/svg/logos/powered_by_stripe_blurple.svg"),"alt":"powered by stripe","width":"130px","height":"65px"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }