<template>
  <div class="manual-expenses">
    <div class="d-flex align-center gap-3 mb-3">
      <h2 class="page-title ">MANUAL EXPENSES</h2>

      <GuidelineVideo :link="guidelinesVideos.manualExpenses" />
    </div>

    <v-btn color="secondary" class="mb-9" @click="addNewExpenseClicked">
      <v-icon left>mdi-playlist-plus</v-icon>
      Create Manual Expense
    </v-btn>
    <new-expense-modal
      v-if="newExpenseClicked"
      @close-clicked="closeNewExpense"
      @create-clicked="createNewExpense"
    ></new-expense-modal>

    <v-card class="manual-expenses-table">
      <table>
        <thead>
          <tr>
            <th>DESCRIPTION</th>
            <th>TYPE</th>
            <th>VALUE</th>
            <th>START DATE</th>
            <th>END DATE</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <manual-expense
            v-for="manualExpense in manualExpenses"
            :key="manualExpense.id"
            :manualExpense="manualExpense"
            @delete-clicked="deleteExpense"
            @start-date-update-clicked="updateStartDate"
            @end-date-update-clicked="updateEndDate"
          >
          </manual-expense>
        </tbody>
        <tfoot>
          <tr></tr>
        </tfoot>
      </table>
    </v-card>
  </div>
</template>

<script>
import * as fetcher from '@/api/fetcher';
import * as poster from '@/api/poster';
import ManualExpense from '@/modules/DashboardLayout/ManualExpenses/ManualExpense/ManualExpense.vue';
import NewExpenseModal from '@/modules/DashboardLayout/ManualExpenses/modals/NewExpenseModal.vue';
import GuidelineVideo from '@/common/components/GuidelineVideo.vue';
import { guidelinesVideos } from '@/utils/guidelines-videos';

export default {
  components: {
    GuidelineVideo,
    ManualExpense,
    NewExpenseModal,
  },
  name: 'ManualExpenses',
  data() {
    return {
      manualExpenses: null,
      newExpenseClicked: false,
      manualExpeseSortMap: {
        ONE_TIME: 1,
        MONTHLY: 2,
        WEEKLY: 3,
        DAILY: 4,
      },
    };
  },
  computed: {
    guidelinesVideos() {
      return guidelinesVideos;
    },
    currentSellerId() {
      return this.$store.getters.currentSellerId;
    },
  },
  watch: {
    currentSellerId(oldId, newId) {
      if (oldId === newId) return;
      this.fetchManualExpenses();
    },
  },
  created() {
    this.fetchManualExpenses();
  },
  methods: {
    async fetchManualExpenses() {
      await this.$store.dispatch('change_loading_state', true);
      try {
        const data = await fetcher.fetch_manual_expenses();
        this.manualExpenses = this.sortManualExpenses(data);
      } catch (error) {
        console.log(error);
        this.$store.dispatch('logAlertError', {
          msg:
            'There was an error fetching Manual Expenses. Please, try again.',
          delay: 10000,
        });
      } finally {
        await this.$store.dispatch('change_loading_state', false);
      }
    },
    sortManualExpenses(data) {
      return data.sort((a, b) => {
        if (this.manualExpeseSortMap[a.type] > this.manualExpeseSortMap[b.type])
          return 1;
        else if (
          this.manualExpeseSortMap[a.type] < this.manualExpeseSortMap[b.type]
        )
          return -1;
        else if (a.startDate > b.startDate) return 1;
        else if (a.startDate < b.startDate) return -1;
        else {
          return a.description > b.description ? 1 : -1;
        }
      });
    },
    addNewExpenseClicked() {
      this.newExpenseClicked = true;
    },
    closeNewExpense() {
      this.newExpenseClicked = false;
    },
    createNewExpense({ type, description, price, startDate }) {
      this.$store.dispatch('change_loading_state', true);
      poster
        .add_manual_expense(type, description, price, startDate)
        .then(async () => {
          this.closeNewExpense();
          await this.fetchManualExpenses();
        })
        .catch(() => {
          this.$store.dispatch('logAlertError', {
            msg:
              'There was an error creating Manual Expense. Please, try again.',
            delay: 10000,
          });
        })
        .finally(() => {
          this.$store.dispatch('change_loading_state', false);
        });
    },
    deleteExpense(id) {
      this.$store.dispatch('change_loading_state', true);
      poster
        .delete_manual_expense(id)
        .then(async () => {
          await this.fetchManualExpenses();
        })
        .catch(() => {
          this.$store.dispatch('logAlertError', {
            msg:
              'There was an error deleting Manual Expense. Please, try again.',
            delay: 10000,
          });
        })
        .finally(() => {
          this.$store.dispatch('change_loading_state', false);
        });
    },
    updateStartDate({ id, newStartDate }) {
      this.$store.dispatch('change_loading_state', true);
      poster
        .update_manual_expense_start_date(id, newStartDate)
        .then(async () => {
          await this.fetchManualExpenses();
        })
        .catch(() => {
          this.$store.dispatch('logAlertError', {
            msg:
              'There was an error updating Manual Expense Start Date. Please, try again.',
            delay: 10000,
          });
        })
        .finally(() => {
          this.$store.dispatch('change_loading_state', false);
        });
    },
    updateEndDate({ id, newEndDate }) {
      this.$store.dispatch('change_loading_state', true);
      poster
        .update_manual_expense_end_date(id, newEndDate)
        .then(async () => {
          await this.fetchManualExpenses();
        })
        .catch(() => {
          this.$store.dispatch('logAlertError', {
            msg:
              'There was an error updating Manual Expense End Date. Please, try again.',
            delay: 10000,
          });
        })
        .finally(() => {
          this.$store.dispatch('change_loading_state', false);
        });
    },
  },
};
</script>

<style>
.manual-expenses .vue-progress-path .progress {
  stroke: #4e4f5e;
  fill: white;
  stroke-width: 1.5px;
}
.manual-expenses .vue-progress-path .background {
  stroke: #afb0bd;
}
</style>

<style scoped>
.manual-expenses-table {
  z-index: 1;
  width: 100%;
}
.manual-expenses-table table {
  width: 100%;
  border-collapse: collapse;
}
.manual-expenses-table table thead tr {
  box-shadow: 0px 1px 3px 0px rgba(201, 199, 217, 0.24),
    0px 2px 2px 0px rgba(78, 87, 170, 0.1),
    0px 0px 5px 0px rgba(143, 142, 145, 0.25);
}

.manual-expenses-table tbody tr {
  border-bottom: 1px solid #e8e7f3;
}
.manual-expenses-table tbody tr:hover {
  box-shadow: 0px 1px 3px 0px rgba(201, 199, 217, 0.24),
    0px 2px 2px 0px rgba(78, 87, 170, 0.1),
    0px 0px 5px 0px rgba(143, 142, 145, 0.25);
  background-color: #fcfcfc;
}

.manual-expenses-table table thead th {
  text-align: left;
  font-family: 'Rajdhani-Regular';
  font-size: 14px;
  line-height: 39px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: #afb0bd;
}

.manual-expenses-table th:nth-child(1) {
  padding-left: 32px;
  width: 35%;
}
.manual-expenses-table th:nth-child(2) {
  width: 15%;
}
.manual-expenses-table th:nth-child(3) {
  width: 15%;
}
.manual-expenses-table th:nth-child(4) {
  padding-left: 20px;
  width: 15%;
}
.manual-expenses-table th:nth-child(5) {
  padding-left: 20px;
  width: 15%;
}
.manual-expenses-table th:nth-child(6) {
  width: 5%;
}

.manual-expenses-table tfoot tr {
  height: 70px;
}
</style>
