<template>
  <div>
    <v-row class="mt-4" align-content="stretch">
      <v-col cols="12" md="4">
        <SummaryBox :loading="loading" :data="sessionData">
          <template v-slot:icon>
            <v-avatar color="#8073e530" size="44">
              <v-icon color="#8073e5">mdi-numeric</v-icon>
            </v-avatar>
          </template>
        </SummaryBox>
      </v-col>
      <v-col cols="12" md="4">
        <SummaryBox :loading="loading" :data="salesData">
          <template v-slot:icon>
            <v-avatar color="rgb(219, 156, 156, 0.2)" size="44">
              <v-icon size="20" color="rgb(219, 156, 156)">
                mdi-finance
              </v-icon>
            </v-avatar>
          </template>
        </SummaryBox>
      </v-col>
      <v-col cols="12" md="4">
        <SummaryBoxGrouped :loading="loading" :data="accountConversionRateData">
          <template v-slot:icon>
            <v-avatar color="rgb(235, 247, 246)" size="44">
              <v-icon size="20" color="green">mdi-strategy</v-icon>
            </v-avatar>
          </template>
        </SummaryBoxGrouped>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SummaryBox from '@/common/components/SummaryBox.vue';
import {
  percentageValueFormatter,
  simpleCurrencyValueFormatter,
  simpleIntegerValueFormatter,
} from '@/common/ag-grid/value-formatters';
import SummaryBoxGrouped from '@/common/components/SummaryBoxGrouped.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'TrafficAnalyticsStatistics',
  components: { SummaryBoxGrouped, SummaryBox },
  computed: {
    ...mapGetters({
      sessions: 'trafficAnalytics/sessions',
      unitsSold: 'trafficAnalytics/unitsSold',
      sales: 'trafficAnalytics/sales',
      conversionRate: 'trafficAnalytics/conversionRate',
      isError: 'trafficAnalytics/isError',
    }),
    loading() {
      return this.$store.getters['trafficAnalytics/isLoading'];
    },
    sessionData() {
      return {
        title: 'Sessions',
        value: simpleIntegerValueFormatter(this.sessions.total),
        left: {
          title: 'Organic Traffic',
          value: this.sessions.organic,
          formattedValue: simpleIntegerValueFormatter(this.sessions.organic),
          color: 'green',
        },
        right: {
          title: 'Paid Traffic',
          value: this.sessions.paid,
          formattedValue: simpleIntegerValueFormatter(this.sessions.paid),
          color: 'secondary-purple',
        },
      };
    },
    salesData() {
      return {
        title: 'Sales',
        value: simpleCurrencyValueFormatter(this.sales.total),
        left: {
          title: 'Organic',
          value: this.sales.organic,
          formattedValue: simpleCurrencyValueFormatter(this.sales.organic),
          color: 'green',
        },
        right: {
          title: 'Advertising',
          value: this.sales.ads,
          formattedValue: simpleCurrencyValueFormatter(this.sales.ads),
          color: 'secondary-purple',
        },
      };
    },
    accountConversionRateData() {
      return {
        title: 'Conversion Rate',
        value: this.conversionRate.total * 100,
        formattedValue: percentageValueFormatter(
          this.conversionRate.total * 100
        ),
        color: 'green',
        partialValues: [
          {
            title: 'Organic',
            value: this.conversionRate.organic * 100,
            formattedValue: percentageValueFormatter(
              this.conversionRate.organic * 100
            ),
          },
          {
            title: 'Advertising',
            value: this.conversionRate.ppc * 100,
            formattedValue: percentageValueFormatter(
              this.conversionRate.ppc * 100
            ),
          },
        ],
      };
    },
  },
};
</script>
