<template>
  <div>
    <!-- Power BI iframe -->
    <iframe
      title="Refunds Analytics"
      :src="currentReportLink"
      width="1080"
      height="2300"
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'Refunds',
  data() {
    return {};
  },
  computed: {
    currentReportLink() {
      return 'https://app.powerbi.com/view?r=eyJrIjoiZWUwMTFkNjMtODI0Ni00ZDc2LTg5MmYtMTYzY2JhYjUxYTU5IiwidCI6IjZjZjA4NjczLTNmODktNGU2NC1iYWZjLWU5MGE5Y2EyMzQwZCIsImMiOjN9';
    },
  },
  created() {},
  methods: {},
};
</script>

<style scoped></style>
