<template>
  <div class="h-100">
    <div class="d-flex gap-3 align-center">
      <h2 class="page-title text-uppercase">FBA Inventory</h2>
      <GuidelineVideo :link="guidelinesVideos.fbaInventory" />
    </div>

    <div class="d-flex align-center justify-space-between  mb-5">
      <v-text-field
        solo
        outlined
        dense
        clearable
        label="Search by product name / sku / asin"
        hide-details="auto"
        class="app-search-input"
        prepend-inner-icon="mdi-magnify"
        v-model="phrase"
        v-on:input="phraseChange()"
      ></v-text-field>
      <v-select
        :items="groupByOptions"
        label="Group by"
        outlined
        dense
        solo
        :menu-props="{ offsetY: true }"
        height="32"
        append-icon="mdi-chevron-down"
        hide-details
        class="app-products-group ml-5"
        v-model="groupBy"
        item-text="name"
        :disabled="loading"
        @change="onGroupByChange($event)"
      >
        <template v-slot:selection="{ item }">
          <span class="text-truncate">Group Products: {{ item.name }}</span>
        </template>
        <template v-slot:append-item>
          <v-divider class="mb-2"></v-divider>
          <v-list-item
            style="cursor: pointer;"
            class="primary--text"
            @click="createCategoryClicked"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon class="primary--text mr-1">mdi-tag-plus-outline</v-icon>
                Create Custom Category
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>
      <v-btn
        color="white"
        depressed
        class="elevation-1 ml-5"
        @click="exportToCsv"
      >
        Export to CSV
        <v-icon class="ml-3" color="primary" size="20">
          mdi-cloud-download
        </v-icon>
      </v-btn>
      <p class="text--secondary mb-0 ml-5">Snapshot Date: {{ snapshotDate }}</p>
    </div>

    <v-card class="rounded overflow-hidden" :loading="loading">
      <ag-grid-vue
        class="app-grid ag-theme-mrp ag-theme-material rounded-lg"
        :class="{ 'app-grid-blur': loading }"
        :columnDefs="columnDefs"
        :rowData="rowData"
        :defaultColDef="defaultColDef"
        :rowHeight="rowHeight"
        :suppressDragLeaveHidesColumns="true"
        :suppressHorizontalScroll="true"
        :pinnedBottomRowData="pinnedBottomRowData"
        :getRowStyle="getRowStyle"
        :enableCellTextSelection="true"
        :scrollbarWidth="0"
        @grid-ready="onGridReady"
        @rowDataUpdated="onRowDataUpdated"
      >
      </ag-grid-vue>
    </v-card>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import ProductDetailsCellRenderer from '@/common/ag-grid/renderers/ProductDetailsCellRenderer.vue';
import { InventoryReportApi } from '@/api/inventory-report';
import { buildColumnDefs } from '@/modules/DashboardLayout/InventoryReport/column-defs';

import { DateTime } from 'luxon';
import { guidelinesVideos } from '@/utils/guidelines-videos';
import GuidelineVideo from '@/common/components/GuidelineVideo.vue';

export default {
  name: 'InventoryReport',
  components: {
    GuidelineVideo,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    ProductDetailsCellRenderer,
  },
  data() {
    return {
      phrase: '',
      rowHeight: 75,
      columnDefs: buildColumnDefs({ groupBy: 'SKU' }),
      defaultColDef: {
        wrapHeaderText: true,
        autoHeaderHeight: true,
        suppressMovable: true,
        resizable: true,
        sortable: true,
        minWidth: 20,
        width: 1,
      },
      rowData: [],
      loading: false,
      groupBy: 'SKU',
      snapshotDate: null,
      customXScrollbar: null,
      customYScrollbar: null,
      pinnedBottomRowData: [],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return { 'background-color': '#f8f8f8' };
        }
      },
    };
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData() {
      this.loading = true;
      await InventoryReportApi.get({
        sellerId: this.currentSellerId,
        marketplaceId: this.marketplaceId,
        groupBy: this.groupBy,
      }).then(response => {
        this.columnDefs = buildColumnDefs({ groupBy: this.groupBy });
        this.rowData = response.data.details;
        this.pinnedBottomRowData = [
          { ...response.data.total, placeholder: 'TOTAL' },
        ];
        if (response.data.total.snapshotDate) {
          this.snapshotDate = DateTime.fromISO(
            response.data.total.snapshotDate,
            {
              zone: 'UTC',
            }
          ).toLocaleString(DateTime.DATE_FULL);
        }
        this.loading = false;
      });
    },
    onGridReady({ api }) {
      this.gridApi = api;
    },

    onRowDataUpdated() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },

    phraseChange() {
      this.gridApi.setQuickFilter(this.phrase);
    },

    createCategoryClicked() {
      this.$router.push('/categories?auto_create=true');
    },

    onGroupByChange() {
      this.loadData();
    },

    exportToCsv() {
      const columnsToExport = [
        'sellable',
        'reserved',
        'stockPendingInbound',
        'totalFbaInventory',
        'inventoryValue',
        'last30DaysUnitsSold',
        'unitsSoldDailyAvg',
        'coverageDays',
        'unsellable',
      ];

      if (this.groupBy === 'SKU') {
        columnsToExport.splice(0, 0, ...['title', 'sku', 'asin', 'parentAsin']);
      } else if (this.groupBy === 'PARENT') {
        columnsToExport.splice(0, 0, 'parentAsin');
      } else {
        columnsToExport.splice(0, 0, 'category');
      }

      this.gridApi.exportDataAsCsv({
        skipColumnGroupHeaders: true,
        columnKeys: columnsToExport,
        fileName: 'mrp_fba_inventory.csv',
      });
    },
  },
  computed: {
    guidelinesVideos() {
      return guidelinesVideos;
    },
    currentSellerId() {
      return this.$store.getters.currentSellerId;
    },
    marketplaceId() {
      return this.$store.getters.currentMarketplaceId;
    },
    groupByOptions() {
      return buildGroupList(this.$store.getters.customCategories);
    },
  },
  watch: {
    async currentSellerId(value) {
      if (!value) {
        return;
      }
      await this.loadData();
    },
  },
};

function buildGroupList(customCategories) {
  return [
    {
      name: 'SKU',
      value: 'SKU',
    },
    {
      name: 'Parent ASIN',
      value: 'PARENT',
    },
    ...customCategories.map(category => ({
      name: category,
      value: category,
    })),
  ];
}
</script>

<style scoped lang="scss">
.app-grid {
  width: 100%;
  height: calc(
    100vh - 52px - 52px - 130px
  ); // padding top + padding bottom + header height
  transition: 300ms opacity ease-in-out;
}

.app-grid-blur {
  opacity: 0.2;
}

.app-search-input {
  max-width: 400px;
}

.app-products-group {
  max-width: 250px;
}

::v-deep {
  --ag-grid-size: 5px;

  .ag-floating-bottom {
    overflow: hidden !important;
  }

  .ag-header-cell .ag-header-cell-label {
    padding: 1rem calc(var(--ag-grid-size) * 3);
  }
  .stock-units-header {
    background: #db9c9c !important;
    color: white;
  }
  .capital-header {
    background: #4fa186 !important;
    color: white;
  }
  .stock-coverage-header {
    background: #776ae6 !important;
    color: white;
  }
  .unsellable-header {
    background: #f6b26b !important;
  }
}
</style>
