<template>
  <ProfitCalculator></ProfitCalculator>
</template>
<script>
import ProfitCalculator from './ProfitCalculator.vue';

export default {
  components: {
    ProfitCalculator,
  },
};
</script>
