<template>
  <div class="product">
    <h5 class="product__label">{{ sku }}</h5>
    <h4 class="product__title mb-2">{{ name }}</h4>
    <div v-show="$vuetify.breakpoint.smAndDown">
      <v-card class="d-flex">
        <img :src="image" class="product__image" :alt="name" />
        <div class="flex-grow-1 pa-4">
          <div class="d-flex justify-space-between mb-2 product-prop">
            <span class="product-prop__label">SALES</span>
            <strong>{{ salesTotalString }}</strong>
          </div>

          <div class="d-flex justify-space-between mb-2 product-prop">
            <span class="product-prop__label">UNITS</span>
            <strong>{{ unitsTotalString }}</strong>
          </div>

          <div class="d-flex justify-space-between product-prop">
            <span class="product-prop__label">PROFIT</span>
            <strong>{{ profitString }}</strong>
          </div>

          <v-divider class="my-2"></v-divider>

          <div class="d-flex justify-space-between product-prop">
            <span class="product-prop__label"
              >MARGIN {{ '\xa0\xa0|\xa0\xa0' }} ROI</span
            >
            <strong
              :class="{
                positive: marginPercent > 0,
                negative: marginPercent <= 0,
              }"
              >{{ marginRoiString }}</strong
            >
          </div>
        </div>
      </v-card>
    </div>

    <div
      class="section-shadow main-card product-table"
      v-show="$vuetify.breakpoint.mdAndUp"
    >
      <table>
        <tr>
          <!-- Product Image -->
          <td>
            <img class="product-image-cell" :src="image" />
          </td>

          <!-- Product Sales (Organic / Advertising) -->
          <td>
            <div class="product-table-cell flex-row justify-space-between">
              <div class="flex-column justify-flex-start">
                <main-name-value
                  :content="{
                    name: 'sales',
                    value: salesTotalString,
                  }"
                  :config="{
                    name: {
                      'text-transform': 'uppercase',
                      weight: 'Medium',
                      'font-size': '13px',
                      color: 'var(--third-gray-color)',
                    },
                    value: {
                      weight: 'SemiBold',
                      color: 'var(--secondary-gray-color)',
                      'font-size': '20px',
                    },
                  }"
                >
                </main-name-value>
              </div>
              <div class="flex-row justify-flex-end">
                <div class="flex-column justify-space-between">
                  <main-name-value
                    :content="{
                      name: 'organic',
                      value: salesOrganicString,
                    }"
                    :config="{
                      gap: '0.1em',
                      'align-items': 'flex-end',
                      name: {
                        'text-transform': 'uppercase',
                        weight: 'Medium',
                        'font-size': '13px',
                        color: 'var(--third-gray-color)',
                      },
                      value: {
                        weight: 'SemiBold',
                        color: 'var(--main-green-color)',
                        'font-size': '13px',
                      },
                    }"
                  >
                  </main-name-value>

                  <main-name-value
                    :content="{
                      name: 'advertising',
                      value: salesAdsString,
                    }"
                    :config="{
                      gap: '0.1em',
                      'align-items': 'flex-end',
                      name: {
                        'text-transform': 'uppercase',
                        weight: 'Medium',
                        'font-size': '13px',
                        color: 'var(--third-gray-color)',
                      },
                      value: {
                        weight: 'SemiBold',
                        color: 'var(--fourth-purple-color)',
                        'font-size': '13px',
                      },
                    }"
                  >
                  </main-name-value>
                </div>
                <div style="width: 8px"></div>
                <main-progressbar
                  :config="{
                    value1: organicSalesPercent,
                    opacity: 0.8,
                    isVertical: true,
                  }"
                ></main-progressbar>
              </div>
            </div>
          </td>

          <!-- Product Units -->
          <td>
            <div class="product-table-cell flex-row justify-space-between">
              <div class="flex-column justify-flex-start">
                <main-name-value
                  :content="{
                    name: 'units',
                    value: unitsTotalString,
                  }"
                  :config="{
                    name: {
                      'text-transform': 'uppercase',
                      weight: 'Medium',
                      'font-size': '13px',
                      color: 'var(--third-gray-color)',
                    },
                    value: {
                      weight: 'SemiBold',
                      color: 'var(--secondary-gray-color)',
                      'font-size': '20px',
                    },
                  }"
                >
                </main-name-value>
              </div>
              <div class="flex-row justify-flex-end">
                <div class="flex-column justify-space-between">
                  <main-name-value
                    :content="{
                      name: 'avg. price',
                      value: averagePriceString,
                    }"
                    :config="{
                      gap: '0.1em',
                      'align-items': 'flex-end',
                      name: {
                        'text-transform': 'uppercase',
                        weight: 'Medium',
                        'font-size': '13px',
                        color: 'var(--third-gray-color)',
                      },
                      value: {
                        weight: 'SemiBold',
                        color: 'var(--main-green-color)',
                        'font-size': '13px',
                      },
                    }"
                  >
                  </main-name-value>

                  <main-name-value
                    :content="{
                      name: 'discounts',
                      value: unitsCouponString,
                    }"
                    :config="{
                      gap: '0.1em',
                      'align-items': 'flex-end',
                      name: {
                        'text-transform': 'uppercase',
                        weight: 'Medium',
                        'font-size': '13px',
                        color: 'var(--third-gray-color)',
                      },
                      value: {
                        weight: 'SemiBold',
                        color: 'var(--fourth-purple-color)',
                        'font-size': '13px',
                      },
                    }"
                  >
                  </main-name-value>
                </div>
              </div>
            </div>
          </td>

          <!-- Product Expenses -->
          <td>
            <div class="product-table-cell flex-row justify-space-between">
              <div class="flex-column justify-space-between">
                <main-name-value
                  :content="{
                    name: 'expenses',
                    value: expensesTotalString,
                  }"
                  :config="{
                    name: {
                      'text-transform': 'uppercase',
                      weight: 'Medium',
                      'font-size': '13px',
                      color: 'var(--third-gray-color)',
                    },
                    value: {
                      weight: 'SemiBold',
                      color: 'var(--secondary-gray-color)',
                      'font-size': '20px',
                    },
                  }"
                >
                </main-name-value>
                <v-tooltip
                  allow-overflow
                  :open-on-hover="false"
                  :open-on-focus="false"
                  v-model="show"
                  right
                  content-class="app-expenses-tooltip"
                  min-width="300"
                  transition="none"
                >
                  <template v-slot:activator="{ on }">
                    <div
                      v-on="on"
                      class="expenses-img-container flex-row justify-space-between align-center"
                      :class="show ? 'expenses-img-container--hover' : ''"
                    >
                      <img
                        class="expenses-img"
                        src="@/assets/svg/table-cells.svg"
                      />
                      <v-icon color="var(--third-gray-color)" x-small>
                        {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                      </v-icon>
                    </div>
                  </template>
                  <expenses-table
                    v-if="show"
                    v-click-outside="hideTooltip"
                    :content="{
                      expensesList: expensesList,
                    }"
                  >
                  </expenses-table>
                </v-tooltip>
              </div>
              <div class="flex-column justify-space-between">
                <main-name-value
                  :content="{
                    name: isSubproduct
                      ? 'Refund rate'
                      : 'refunds \xa0|\xa0 rate',
                    value: expensesRefundsString,
                  }"
                  :config="{
                    gap: '0.1em',
                    'align-items': 'flex-end',
                    name: {
                      'text-transform': 'uppercase',
                      weight: 'Medium',
                      'font-size': '13px',
                      color: 'var(--third-gray-color)',
                    },
                    value: {
                      weight: 'SemiBold',
                      color: 'var(--fourth-purple-color)',
                      'font-size': '13px',
                    },
                  }"
                >
                </main-name-value>

                <main-name-value
                  :content="{
                    name: isSubproduct ? 'tacos' : 'marketing \xa0|\xa0 tacos',
                    value: expensesMarketingString,
                  }"
                  :config="{
                    gap: '0.1em',
                    'align-items': 'flex-end',
                    name: {
                      'text-transform': 'uppercase',
                      weight: 'Medium',
                      'font-size': '13px',
                      color: 'var(--third-gray-color)',
                    },
                    value: {
                      weight: 'SemiBold',
                      color: 'var(--fourth-purple-color)',
                      'font-size': '13px',
                    },
                  }"
                >
                </main-name-value>
              </div>
            </div>
          </td>

          <!-- Product Profit / Margin -->
          <td>
            <div class="product-table-cell flex-row justify-space-between">
              <div class="flex-column justify-flex-start">
                <main-name-value
                  :content="{
                    name: 'profit',
                    value: profitString,
                  }"
                  :config="{
                    name: {
                      'text-transform': 'uppercase',
                      weight: 'Medium',
                      'font-size': '13px',
                      color: 'var(--third-gray-color)',
                    },
                    value: {
                      weight: 'SemiBold',
                      color: 'var(--secondary-gray-color)',
                      'font-size': '20px',
                    },
                  }"
                >
                </main-name-value>
              </div>
              <div class="flex-column justify-space-between">
                <main-name-value
                  :content="{
                    name: 'margin',
                    value: marginString,
                  }"
                  :config="{
                    gap: '0.1em',
                    'align-items': 'flex-end',
                    name: {
                      'text-transform': 'uppercase',
                      weight: 'Medium',
                      'font-size': '13px',
                      color: 'var(--third-gray-color)',
                    },
                    value: {
                      weight: 'SemiBold',
                      color:
                        profit >= 0
                          ? 'var(--main-green-color)'
                          : 'var(--main-red-color)',
                      'font-size': '13px',
                    },
                  }"
                >
                </main-name-value>
                <main-name-value
                  :content="{
                    name: 'roi',
                    value: roiString,
                  }"
                  :config="{
                    gap: '0.1em',
                    'align-items': 'flex-end',
                    name: {
                      'text-transform': 'uppercase',
                      weight: 'Medium',
                      'font-size': '13px',
                      color: 'var(--third-gray-color)',
                    },
                    value: {
                      weight: 'SemiBold',
                      color:
                        profit >= 0
                          ? 'var(--main-green-color)'
                          : 'var(--main-red-color)',
                      'font-size': '13px',
                    },
                  }"
                >
                </main-name-value>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import ExpensesTable from '@/modules/DashboardLayout/ProfitDashboard/common/ExpensesTable/ExpensesTable.vue';
import MainNameValue from '@/common/components/MainNameValue.vue';
import MainProgressbar from '@/common/components/MainProgressbar.vue';
import { getPrettyInteger } from '@/common/helpers/helpers.js';

export default {
  name: 'DashboardProduct',
  props: ['product', 'isSubproduct'],
  data() {
    return {
      show: false,
    };
  },
  components: {
    MainNameValue,
    MainProgressbar,
    ExpensesTable,
  },
  methods: {
    getPercent(a, b) {
      return b ? (a / b) * 100 : 0;
    },
    hideTooltip() {
      this.show = false;
    },
  },
  computed: {
    sku() {
      return this.product.sku;
    },
    name() {
      return this.product.name;
    },
    image() {
      return this.product.img;
    },

    /* * * SALES COLUMN * * */
    organicSalesPercent() {
      return this.product.sales.organicPercent;
    },
    adSalesPercent() {
      return this.product.sales.adsPercent;
    },

    salesTotalString() {
      return '$' + this.product.sales.totalPretty;
    },
    salesOrganicString() {
      if (this.isSubproduct) {
        return this.organicSalesPercent.toFixed(0) + '%';
      }
      return (
        '$' +
        this.product.sales.organicPretty +
        '\xa0 · \xa0' +
        this.organicSalesPercent.toFixed(0) +
        '%'
      );
    },
    salesAdsString() {
      if (this.isSubproduct) {
        return this.adSalesPercent.toFixed(0) + '%';
      }
      return (
        '$' +
        this.product.sales.adsPretty +
        '\xa0 · \xa0' +
        this.adSalesPercent.toFixed(0) +
        '%'
      );
    },
    /* * * * * * */

    /* * * UNITS COLUMN * * */
    averagePrice() {
      return this.product.averagePricePretty;
    },

    unitsTotalString() {
      return this.product.units.totalPretty;
    },
    averagePriceString() {
      return '$' + this.averagePrice;
    },
    unitsCouponString() {
      if (this.isSubproduct) {
        return this.product.units.couponsPercent.toFixed(0) + '%';
      }
      return (
        this.product.units.couponsPretty +
        '\xa0 · \xa0' +
        this.product.units.couponsPercent.toFixed(0) +
        '%'
      );
    },
    /* * * * * * * * * * * * * */

    /* * * EXPENSES COLUMN * * */
    expensesTotalString() {
      return '$' + this.product.expenses.totalPretty;
    },

    refundRate() {
      return this.product.refundRate;
    },
    expensesRefundsString() {
      if (this.isSubproduct) {
        return this.refundRate.toFixed(2) + '%';
      }
      return (
        '$' +
        this.product.expenses
          .getExpense('Refunds')
          .subexpenses.getSubexpense('Refunds').totalPretty +
        '\xa0 · \xa0' +
        this.refundRate.toFixed(2) +
        '%'
      );
    },

    marketingExpenseTotal() {
      return (
        this.product.expenses.getExpense('Advertising').total +
        this.product.expenses.getExpense('Discounts').total
      );
    },
    marketingExpenseTotalPretty() {
      return getPrettyInteger(this.marketingExpenseTotal);
    },
    marketingExpensesPercent() {
      return this.getPercent(
        this.marketingExpenseTotal,
        this.product.sales.total
      );
    },
    expensesMarketingString() {
      if (this.isSubproduct) {
        return this.marketingExpensesPercent.toFixed(2) + '%';
      }
      return (
        '$' +
        this.marketingExpenseTotalPretty +
        '\xa0 · \xa0' +
        this.marketingExpensesPercent.toFixed(2) +
        '%'
      );
    },
    /* * * * * * * * * * * * * */

    /* * * PROFIT COLUMN * * */
    profitString() {
      return '$' + this.product.profit.totalPretty;
    },
    profit() {
      return this.product.profit.total;
    },
    marginPercent() {
      return this.product.profit.margin;
    },
    marginString() {
      return this.marginPercent.toFixed(2) + '%';
    },
    roiPercent() {
      return this.product.profit.roi;
    },
    roiString() {
      return this.roiPercent.toFixed(2) + '%';
    },
    marginRoiString() {
      return this.marginString + '\xa0\xa0 · \xa0\xa0' + this.roiString;
    },
    /* * * * * * * * * * * * * */

    expensesList() {
      return this.product.expenses.list;
    },
  },
};
</script>

<style lang="scss" scoped>
.product + .product {
  margin-top: 16px;
}

.expenses-img {
  width: 20px;
  height: 20px;
}
.expenses-img-container {
  width: 42px;
  padding: 4px 4px 4px 5px;
  margin-bottom: -6px;
  margin-left: -2px;
}
.expenses-img-container .expenses-img {
  opacity: 0.5;
}

.expenses-img-container:hover,
.expenses-img-container--hover {
  margin-bottom: -7px;
  margin-left: -3px;
  padding-right: 2px;

  cursor: pointer;
  background: #f8f7ff;
  border: 1px solid #dbdae6;

  box-shadow: 0px 0px 5px rgba(143, 142, 145, 0.25),
    0px 2px 2px rgba(78, 87, 170, 0.1), 0px 1px 3px rgba(201, 199, 217, 0.24);
  border-radius: 55px;

  .expenses-img {
    opacity: 1;
  }
}

.product-table {
  height: 96px;
  > table {
    height: 100%;
  }
}

.product-table td:nth-child(n + 2) {
  padding: 13px;
}

.product-table-cell {
  width: 100%;
  height: 100%;
}
.product-table td:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;

  max-height: 92px;
  max-width: 92px;
  height: 100%;
}
.product-image-cell {
  max-height: 78px;
  max-width: 78px;
  margin: 8px;
  object-fit: contain;
  object-position: center;
}

.product-table table {
  width: 100%;
  height: 100%;
}
.product-table td:nth-child(n + 2):nth-child(-n + 4) {
  width: 25%;
}
.product-table td:nth-child(5) {
  width: 17%;
  background: #fafafc;
}
.product-table table {
  border: none;
  border-collapse: collapse;
}
.product-table table td {
  border-left: 1px solid #e5e7eb;
}
.product-table table td:first-child {
  border-left: none;
}

.product {
  &__label {
    color: var(--gray-500);
    font-size: 0.785rem;
    font-weight: normal;
    text-transform: uppercase;
  }
  &__title {
    font-size: 0.875rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__image {
    width: 120px;
    height: 120px;
    object-fit: contain;
    margin: 16px;
  }
}
.product-prop {
  font-size: 0.875rem;
  &__label {
    font-family: Rajdhani, serif;
    color: var(--gray-400);
  }
}
.positive {
  color: var(--main-green-color);
}
.negative {
  color: var(--main-red-color);
}
.app-expenses-tooltip {
  padding: 0 0.5rem;
}
</style>
