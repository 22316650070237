import { productDetailsCellValueGetter } from './value-getters';
import { createNumericCellEditorParams } from './renderers';
import {
  currencyValueFormatter,
  floatValueFormatter,
  percentageValueFormatter,
} from '@/common/ag-grid/value-formatters';

export const defaultColDef = {
  resizable: true,
  enableCellChangeFlash: true,
  minWidth: 100,
  sortable: true,
  wrapHeaderText: true,
  autoHeaderHeight: true,
  suppressMovable: true,
};

export function createColumnDefs({ rowDataInitial }) {
  return [
    {
      field: 'title',
      headerName: 'Product',
      cellRenderer: 'ProductDetailsCellRenderer',
      cellRendererParams: params => productDetailsCellValueGetter(params),
      minWidth: 250,
      pinned: 'left',
      getQuickFilterText: params => {
        const { parentAsin, title, asin, sku } = params.data;
        return Object.values({ parentAsin, title, asin, sku }).join();
      },
    },
    {
      field: 'sku',
      headerName: 'SKU',
      hide: true,
    },
    {
      field: 'asin',
      headerName: 'ASIN',
      hide: true,
    },
    {
      field: 'salePrice',
      headerName: 'Sale price',
      cellRenderer: 'EditableRenderer',
      editable: true,
      cellEditor: 'NumericCellEditor',
      cellEditorParams: createNumericCellEditorParams({ rowDataInitial }),
      valueFormatter: ({ value }) => currencyValueFormatter(value),
      minWidth: 120,
      pinned: 'left',
    },
    {
      headerName: 'Unit Economics',
      headerClass: 'unit-economics-header',
      cellStyle: { color: 'red', 'background-color': '#DB9C9C' },
      children: [
        {
          field: 'fbaFee',
          headerName: 'FBA fee',
          valueFormatter: ({ value }) => currencyValueFormatter(value),
        },
        {
          field: 'referralFee',
          headerName: 'Referral fee',
          minWidth: 120,
          valueFormatter: ({ value }) => currencyValueFormatter(value),
        },
        {
          field: 'cogs',
          headerName: 'COGS',
          minWidth: 110,
          cellRenderer: 'EditableRenderer',
          editable: true,
          cellEditor: 'NumericCellEditor',
          cellEditorParams: createNumericCellEditorParams({ rowDataInitial }),
          valueFormatter: ({ value }) => currencyValueFormatter(value),
        },
        {
          field: 'storageFee',
          headerName: 'Storage fee',
          minWidth: 130,
          cellRenderer: 'EditableRenderer',
          editable: true,
          cellEditor: 'NumericCellEditor',
          cellEditorParams: createNumericCellEditorParams({ rowDataInitial }),
          valueFormatter: ({ value }) => currencyValueFormatter(value),
        },
        {
          field: 'refundRate',
          headerName: 'Refund rate',
          minWidth: 125,
          cellRenderer: 'EditableRenderer',
          editable: true,
          cellEditor: 'NumericCellEditor',
          cellEditorParams: createNumericCellEditorParams({ rowDataInitial }),
          valueFormatter: ({ value }) => percentageValueFormatter(value),
        },
        {
          field: 'tacos',
          headerName: 'TACoS',
          headerClass: 'border-right',
          minWidth: 115,
          cellRenderer: 'EditableRenderer',
          editable: true,
          cellEditor: 'NumericCellEditor',
          cellEditorParams: createNumericCellEditorParams({ rowDataInitial }),
          valueFormatter: ({ value }) => percentageValueFormatter(value),
          cellStyle: { 'border-right': '1px solid #e2e2e2' },
        },
      ],
    },
    {
      headerName: 'Metrics Before Ads',
      headerClass: 'before-ads-header',
      children: [
        {
          field: 'profitBeforeAds',
          headerName: 'Profit',
          valueFormatter: ({ value }) => currencyValueFormatter(value),
        },
        {
          field: 'marginBeforeAds',
          headerName: 'Margin %',
          minWidth: 115,
          valueFormatter: ({ value }) => percentageValueFormatter(value),
        },
        {
          field: 'roiBeforeAds',
          headerName: 'ROI',
          headerClass: 'border-right',
          valueFormatter: ({ value }) => percentageValueFormatter(value),
          cellStyle: { 'border-right': '1px solid #e2e2e2' },
        },
      ],
    },
    {
      headerName: 'Net Metrics Including Ads',
      headerClass: 'net-metrics-header',
      children: [
        {
          field: 'netProfit',
          headerName: 'profit',
          valueFormatter: ({ value }) => currencyValueFormatter(value),
        },
        {
          field: 'netMargin',
          headerName: 'Margin %',
          minWidth: 115,
          valueFormatter: ({ value }) => percentageValueFormatter(value),
        },
        {
          field: 'netRoi',
          headerName: 'ROI',
          headerClass: 'border-right',
          valueFormatter: ({ value }) => percentageValueFormatter(value),
          headerStyle: { 'border-right': '1px solid #e2e2e2' },
          cellStyle: { 'border-right': '1px solid #e2e2e2' },
        },
      ],
    },
    {
      headerName: 'Break-Even Points',
      headerClass: 'break-even-points-header',
      children: [
        {
          field: 'beAcos',
          headerName: 'BE ACoS',
          valueFormatter: ({ value }) => percentageValueFormatter(value),
        },
        {
          field: 'beRoas',
          headerName: 'BE ROAS',
          valueFormatter: ({ value }) => floatValueFormatter(value),
        },
      ],
    },
  ];
}
