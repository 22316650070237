<template>
  <v-card class="account pa-8">
    <!-- TODO set default: (account.isDefault ? ' (Default)' : ''), - comments copied after RWD -->
    <div class="d-flex align-center mb-2 mb-lg-5">
      <h2 class="account__name text-h6 text-lg-h5">
        Brand name: {{ account.name }}
      </h2>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            :to="'/settings/change-seller-name/' + account.id"
            v-bind="attrs"
            v-on="on"
            class="ml-2"
          >
            <v-icon size="20">mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Change seller name</span>
      </v-tooltip>
    </div>
    <div class="d-flex align-center mb-3 text-lg-h6">
      Seller Account:
      <span
        class="d-flex align-center account__status account__status--active ml-2"
      >
        Active
        <v-icon dense color="var(--green-400)" class="ml-1">
          mdi-check-circle</v-icon
        >
        <v-btn
          :href="renew_token_link + '_' + account.id"
          color="white"
          target="_blank"
          small
          class="ml-2"
        >
          Renew
        </v-btn>
      </span>
    </div>
    <div class="d-flex align-center text-lg-h6">
      PPC Account:
      <span
        class="d-flex align-center account__status account__status--active ml-2"
        v-if="account.advertisingAuthorized"
      >
        Active
        <v-icon dense color="var(--green-400)" class="ml-1">
          mdi-check-circle</v-icon
        >
        <v-btn
          @click="changeCurrentSeller()"
          :href="activate_advertising_link"
          color="white"
          target="_blank"
          small
          class="ml-2"
        >
          Renew
        </v-btn>
      </span>
      <span
        class="d-flex align-center account__status account__status--inactive ml-2"
        v-else
      >
        Inactive
        <v-icon dense color="var(--red-400)" class="ml-1">
          mdi-close-circle</v-icon
        >
        <v-btn
          @click="changeCurrentSeller()"
          :href="activate_advertising_link"
          color="secondary"
          target="_blank"
          small
          class="ml-2"
        >
          Connect
        </v-btn>
      </span>
    </div>
  </v-card>
</template>

<script>
import { env_vars } from '@/store/vars.js';

export default {
  name: 'Account',
  props: ['account'],
  data() {
    return {
      renew_token_link: env_vars.renew_selling_partner,
    };
  },
  methods: {
    changeCurrentSeller() {
      this.$store.commit('SET_CURRENT_SELLER', this.account);
    },
  },
  computed: {
    activateAdvertisingPrefix() {
      if (this.account.state === 'Europe') {
        return 'https://eu.account.amazon.com/ap/oa';
      } else if (this.account.state === 'Far East') {
        return 'https://apac.account.amazon.com/ap/oa';
      } else {
        return 'https://www.amazon.com/ap/oa';
      }
    },
    activate_advertising_link() {
      return this.activateAdvertisingPrefix + env_vars.activate_advertising;
    },
  },
};
</script>

<style lang="scss" scoped>
.account {
  display: block;
  height: 100%;
  //max-width: 540px;

  &__name {
    font-weight: 600;
    color: var(--gray-700);
  }
  &__status {
    font-weight: 600;
    &--active {
      color: var(--green-400);
    }
    &--inactive {
      color: var(--red-400);
    }
  }
}
</style>
