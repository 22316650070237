<template>
  <div>
    <h2 class="page-title mb-3">
      ACCOUNTS
    </h2>

    <v-alert type="info" color="primary" v-if="!sellers.length">
      To start using My Real Profit please connect your
      <strong>Amazon account.</strong>
    </v-alert>

    <div class="d-flex mb-9 gap-3 align-center">
      <v-btn
        :href="activate_selling_partner_link"
        color="secondary"
        target="_blank"
      >
        <v-icon left>mdi-account-plus</v-icon>
        Connect Amazon Account
      </v-btn>
    </div>

    <v-row align-content="stretch" v-if="sellers.length">
      <v-col
        xs="6"
        md="6"
        lg="6"
        cols="12"
        v-for="seller in sellers"
        :key="seller.id"
      >
        <account :account="seller"></account>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { env_vars } from '@/store/vars.js';
import Account from '@/modules/DashboardLayout/Settings/Accounts/Account/Account.vue';

export default {
  name: 'Accounts',
  data() {
    return {
      activate_selling_partner_link: env_vars.activate_selling_partner,
    };
  },
  components: { Account },
  computed: {
    sellers() {
      return this.$store.getters.sellers;
    },
  },
};
</script>
