<template>
  <div>
    <div class="d-flex align-center gap-3 mb-3">
      <h2 class="page-title ">CUSTOM CATEGORIES</h2>

      <GuidelineVideo :link="guidelinesVideos.customCategories" />
    </div>

    <v-alert type="info" color="primary" v-if="!categories.length">
      To start using this section please create your
      <strong>Custom Category</strong>
    </v-alert>

    <v-btn color="secondary" class="mb-9" @click="createCategoryButtonClicked">
      <v-icon left>mdi-tag-plus-outline</v-icon>
      Create Custom Category
    </v-btn>

    <v-card class="categories-table" v-if="categories.length">
      <table>
        <thead>
          <tr>
            <th>Category Name</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <category
            v-for="category in categories"
            :key="category"
            :category="category"
            @delete-clicked="deleteModalClicked(category)"
          >
          </category>
        </tbody>
        <tfoot>
          <tr></tr>
        </tfoot>
      </table>
    </v-card>
    <ConfirmationModal
      :enabled="createCategoryModalShowed"
      :onClose="hideModal"
    >
      <template v-slot:title>
        Enter Category Name
      </template>
      <template v-slot:content>
        <v-text-field
          v-model="newCategoryName"
          label="Category Name"
          style="line-height: 20px;"
        ></v-text-field>
      </template>
      <template v-slot:buttons>
        <v-btn color="white" class="mr-3" @click="hideModal">Cancel</v-btn>
        <v-btn color="secondary" @click="createCategory()">
          Create
        </v-btn>
      </template>
    </ConfirmationModal>

    <ConfirmationModal
      :enabled="deleteCategoryModalShowed"
      :onClose="hideModal"
    >
      <template v-slot:title>
        Delete Category
      </template>
      <template v-slot:content>
        Are you sure you want to delete Category: {{ categoryToDelete }} ?
      </template>
      <template v-slot:buttons>
        <v-btn color="white" class="mr-3" @click="hideModal">Cancel</v-btn>
        <v-btn
          color="secondary"
          :loading="deleteButtonLoading"
          @click="deleteCategory(categoryToDelete)"
        >
          Delete
        </v-btn>
      </template>
    </ConfirmationModal>
  </div>
</template>

<script>
import Category from '@/modules/DashboardLayout/CustomCategories/Categories/Category/Category.vue';
import ConfirmationModal from '@/common/components/ConfirmationModal';

import * as poster from '@/api/poster';
import GuidelineVideo from '@/common/components/GuidelineVideo.vue';
import { guidelinesVideos } from '@/utils/guidelines-videos';

export default {
  data() {
    return {
      createCategoryModalShowed: false,
      deleteCategoryModalShowed: false,
      deleteButtonLoading: false,
      newCategoryName: null,
      categoryToDelete: null,
    };
  },
  components: { GuidelineVideo, ConfirmationModal, Category },
  name: 'productCategories',
  created() {
    if (
      this.$route.query.auto_create !== null &&
      this.$route.query.auto_create === 'true'
    )
      this.createCategoryModalShowed = true;
  },
  computed: {
    guidelinesVideos() {
      return guidelinesVideos;
    },
    categories() {
      return this.$store.getters.customCategories;
    },
  },
  methods: {
    createCategoryButtonClicked() {
      this.createCategoryModalShowed = true;
    },
    deleteModalClicked(category) {
      this.categoryToDelete = category;
      this.deleteCategoryModalShowed = true;
    },
    hideModal() {
      this.createCategoryModalShowed = false;
      this.deleteCategoryModalShowed = false;
      this.deleteButtonLoading = false;
    },
    createCategory() {
      this.newCategoryName = this.newCategoryName
        ? this.newCategoryName.replace(/\s\s+/g, ' ').trim()
        : this.newCategoryName;
      if (!this.newCategoryName) {
        this.logValidStringMsg();
        return;
      }
      this.hideModal();
      this.$router.push('category/' + encodeURIComponent(this.newCategoryName));
      this.$store.commit('ADD_CATEGORY', this.newCategoryName);
      this.newCategoryName = null;
    },
    deleteCategory(category) {
      if (!category) return;
      this.deleteButtonLoading = true;
      poster
        .delete_category(category)
        .then(() => {
          this.$store.commit('REMOVE_CATEGORY', category);
          this.$store.dispatch('logAlertSuccess', {
            msg: 'Category ' + category + ' deleted successfully',
            delay: 10000,
          });
        })
        .catch(() => {
          this.$store.dispatch('logAlertError', {
            msg:
              'There was an error deleting category ' +
              category +
              '. Please, try again.',
            delay: 10000,
          });
        })
        .finally(() => {
          this.hideModal();
          this.categoryToDelete = null;
        });
    },
    logValidStringMsg() {
      this.$store.dispatch('logAlertInfo', { msg: 'Value must not be empty' });
    },
  },
};
</script>
<style scoped>
.categories-table tbody tr {
  border-bottom: 1px solid #e8e7f3;
}
.categories-table tbody tr:hover {
  box-shadow: 0px 1px 3px 0px rgba(201, 199, 217, 0.24),
    0px 2px 2px 0px rgba(78, 87, 170, 0.1),
    0px 0px 5px 0px rgba(143, 142, 145, 0.25);
  background-color: #fcfcfc;
}
.categories-table table {
  width: 100%;
  border-collapse: collapse;
}

.categories-table table thead tr {
  box-shadow: 0px 1px 3px 0px rgba(201, 199, 217, 0.24),
    0px 2px 2px 0px rgba(78, 87, 170, 0.1),
    0px 0px 5px 0px rgba(143, 142, 145, 0.25);
}
.categories-table tfoot tr {
  height: 30px;
}
.categories-table table thead th {
  height: 39px;

  font-family: 'Rajdhani-Regular';
  font-size: 14px;
  font-weight: 700;
  font-style: normal;

  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.02em;

  color: #afb0bd;
}

.categories-table th:nth-child(1) {
  width: 60%;
  padding-left: calc(var(--universal-padding) * 2);
  text-align: left;
}
.categories-table th:nth-child(2) {
  width: 30%;
  padding-left: calc(var(--universal-padding) * 4);
  text-align: left;
}
.categories-table th:nth-child(3) {
  width: 10%;
  text-align: center;
}
</style>
