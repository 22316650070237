<template>
  <div class="product">
    <div class="product__label"><slot name="subtitle"></slot></div>
    <div class="product__title"><slot name="title"></slot></div>

    <div class="position-relative">
      <v-card class="product__details">
        <div class="d-flex align-stretch">
          <div class="product__image">
            <v-img
              aspect-ratio="1/1"
              contain
              rel
              :src="product.imageUrl"
              class="rounded flex-grow-0 w-100 h-100 "
            ></v-img>
          </div>
          <div
            class="product__values"
            :style="{ gridTemplateColumns: `repeat(${cols}, 1fr)` }"
          >
            <slot name="values"></slot>
          </div>
        </div>
      </v-card>

      <div
        class="toggle-container text-center"
        v-if="product.subProducts && product.subProducts.length"
        @click="toggle"
      >
        <v-icon color="primary">
          {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </div>
    </div>

    <div v-if="isOpen" class="product__subproducts">
      <slot name="subProducts"></slot>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  props: {
    product: Object,
    cols: Number,
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
<style lang="scss" scoped>
.product {
  &__label {
    color: var(--gray-500);
    font-size: 0.785rem;
    font-weight: normal;
    text-transform: uppercase;
  }
  &__title {
    font-size: 0.875rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 12px;
  }
  &__image {
    object-fit: contain;
    padding: 8px;
    aspect-ratio: 1 / 1;
    width: 100px;
    flex-shrink: 0;
    align-self: center;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      width: 150px;
    }

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: 90px;
    }
  }
  &__values {
    width: 100%;
    border-left: 1px solid #f1f1f1;
    align-items: stretch;
    padding: 0.5rem 0;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      display: grid;
    }

    ::v-deep > * {
      padding: 0.25rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        padding: 16px;
        justify-content: center;
        flex-direction: column;
        align-items: start;

        &:not(:first-child) {
          border-left: 1px solid #f1f1f1;
        }
      }
    }
  }
  &__details {
    position: relative;
    z-index: 3;
  }

  &__subproducts {
    background: #e8eaf0;
    overflow: hidden;
    padding: 1rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding: 2rem 2rem 2rem 4.8rem;
    }
  }

  &__subproducts &__details {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      &:before {
        content: '';
        display: block;
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 2px;
        background: #c5c9d6;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: -30px;
        bottom: 50%;
        width: 2px;
        height: 200%;
        background: #c5c9d6;
      }
    }
  }
}

.toggle-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f8f7fc;
  box-shadow: 0 4px 2px -2px rgba(143, 142, 145, 0.25);
  position: relative;
  z-index: 2;
  height: 48px;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    width: 92px;
    margin: 0 auto;
    height: 22px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
