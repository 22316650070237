<template>
  <div class="cogs-modal-container">
    <card
      :content="{
        header: { text: 'Update COGS' },
        text: { text: ' ' },
        button: { text: 'Apply' },
      }"
      :config="{ width: '350px' }"
    >
      <img
        src="@/assets/svg/tick-inactive.svg"
        alt="close modal"
        style="position: absolute; top:8px; right:8px"
        @click="closeClicked()"
      />
      <template v-slot:content>
        <v-radio-group v-model="applyHistoryRadio" column>
          <v-radio
            class="radio"
            label="Apply cost from today"
            value="NO_HISTORY"
          ></v-radio>
          <v-radio
            class="radio"
            label="Apply cost for all captured orders"
            value="HISTORY"
          ></v-radio>
        </v-radio-group>
      </template>
      <template v-slot:buttons>
        <v-btn color="white" class="mr-3" @click="closeClicked()">Cancel</v-btn>
        <v-btn color="secondary" @click="applyClicked()">
          Apply
        </v-btn>
      </template>
    </card>
  </div>
</template>

<script>
import Card from '@/common/components/Card.vue';

export default {
  name: 'CogsModal',
  components: {
    Card,
  },
  data() {
    return {
      applyHistoryRadio: 'NO_HISTORY',
    };
  },
  methods: {
    applyClicked() {
      this.$emit('apply-clicked', this.applyHistoryRadio);
    },
    closeClicked() {
      this.$emit('close-clicked');
    },
  },
};
</script>

<style scoped>
.cogs-modal-container {
  position: relative;
  z-index: 999999;
  top: 50px;
  left: -300px;
}
.radio {
  font-weight: 400 !important;
  font-size: 16px !important;
}
</style>
