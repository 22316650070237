<template>
  <div class="flex-column align-flex-start">
    <vertical-space-divider></vertical-space-divider>
    <rajdhani
      :content="{ text: 'Connect DSP Account' }"
      :config="{
        weight: 'Bold',
        'text-transform': 'uppercase',
        'font-size': '44px',
        'line-height': '56px',
        color: '#c3c9de',
      }"
    ></rajdhani>
    <vertical-space-divider></vertical-space-divider>

    <div class="section-shadow main-card flex-column save-seller-card">
      <div class="main-card-header">
        <span class="main-card-header-text">
          Authorize Amazon DSP
        </span>
      </div>
      <div v-if="!error">
        <div class="save-seller-text-container">
          <span class="save-seller-text">
            Please, give us a moment to process your Amazon DSP account
            credentials. <br />
            You will be redirected to the settings page once completed.
            <br /><br /><br />
          </span>
          <loading-progress
            :indeterminate="true"
            size="36"
            rotate
            fillDuration="2"
            rotationDuration="1"
            height="38"
            width="38"
          />
        </div>
      </div>
      <div v-else>
        <div class="save-seller-text-container">
          <span class="save-seller-text">
            There was an error authorizing your Amazon DSP account.
            <br />
            Please, try again or contact support.
          </span>
        </div>
        <div class="flex-row contact-support-container">
          <v-btn color="primary" href="mailto:support@myrealprofit.com">
            Contact Support
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VerticalSpaceDivider from '@/common/components/VerticalSpaceDivider.vue';
import Rajdhani from '@/common/components/Texts/Rajdhani.vue';

export default {
  name: 'ActivateDspRedirect',
  data() {
    return {
      error: false,
      scope: null,
      code: null,
    };
  },
  components: {
    VerticalSpaceDivider,
    Rajdhani,
  },
  created() {
    this.collect_credentials();
    if (this.dsp_success()) {
      this.$store.dispatch('post_dsp_controller', this.dsp_credentials);
    } else {
      this.error = true;
    }
  },
  methods: {
    collect_credentials() {
      this.scope = this.$route.query.scope;
      this.code = this.$route.query.code;
    },
    dsp_success() {
      return (
        this.scope && this.code && this.currentSellerId && this.amazonRegionName
      );
    },
  },
  computed: {
    dsp_credentials() {
      return {
        scope: this.scope,
        code: this.code,
        sellingPartnerId: this.currentSellerId,
        amazonRegionName: this.amazonRegionName,
      };
    },
    currentSellerId() {
      return this.$store.getters.currentSellerId;
    },
    amazonRegionName() {
      return this.$store.getters.currentSellerState;
    },
  },
};
</script>

<style scoped>
.save-seller-card {
  width: 55%;
}

.save-seller-text-container {
  padding: 30px;
  padding-top: 0;
  text-align: left;
}
.save-seller-text {
  font-family: 'SourceSansPro-Regular';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0em;
}

.save-seller-button-container {
  padding: 30px;
}
.save-seller-button {
  float: left;
}

.save-seller-form-container {
  padding: 30px;
  justify-content: space-between;
  align-items: flex-end;
}
.contact-support-container {
  padding: 30px;
  justify-content: flex-start;
}
</style>
<style>
.save-seller-text-container .vue-progress-path .progress {
  stroke: var(--secondary-purple-color);
  fill: white;
  stroke-width: 1.5px;
}
.save-seller-text-container .vue-progress-path .background {
  stroke: var(--third-purple-color);
}
</style>
