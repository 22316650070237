<template>
  <div>
    <ProductItem :product="product" :cols="5" ref="product">
      <template v-slot:title>
        <span :title="product.title">{{ product.title }}</span>
      </template>
      <template v-slot:subtitle>{{ subtitle }}</template>
      <template v-slot:values>
        <ProductItemValue>
          <template v-slot:label>Sales</template>
          <template v-slot:value>{{ product.salesFormatted }}</template>
        </ProductItemValue>

        <ProductItemValue>
          <template v-slot:label>Units Sold</template>
          <template v-slot:value>{{ product.unitsFormatted }}</template>
        </ProductItemValue>

        <ProductItemValue>
          <template v-slot:label>Sessions</template>
          <template v-slot:value>{{ product.sessionsFormatted }}</template>
        </ProductItemValue>

        <ProductItemValue>
          <template v-slot:label>Conversion Rate</template>
          <template v-slot:value>
            {{ product.conversionRateFormatted }}
          </template>
        </ProductItemValue>

        <ProductItemValue>
          <template v-slot:label>TACoS</template>
          <template v-slot:value>{{ product.tacosFormatted }}</template>
        </ProductItemValue>
      </template>

      <template v-slot:subProducts>
        <div class="d-flex flex-column gap-3">
          <div
            v-for="subProduct of loadedProducts"
            :key="'sub' + subProduct.title"
          >
            <ProductItem :product="subProduct" :cols="5">
              <template v-slot:title>
                <span :title="subProduct.title">{{ subProduct.title }}</span>
              </template>
              <template v-slot:subtitle>CHILD: {{ subProduct.asin }}</template>
              <template v-slot:values>
                <ProductItemValue>
                  <template v-slot:label>Sales</template>
                  <template v-slot:value>
                    {{ subProduct.salesFormatted }}
                  </template>
                </ProductItemValue>

                <ProductItemValue>
                  <template v-slot:label>Units Sold</template>
                  <template v-slot:value>
                    {{ subProduct.unitsFormatted }}
                  </template>
                </ProductItemValue>

                <ProductItemValue>
                  <template v-slot:label>Sessions</template>
                  <template v-slot:value>
                    {{ subProduct.sessionsFormatted }}
                  </template>
                </ProductItemValue>

                <ProductItemValue>
                  <template v-slot:label>Conversion Rate</template>
                  <template v-slot:value>
                    {{ subProduct.conversionRateFormatted }}
                  </template>
                </ProductItemValue>

                <ProductItemValue>
                  <template v-slot:label>TACoS</template>
                  <template v-slot:value>
                    {{ subProduct.tacosFormatted }}
                  </template>
                </ProductItemValue>
              </template>
            </ProductItem>
          </div>
          <v-btn
            v-if="!isLoadedAllProducts"
            @click="showMore()"
            color="white"
            class="primary--text d-block mx-auto products__more"
          >
            Show more
          </v-btn>
        </div>
      </template>
    </ProductItem>
  </div>
</template>
<script>
import ProductItemValue from '@/common/components/ProductsList/ProductItemValue.vue';
import ProductItem from '@/common/components/ProductsList/ProductItem.vue';
import { mapGetters } from 'vuex';

import { TA_PRODUCTS_INCREMENTAL } from './TrafficAnalyticsProductsTable.vue';

export default {
  props: ['product', 'groupBy'],
  components: {
    ProductItemValue,
    ProductItem,
  },
  data() {
    return {
      loadedProductsCount: TA_PRODUCTS_INCREMENTAL,
    };
  },
  computed: {
    ...mapGetters({
      data: 'trafficAnalytics/productsData',
    }),
    subtitle() {
      if (this.groupBy === 'CHILD') {
        return `CHILD: ${this.product.asin}`;
      }

      if (this.groupBy === 'PARENT') {
        return `PARENT: ${this.product.parentAsin}`;
      }
      return `CATEGORY: ${this.product.category}`;
    },
    loadedProducts() {
      return this.product.subProducts.slice(0, this.loadedProductsCount);
    },

    isLoadedAllProducts() {
      return this.product.subProducts.length <= this.loadedProductsCount;
    },
  },
  methods: {
    showMore() {
      this.loadedProductsCount += TA_PRODUCTS_INCREMENTAL;
    },
  },
  watch: {
    data() {
      this.loadedProductsCount = TA_PRODUCTS_INCREMENTAL;
      this.$refs.product.isOpen = false;
    },
  },
};
</script>
