export function createNumericCellEditorParams({ rowDataInitial }) {
  return {
    getInitialValue() {
      const colId = this.column.colId;
      const id = this.node.id;

      const findProduct = rowDataInitial.find(({ sku }) => sku === id);

      const initialValue = findProduct[colId];

      return initialValue || 0;
    },
  };
}
