<template>
  <div>
    <div class="d-flex align-center gap-3 justify-space-between">
      <div class="d-flex align-center gap-3">
        <h2 class="page-title text-uppercase">Traffic</h2>

        <GuidelineVideo :link="guidelinesVideos.trafficAnalytics" />
      </div>

      <DateRange
        :ranges="ranges"
        :custom-ranges="customRanges"
        :active-range="rangeType"
        :active-dates="rangeDates"
        @onRangeChange="changeRange"
      ></DateRange>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DateRange from '@/common/components/DateRange/DateRange.vue';
import { getCurrentRangeDates } from '@/store/modules/trafficAnalytics';
import { guidelinesVideos } from '@/utils/guidelines-videos';
import GuidelineVideo from '@/common/components/GuidelineVideo.vue';

export default {
  name: 'TrafficAnalyticsHeader',

  components: { GuidelineVideo, DateRange },
  data() {
    return {
      ranges: [
        { text: 'Last 7 days', value: 'last_7_days' },
        { text: 'Last 14 days', value: 'last_14_days' },
        { text: 'This month', value: 'this_month' },
        { text: 'Last month', value: 'last_month' },
      ],
      customRanges: [
        { text: 'Last 30 days', value: 'last_30_days' },
        { text: 'This quarter', value: 'this_quarter' },
        { text: 'Last quarter', value: 'last_quarter' },
      ],
    };
  },
  computed: {
    guidelinesVideos() {
      return guidelinesVideos;
    },
    ...mapGetters({
      rangeType: 'trafficAnalytics/rangeType',
      _rangeDates: 'trafficAnalytics/rangeDates',
    }),
    rangeDates() {
      return getCurrentRangeDates(this.rangeType, this._rangeDates);
    },
  },
  methods: {
    changeRange(type, dates = null) {
      const customDates = getCurrentRangeDates(type, dates);

      this.$store.dispatch('trafficAnalytics/setRange', {
        type,
        dates: customDates,
      });
    },
  },
};
</script>
