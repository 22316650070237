import { $api } from '@/main';
import { env_vars } from '@/store/vars';

export const AuthApi = {
  resetPassword(email) {
    const params = {
      email,
      ...(env_vars.isBeta && { isBeta: true }),
    };
    return $api.make_post(
      '/user/reset-password',
      'reset-password',
      {},
      {
        params,
      }
    );
  },

  createNewPassword({ password, token, myRealProfitId }) {
    const params = {
      password,
      token,
      myRealProfitId,
    };
    return $api.make_post(
      '/user/create-new-password',
      'reset-password',
      {},
      {
        params,
      }
    );
  },
};
