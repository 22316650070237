var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"delete-modal-container"},[_c('card',{attrs:{"content":{
      header: { text: _vm.content.header },
      text: { text: ' ' },
    },"config":{ width: '350px' }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"flex-column align-flex-start"},[_c('div',{staticClass:"flex-row"},[_c('inter',{attrs:{"content":{
              text: _vm.content.text,
            },"config":{
              weight: 'Regular',
              color: 'var(--gray-700)',
              'font-size': '18px',
              'line-height': '28px',
            }}}),_c('horizontal-space-divider',{attrs:{"config":{ width: '16px' }}}),_c('inter',{attrs:{"content":{
              text: _vm.calendarDateDisplay,
            },"config":{
              weight: 'Medium',
              color: 'var(--gray-700)',
              'font-size': '18px',
              'line-height': '28px',
            }}})],1),_c('vertical-space-divider',{attrs:{"config":{ height: '16px' }}}),_c('date-picker',{attrs:{"color":"purple","trim-weeks":"","step":1,"mode":"date"},model:{value:(_vm.calendarDate),callback:function ($$v) {_vm.calendarDate=$$v},expression:"calendarDate"}}),_c('vertical-space-divider',{attrs:{"config":{ height: '8px' }}})],1)]},proxy:true},{key:"buttons",fn:function(){return [_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.yesClicked()}}},[_vm._v("Update")])]},proxy:true}])},[_c('img',{staticClass:"tick-img",attrs:{"src":require("@/assets/svg/tick-inactive.svg")},on:{"click":function($event){return _vm.closeClicked()}}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }