<template>
  <div class="w-100 h-100 d-flex justify-center align-center">
    Redirect ...
  </div>
</template>

<script>
import router from '@/router';

export default {
  data() {
    return {
      requestParams: {
        scopes: [
          'User.Read',
          'https://analysis.windows.net/powerbi/api/Report.Read.All',
        ],
        redirectStartPage: window.location.href,
      },
    };
  },
  async mounted() {
    const redirectResponse = await this.$msal.handleRedirectPromise();

    try {
      if (redirectResponse !== null) {
        this.$msal.setActiveAccount(redirectResponse);

        await router.push(router.currentRoute.query.redirectUrl);
      } else {
        await this.acquireToken();
      }
    } catch (e) {
      console.log(e);
      await this.$msal.loginRedirect(this.requestParams);
    }
  },
  methods: {
    async acquireToken() {
      const accounts = this.$msal.getAllAccounts();

      if (accounts.length === 0) {
        await this.$msal.loginRedirect(this.requestParams);

        return;
      }

      const mrpAccount = findMrpAccount(accounts);

      if (mrpAccount) {
        const { account } = await this.$msal.acquireTokenSilent({
          ...this.requestParams,
          account: mrpAccount,
        });

        this.$msal.setActiveAccount(account);
        await router.push(router.currentRoute.query.redirectUrl);

        return;
      }

      await this.$msal.loginRedirect(this.requestParams);
    },
  },
};

function findMrpAccount(accounts) {
  return accounts.find(account =>
    account.username.includes('myrealprofit.com')
  );
}
</script>
