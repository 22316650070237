const formatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  notation: 'compact',
  compactDisplay: 'short',
});

export function integerValueFormatter(value) {
  if (!value) {
    return '0';
  }

  if (value < 10000 && value > -10000) {
    return parseInt(value, 10);
  }

  return formatter.format(parseInt(value));
}

export function simpleIntegerValueFormatter(value) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(value).replaceAll(',', ' ');
}
