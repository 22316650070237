var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"expenses-table",style:(_vm.computedStyles)},_vm._l((_vm.content.expensesList),function(expense,i){return _c('tr',{key:i},[_c('td',[_c('rajdhani',{attrs:{"content":{
          text: expense.percent.toFixed(0) + '%',
        },"config":{ weight: 'SemiBold' }}})],1),_c('td',[_c('div',{staticClass:"flex-row align-center"},[_c('source-sans-pro',{attrs:{"content":{ text: _vm.getExpenseName(expense) },"config":{ color: 'var(--secondary-gray-color)' }}}),(_vm.showCogsWarning(expense.name))?_c('div',{staticClass:"warning-tooltip-wrapper"},[_c('img',{staticStyle:{"margin-left":"8px"},attrs:{"src":require("@/assets/svg/warning.svg")}}),_c('div',{staticClass:"warning-tooltip main-card section-shadow"},[_c('source-sans-pro',{attrs:{"content":{
                text: 'Some of the Active listings have empty COGS value.',
              },"config":{ 'font-size': '15px', 'line-height': '18px' }}},[_c('router-link',{staticStyle:{"color":"var(--main-purple-color)","font-weight":"700"},attrs:{"to":"/cogs"}},[_vm._v(" Set value ")])],1)],1)]):_vm._e(),(_vm.expenseHoverVisible(expense))?_c('div',{staticClass:"expense-details-hover ml-1"},[_c('img',{attrs:{"src":require("@/assets/svg/info-circle-solid.svg")}}),_c('div',{staticClass:"expense-details hover-shadow main-card",class:expense.name == 'Adjustments' ? 'wide-row' : 'standart-row'},_vm._l((expense.subexpenses.list),function(expenseDetail,idx){return _c('div',{key:idx,staticClass:"flex-row justify-space-between expense-details-row"},[_c('source-sans-pro',{attrs:{"content":{
                  text:
                    expense.name == 'Adjustments'
                      ? _vm.trimString(expenseDetail.name, 42)
                      : _vm.trimString(expenseDetail.name, 22),
                },"config":{
                  color: 'var(--secondary-gray-color)',
                }}}),_c('source-sans-pro',{attrs:{"content":{
                  text: '$' + expenseDetail.totalPretty,
                },"config":{
                  weight: 'SemiBold',
                  color: 'var(--secondary-gray-color)',
                }}})],1)}),0)]):_vm._e()],1)]),_c('td',[_c('source-sans-pro',{attrs:{"content":{
          text: '$' + expense.totalPretty,
        },"config":{ weight: 'Bold', color: 'var(--secondary-gray-color)' }}})],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }