<template>
  <div class="d-flex flex-column align-center justify-center app-message ma-8">
    <h3 class="text--secondary text-h6 text-center">
      {{ text }}
    </h3>
    <v-btn color="primary" small @click="$emit('retry')">
      {{ retryButtonText || 'Retry' }}
    </v-btn>
  </div>
</template>
<script>
export default {
  props: {
    text: String,
    retryButtonText: String,
  },
};
</script>
<style scoped>
.app-message {
  gap: 20px;
}
</style>
