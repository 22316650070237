<template>
  <div class="">
    <h2 class="page-title mb-5">SUBSCRIPTIONS</h2>

    <v-card v-if="subscriptionTrial" class="pa-8 text-lg-h6">
      Subscription:
      <span class="indigo--text text--accent-2 font-weight-semibold">
        {{ trialEndsText }}
      </span>
    </v-card>

    <v-row v-else>
      <v-col md="6" cols="12">
        <v-card class="pa-8 text-lg-h6">
          <div class="d-flex">
            Subscriptions:
            <span
              class="subscription-active d-flex ml-2 align-center font-weight-semibold"
            >
              Active
              <v-icon dense color="var(--green-400)" class="ml-1">
                mdi-check-circle
              </v-icon>
            </span>
            <span
              v-if="subscriptionCanceled"
              class="ml-2 grey--text font-weight-semibold"
            >
              {{ cancelsText }}
            </span>
          </div>

          <div
            class="d-flex"
            v-if="!subscriptionCanceled && subscriptionActive"
          >
            Next billing:
            <span class="ml-2 grey--text font-weight-semibold">
              {{ activeDatePretty }}
            </span>
          </div>

          <div class="d-flex" v-if="subscriptionDetails.cardLastDigits">
            Card details:
            <span class="ml-2 grey--text font-weight-semibold">
              *{{ subscriptionDetails.cardLastDigits }}
            </span>
          </div>

          <v-btn
            v-if="!subscriptionCanceled"
            color="secondary"
            class="mt-4"
            @click="openUnsubscribeModal()"
          >
            Unsubscribe
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <ConfirmationModal
      :enabled="unsubscribeModalShowed"
      :onClose="closeUnsubscribeModal"
    >
      <template v-slot:title>
        Unsubscribe from My Real Profit?
      </template>
      <template v-slot:content>
        Your subscription will be active till the end of the current billing
        period
      </template>
      <template v-slot:buttons>
        <v-btn
          color="white"
          class="mr-3"
          @click="unsubscribeModalShowed = false"
          >No</v-btn
        >
        <v-btn color="secondary" @click="yesUnsubscribeModalClicked()">
          Yes, unsubscribe
        </v-btn>
      </template>
    </ConfirmationModal>
  </div>
</template>

<script>
import ConfirmationModal from '@/common/components/ConfirmationModal';

import { DateTime } from 'luxon';

export default {
  components: {
    ConfirmationModal,
  },
  name: 'Subscriptions',
  data() {
    return {
      unsubscribeModalShowed: false,
    };
  },
  computed: {
    subscriptionStatus() {
      return this.$store.getters.subscriptionStatus;
    },
    subscriptionDetails() {
      return this.$store.getters.subscriptionDetails;
    },
    subscriptionActive() {
      return this.subscriptionStatus === 'active';
    },
    subscriptionTrial() {
      return this.subscriptionStatus === 'trialing';
    },
    subscriptionCanceled() {
      return this.subscriptionDetails.cancelAtPeriodEnd;
    },
    isStatusActive() {
      return this.subscriptionStatus === 'active';
    },
    periodEndText() {
      if (this.subscriptionTrial) {
        return 'Trial period ends: ' + this.trialDatePretty;
      } else if (this.subscriptionActive) {
        return 'Next billing period: ' + this.activeDatePretty;
      } else {
        return '';
      }
    },
    trialEndsText() {
      return 'Trial ends ' + this.trialDatePretty;
    },
    cancelsText() {
      return 'Cancels ' + this.activeDatePretty;
    },

    trialDatePretty() {
      return DateTime.fromISO(this.subscriptionDetails.trialEnd).toFormat(
        'dd LLL yyyy'
      );
    },
    activeDatePretty() {
      return DateTime.fromISO(
        this.subscriptionDetails.currentPeriodEnd
      ).toFormat('dd LLL yyyy');
    },
  },
  methods: {
    yesUnsubscribeModalClicked() {
      this.$store.dispatch('cancel_subscription_controller');
      this.unsubscribeModalShowed = false;
    },
    openUnsubscribeModal() {
      this.unsubscribeModalShowed = true;
    },
    closeUnsubscribeModal() {
      this.unsubscribeModalShowed = false;
    },
  },
};
</script>

<style scoped>
.subscription-active {
  color: var(--green-400);
}
</style>
