<template>
  <tr>
    <td style="cursor: pointer;" @click="clickEdit">
      {{ category }}
    </td>
    <td>
      <v-btn text class="py-6 px-5" color="grey" @click="clickEdit">
        <v-icon size="20" class="pr-2">mdi-pencil</v-icon>
        EDIT
      </v-btn>
    </td>
    <td>
      <v-btn
        text
        class="py-6 px-5"
        color="red lighten-1"
        @click="clickDelete()"
      >
        <v-icon size="20" class="pr-2">mdi-delete</v-icon>
        DELETE
      </v-btn>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'Category',
  props: ['category'],
  data() {
    return {};
  },
  computed: {},
  methods: {
    clickEdit() {
      this.$router.push('category/' + encodeURIComponent(this.category));
    },
    clickDelete() {
      this.$emit('delete-clicked', this.category);
    },
  },
};
</script>

<style scoped>
tr td:nth-child(1) {
  width: 70%;
  padding-left: calc(var(--universal-padding) * 2);
  text-align: left;

  font-family: 'SourceSansPro-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #8073e5;
}
tr td:nth-child(2) {
  width: 15%;
  text-align: left;
  padding: var(--universal-padding);
}
tr td:nth-child(3) {
  width: 15%;
  text-align: left;
  padding-left: 10px;
  padding: var(--universal-padding);
}
</style>
