import { $api } from '@/main';

export const InventoryReportApi = {
  get({ sellerId, marketplaceId, groupBy }) {
    const params = {
      sellerId,
      marketplaceId,
      groupBy,
    };
    return $api.make_get('inventory-report', null, { params });
  },
};
